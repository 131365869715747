import React, {useCallback} from 'react';
import styled from 'styled-components';
import {useLocalStorage} from 'usehooks-ts';
import _ from 'lodash';
import arrowUp from '^assets/images/arrowUp.svg';
import arrowDown from '^assets/images/arrowDown.svg';
import {captureFields} from '^config/captureFields';
import {Col, Container, Row} from 'react-bootstrap';
import AsideHeader from '^common/asideHeader';

const FieldsContainer = styled(Container).attrs(() => ({
    className: 'text-primary',
}))`
    max-width: 35ch;
`;

const SmallTextButton = styled.div.attrs(() => ({
    className: 'text-primary',
    role: 'button',
}))`
    margin-top: 0.5rem;
    font-size: 0.8rem;
`;

const ReorderButton = styled.img.attrs(() => ({
    role: 'button',
}))`
    width: 1.5rem;
    cursor: ${({$disabled}) => $disabled && 'not-allowed'};
    filter: ${({$disabled}) => $disabled && 'grayscale(1) brightness(2)'};
`;

const AssetCaptureSettingsAside = () => {
    const [fieldOrder, setFieldOrder] = useLocalStorage(
        'asset_capture_preference',
        _.keys(captureFields),
    );

    const reorderField = useCallback((field, up) => {
        const fieldIndex = _.indexOf(fieldOrder, field);
        const reorderIdx = up ? fieldIndex - 1 : fieldIndex + 1;

        setFieldOrder(_.chain(fieldOrder)
            .without(field)
            .thru((fields) => {
                fields.splice(reorderIdx, 0, field);
                return fields;
            })
            .value());
    }, [fieldOrder, setFieldOrder]);

    return <>
        <AsideHeader>
            {'Asset Capture Settings'}
        </AsideHeader>
        <FieldsContainer>
            <Row className={'border-bottom'}>
                <Col className={'d-flex justify-content-end'}>
                    <SmallTextButton onClick={() => setFieldOrder([])}>
                        {'Hide All'}
                    </SmallTextButton>
                    <div className={'mx-1 mt-1'}>{'|'}</div>
                    <SmallTextButton
                        onClick={() => setFieldOrder(_.keys(captureFields))}
                    >
                        {'Reset'}
                    </SmallTextButton>
                </Col>
            </Row>
            {_.map(fieldOrder, (field) => {
                return <Row className={'my-3'} key={field}>
                    <Col xs={8} className={'mt-2 pe-1'}>
                        {captureFields[field]}
                    </Col>
                    <Col xs={2} className={'p-0 text-center lh-sm'}>
                        <div>
                            <ReorderButton
                                src={arrowUp}
                                $disabled={field === _.head(fieldOrder)}
                                onClick={() => reorderField(field, true)}
                            />
                        </div>
                        <div>
                            <ReorderButton
                                src={arrowDown}
                                $disabled={field === _.last(fieldOrder)}
                                onClick={() => reorderField(field, false)}
                            />
                        </div>
                    </Col>
                    <Col xs={2} className={'mt-1 ps-1 h-100'}>
                        <SmallTextButton onClick={() => setFieldOrder(
                            _.without(fieldOrder, field),
                        )}>
                            {'Hide'}
                        </SmallTextButton>
                    </Col>
                </Row>;
            })}
        </FieldsContainer>
    </>;
};

export default AssetCaptureSettingsAside;
