import styled from 'styled-components';
import arrowRight from '^assets/images/arrowRight.svg';
import externalLink from '^assets/images/externalLink.svg';
import infoIcon from '^assets/images/info.svg';
import unitIcon from '^assets/images/unit.svg';
import warningIcon from '^assets/images/warning.svg';

export const ArrowRight = styled.img.attrs(() => ({
    src: arrowRight,
}))`
  height: 1rem;
  margin-right: 0.1rem;
`;

export const ExternalLinkIcon = styled.img.attrs(() => ({
    className: 'ms-1 mb-1',
    src: externalLink,
}))`
  height: 0.8rem;
  width: 0.8rem;
`;

export const InfoIcon = styled.img.attrs(() => ({
    className: `ms-1 mb-1`,
    src: infoIcon,
}))`
  height: 0.8rem;
  width: 0.8rem;
`;

export const UnitIcon = styled.img.attrs(() => ({
    className: 'm-1',
    src: unitIcon,
}))`
  height: 1.5rem;
`;

export const WarningIcon = styled.img.attrs(() => ({
    className: `m-1`,
    src: warningIcon,
    role: 'button',
}))`
  height: 1.2rem;
  width: 1.2rem;
`;
