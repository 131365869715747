import styled from 'styled-components';
import _ from 'lodash';

const AsideGroupHeader = styled.div.attrs(({className}) => ({
    className: _.join([
        className,
        'mt-3',
        'lead',
        'border-bottom',
        'text-primary',
    ], ' '),
}))`
`;

export default AsideGroupHeader;
