import React, {useState} from 'react';
import {createSearchParams, useNavigate} from 'react-router-dom';
import {useLocalStorage} from 'usehooks-ts';
import styled from 'styled-components';
import search from '^assets/images/search.svg';
import {
    Button,
    Col,
    Form,
    OverlayTrigger,
    Popover,
    Row,
} from 'react-bootstrap';

const SearchIcon = styled.img.attrs(() => ({
    className: 'mx-4',
    role: 'button',
    src: search,
    alt: 'Search',
}))`
  height: 2rem;
  width: 2rem;
`;

const SearchForm = ({
    setShowSearchOverlay,
}) => {
    const navigate = useNavigate();
    const [userFacilityLabel] = useLocalStorage('facility_label', '');
    const [scanType, setScanType] = useState('work_ticket');
    const [scanValue, setScanValue] = useState('');

    const searchSubmit = async (type, value) => {
        if (!value) {
            return;
        }

        let searchParams;

        switch (type) {
            case 'work_ticket':
                searchParams = {work_ticket_number: value};
                break;
            case 'shipment':
                searchParams = {shipment_number: value};
                break;
            case 'order':
                searchParams = {order_number: value};
                break;
            case 'unit':
                searchParams = {serial_number: value};
                break;
            default:
                return;
        }

        if (!searchParams) {
            return;
        }

        setScanValue('');

        if (setShowSearchOverlay) {
            setShowSearchOverlay(false);
        }

        navigate({
            pathname: '/work-tickets',
            search: `?${createSearchParams(searchParams)}`,
        });
    };

    return <Form onSubmit={(e) => {
        e.preventDefault();
        searchSubmit(scanType, scanValue);
    }}>
        <Row>
            <Col sm={4}>
                <Form.Select
                    onChange={(e) => setScanType(e.target.value)}
                    value={scanType}
                >
                    <option value={'work_ticket'}>{'Work'}</option>
                    <option value={'shipment'}>{'Shipment'}</option>
                    <option value={'order'}>{'Order'}</option>
                    <option value={'unit'}>{'Unit'}</option>
                </Form.Select>
            </Col>
            <Col sm={6}>
                <Form.Control
                    type={'search'}
                    placeholder={`Search ${userFacilityLabel}`}
                    value={scanValue}
                    onChange={(e) => setScanValue(e?.target?.value)}
                />
            </Col>
            <Col sm={2}>
                <Button
                    className={'w-100'}
                    type={'submit'}
                    variant={'outline-success'}
                >
                    {'Go'}
                </Button>
            </Col>
        </Row>
    </Form>;
};

const TopNavSearch = ({
    userFacility,
}) => {
    const [showSearchOverlay, setShowSearchOverlay] = useState(false);

    if (!userFacility) {
        return null;
    }

    if (userFacility === 'GLOBAL') {
        return null;
    }

    return <>
        <OverlayTrigger
            trigger={'click'}
            placement={'bottom'}
            show={showSearchOverlay}
            onToggle={(val) => setShowSearchOverlay(val)}
            rootClose={true}
            overlay={<Popover className={'d-lg-none mw-100'}>
                <Popover.Body>
                    <SearchForm setShowSearchOverlay={setShowSearchOverlay}/>
                </Popover.Body>
            </Popover>}
        >
            <SearchIcon className={'d-lg-none'}/>
        </OverlayTrigger>
        <div className={'d-none d-lg-block'}>
            <SearchForm/>
        </div>
    </>;
};

export default TopNavSearch;
