import React from 'react';
import _ from 'lodash';
import {buildAssembly} from '^pages/packing/common/util';
import UnitRow from './unitRow';

const AssemblyGroup = ({
    parent,
    allUnits,
    workTicketId,
    updateUnit,
    isStageEditable,
    isResourceEditable,
    showAssetDetails,
    stages,
    defaultResources,
    searchResources,
}) => {
    const assembly = buildAssembly(parent, allUnits);

    return <div className={'mb-2'}>
        {_.map(assembly, (unit, idx) => <UnitRow
            key={unit.unit_id}
            first={idx === 0}
            bg={idx === 0 ? 'secondary' : 'white'}
            unit={unit}
            assembly={assembly}
            workTicketId={workTicketId}
            count={idx === 0 ? _.size(assembly) : 0}
            updateUnit={updateUnit}
            isStageEditable={isStageEditable && idx === 0}
            isResourceEditable={isResourceEditable && idx === 0}
            showAssetDetails={showAssetDetails}
            stages={stages}
            defaultResources={defaultResources}
            searchResources={searchResources}
        />)}
    </div>;
};

export default AssemblyGroup;
