import React, {useContext, useEffect, useRef} from 'react';
import {Route, Routes, useNavigate} from 'react-router-dom';
import styled, {keyframes} from 'styled-components';
import _ from 'lodash';
import {ApiContext} from '^contexts/api';
import {AppContext} from '^contexts/app';
import routesConfig from '^src/routesConfig';
import Aside from '^sections/aside';
import LeftNav from '^sections/leftNav';
import {Col, Container, Row} from 'react-bootstrap';
import {ScrollButtons} from '^utilities/useScrollButtons';

const RequireAuth = ({children}) => {
    const api = useContext(ApiContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (!api) {
            navigate('/logout');
        }
    }, [api, navigate]);

    return children;
};

const ScrollableCol = styled(Col)`
  margin-top: 4rem;
  height: calc(100svh - 7rem);
  overflow-y: scroll;

  @media print {
    margin-top: 0;
    overflow-y: visible;
  }
`;

const animateInLeft = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
`;

const LeftNavCol = styled(ScrollableCol).attrs(() => ({
    className: 'bg-secondary position-absolute z-2 d-print-none',
    xl: 2,
    lg: 3,
    md: 4,
    sm: 5,
    xs: 7,
}))`
  box-shadow: 3px 0 6px #00000029;

  animation-name: ${animateInLeft};
  animation-duration: 0.5s;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const animateInRight = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
`;

const AsideCol = styled(ScrollableCol).attrs(() => ({
    className: 'bg-white position-absolute z-2 d-print-none',
    xl: 4,
    lg: 5,
    md: 6,
    sm: 7,
    xs: 9,
}))`
  right: 0;
  box-shadow: -3px 0 6px #00000029;

  animation-name: ${animateInRight};
  animation-duration: 0.5s;
`;

const MainRow = styled(Row)`
  height: 100svh;

  @media print {
    height: fit-content;

    .row {
      break-inside: avoid;
    }
  }
`;

const Main = () => {
    const {
        asideChildren,
        leftNavOpen,
        setAsideChildren,
        setLeftNavOpen,
        showScrollButtons,
    } = useContext(AppContext);

    const middleColRef = useRef(null);

    useEffect(() => {
        const handleClick = (event) => {
            if (!middleColRef.current) {
                return;
            }

            if (middleColRef.current.contains(event.target)) {
                setLeftNavOpen(false);
            }
        };

        window.addEventListener('click', handleClick);

        return () => {
            window.removeEventListener('click', handleClick);
        };
    }, [setLeftNavOpen]);

    useEffect(() => {
        const handleKeyup = (event) => {
            if (event.key === 'Escape') {
                setAsideChildren(null);
                setLeftNavOpen(false);
            }
        };

        window.addEventListener('keyup', handleKeyup);

        return () => {
            window.removeEventListener('keyup', handleKeyup);
        };
    }, [setAsideChildren, setLeftNavOpen]);

    return <main>
        <Container fluid={true}>
            <MainRow>
                {leftNavOpen && <LeftNavCol>
                    <LeftNav/>
                </LeftNavCol>}
                <ScrollableCol ref={middleColRef}>
                    {showScrollButtons && <ScrollButtons
                        container={middleColRef}
                    />}
                    <Routes>
                        {_.map(routesConfig, (route) =>
                            <Route
                                key={route.path}
                                exact={true}
                                path={route.path}
                                element={route.authentication
                                    ? route.element
                                    : <RequireAuth>
                                        {route.element}
                                    </RequireAuth>}
                            />,
                        )}
                    </Routes>
                </ScrollableCol>
                {asideChildren && <AsideCol>
                    <Aside/>
                </AsideCol>}
            </MainRow>
        </Container>
    </main>;
};

export default Main;
