import React, {useCallback, useContext, useEffect, useState} from 'react';
import {createSearchParams, useSearchParams} from 'react-router-dom';
import _ from 'lodash';
import {useLocalStorage} from 'usehooks-ts';
import {toast} from 'react-toastify';
import {ApiContext} from '^contexts/api';
import {isResponseError} from '^utilities/isResponseError';
import {
    Button,
    Col,
    Container,
    Form,
    OverlayTrigger,
    Popover,
    Row,
    Spinner,
} from 'react-bootstrap';
import exportIcon from '^assets/images/export.svg';
import {PageActionIcon} from '^common/pageAction';
import {InfoIcon} from '../common/icon';
import KitResult from './kitResult';
import UnitResult from './unitResult';

const ByUnitKitTab = ({
    setActionButtons,
    setActionText,
    filterStages,
}) => {
    const api = useContext(ApiContext);
    const [searchParams, setSearchParams] = useSearchParams();
    const [userFacility] = useLocalStorage('facility_id', '');

    const [searchSerialNumber, setSearchSerialNumber] = useState('');
    const [searchKitLabel, setSearchKitLabel] = useState('');
    const [resultsSummary, setResultsSummary] = useState('');
    const [unit, setUnit] = useState(null);
    const [kit, setKit] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showAssetDetails, setShowAssetDetails] = useState(true);

    const searchForUnit = useCallback(async (serialNumber) => {
        setLoading(true);
        setResultsSummary('');

        const response = await api.get('/units', {
            params: {
                serial_number: serialNumber,
                facility_id: userFacility,
                stage: _.join(filterStages, ','),
            },
        });

        setLoading(false);

        if (isResponseError(response)) {
            toast.error(response?.data?.error);
            return;
        }

        const numberResults = response?.data?.total_count;

        if (numberResults === 0) {
            setResultsSummary('No results found for'
                + ` serial number "${serialNumber}".`);
            return;
        }

        setResultsSummary(numberResults === 1
            ? `Found serial number "${serialNumber}".`
            : `Multiple results found for serial number "${serialNumber}".`
            + ' Showing most-recent result.');

        const unit = response?.data?.results?.[0];
        const kit = unit?.kit;

        if (kit) {
            setKit(kit);
            return;
        }

        setUnit(unit);
    }, [api, filterStages, userFacility]);

    const searchForKit = useCallback(async (label) => {
        setLoading(true);
        setResultsSummary('');

        const response = await api.get('/kits', {
            params: {
                label: label,
                facility_id: userFacility,
                stage: _.join(filterStages, ','),
            },
        });

        setLoading(false);

        if (isResponseError(response)) {
            toast.error(response?.data?.error);
            return;
        }

        const numberResults = response?.data?.total_count;

        if (numberResults === 0) {
            setResultsSummary(`No results found for kit "${label}".`);
            return;
        }

        setResultsSummary(numberResults === 1
            ? `Found kit "${label}".`
            : `Multiple results found for kit "${label}".`
            + ' Showing most-recent result.');

        setKit(response?.data?.results?.[0]);
    }, [api, filterStages, userFacility]);

    const searchSubmit = useCallback(() => {
        setSearchSerialNumber('');
        setSearchKitLabel('');

        if (searchSerialNumber) {
            setSearchParams(createSearchParams({
                serial_number: searchSerialNumber,
            }));
        }

        if (searchKitLabel) {
            setSearchParams(createSearchParams({
                kit_label: searchKitLabel,
            }));
        }
    }, [searchKitLabel, searchSerialNumber, setSearchParams]);

    useEffect(() => {
        const serialNumber = searchParams.get('serial_number');
        const kitLabel = searchParams.get('kit_label');

        if (_.size(filterStages) < 1) {
            return;
        }

        if (serialNumber) {
            setUnit(null);
            setKit(null);
            searchForUnit(serialNumber);
            return;
        }

        if (kitLabel) {
            setUnit(null);
            setKit(null);
            searchForKit(kitLabel);
        }
    }, [filterStages, searchForKit, searchForUnit, searchParams]);

    useEffect(() => {
        setActionButtons(<PageActionIcon
            src={exportIcon}
            alt={'Export'}
            onClick={null}
        />);
    }, [setActionButtons]);

    useEffect(() => {
        setActionText(<>
            <div
                className={'d-inline text-primary'}
                role={'button'}
                onClick={() => setShowAssetDetails(!showAssetDetails)}
            >
                {`${showAssetDetails ? 'Hide' : 'Show'}  Asset Details`}
            </div>
            <OverlayTrigger
                overlay={<Popover>
                    <Popover.Body>
                        {`${showAssetDetails ? 'Hide' : 'Show'}`
                            + ' asset capture values for all Units'}
                    </Popover.Body>
                </Popover>}
            >
                <InfoIcon/>
            </OverlayTrigger>
        </>);
    }, [setActionText, showAssetDetails]);

    return <>
        <Container fluid={true} className={'my-2'}>
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                    searchSubmit();
                }}
            >
                <Row className={'justify-content-center'}>
                    <Col xs={8} md={3} xl={2}>
                        <Form.Control
                            type={'search'}
                            placeholder={`Search Unit S/N`}
                            value={searchSerialNumber}
                            onChange={(e) => {
                                setSearchKitLabel('');
                                setSearchSerialNumber(
                                    _.toUpper(e?.target?.value),
                                );
                            }}
                        />
                    </Col>
                    <Col
                        xs={4}
                        md={1}
                        className={'text-center my-auto fw-bold'}
                    >
                        {'OR'}
                    </Col>
                    <Col xs={8} md={3} xl={2}>
                        <Form.Control
                            type={'search'}
                            placeholder={`Search Kit Label`}
                            value={searchKitLabel}
                            onChange={(e) => {
                                setSearchSerialNumber('');
                                setSearchKitLabel(e?.target?.value);
                            }}
                        />
                    </Col>
                    <Col xs={4} md={1}>
                        <Button
                            className={'w-100'}
                            type={'submit'}
                            variant={'success'}
                        >
                            {'Go'}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Container>
        <Container fluid={true} className={'my-3'}>
            <Row className={'my-3 justify-content-center fw-bold'}>
                {loading && <Spinner/>}
                {resultsSummary}
            </Row>
            {unit && <UnitResult
                unit={unit}
                setActionButtons={setActionButtons}
                showAssetDetails={showAssetDetails}
            />}
            {kit && <KitResult
                kit={kit}
                setActionButtons={setActionButtons}
                showAssetDetails={showAssetDetails}
            />}
        </Container>
    </>;
};

export default ByUnitKitTab;
