import React from 'react';
import {createSearchParams, Link} from 'react-router-dom';
import _ from 'lodash';
import {OverlayTrigger, Popover} from 'react-bootstrap';
import AssetBarcode from '../common/assetBarcode';
import BaseCol from '../common/baseCol';
import BaseRow from '../common/baseRow';
import {
    ArrowRight,
    ExternalLinkIcon,
    UnitIcon,
} from '../common/icon';
import UnitAssetDetails from '../common/unitAssetDetails';
import {ClipboardText} from '^common/clipboardText';


const UnitRow = ({
    unit,
    count = 0,
    bg = 'white',
    first = false,
    showAssetDetails = false,
}) => {
    const serialized = unit?.serialized ?? true;
    const serial = unit?.serial_number;
    const sku = unit?.sku ?? '';
    const barcode = unit?.barcode;

    const CountCol = (props) => <BaseCol {...props}>
        {count > 0 && <>
            <UnitIcon/>
            <span>{count}</span>
        </>}
    </BaseCol>;

    return <BaseRow $first={first} $bg={bg}>
        <BaseCol xs={2} md={1} xl={1} className={'pe-0'}>
            {_.times(
                unit.assembly_level ?? 0,
                (idx) => <ArrowRight key={idx}/>,
            )}
        </BaseCol>
        <BaseCol className={'text-primary'} xs={8} md={3} xl={2}>
            <Link
                className={'text-decoration-none text-primary'}
                to={{
                    pathname: '/packing',
                    search: `?${createSearchParams({
                        work_ticket_number: unit.work_ticket.work_ticket_number,
                    })}`,
                }}
            >
                {unit.work_ticket.work_ticket_number}
                <ExternalLinkIcon/>
            </Link>
        </BaseCol>
        <CountCol className={'d-md-none'} xs={2}/>
        <BaseCol className={'text-primary'} xs={6} md={4} xl={2}>
            <OverlayTrigger
                trigger={'click'}
                rootClose={true}
                overlay={<Popover>
                    <Popover.Body>
                        {unit.config.part.description}
                    </Popover.Body>
                </Popover>}
            >
                <div role={'button'} className={'d-inline'}>
                    {`P/N: ${barcode ?? sku}`}
                </div>
            </OverlayTrigger>
            <ClipboardText value={barcode ?? sku}/>
        </BaseCol>
        <BaseCol xs={6} md={4} xl={2}>
            <AssetBarcode value={barcode ?? sku}/>
        </BaseCol>
        <CountCol className={'d-none d-md-block d-xl-none'} md={4}/>
        <BaseCol className={'text-primary'} xs={6} md={4} xl={2}>
            {`S/N: ${serialized
                ? serial ?? 'NOT-CAPTURED'
                : 'Non-Serialized'}`}
            {serialized && serial && <ClipboardText value={serial}/>}
        </BaseCol>
        <BaseCol xs={6} md={4} xl={2}>
            {serialized && serial && <AssetBarcode value={serial}/>}
        </BaseCol>
        <CountCol className={'d-none d-xl-block'} xl={1}/>
        {showAssetDetails && <UnitAssetDetails unit={unit}/>}
    </BaseRow>;
};

export default UnitRow;
