import React from 'react';
import _ from 'lodash';
import {buildAssembly} from '../common/util';
import AssetBarcode from '../common/assetBarcode';
import BaseCol from '../common/baseCol';
import BaseRow from '../common/baseRow';
import {UnitIcon} from '../common/icon';
import UnitRow from './unitRow';

const KitGroup = ({
    kit,
    allUnits,
    workTicketId,
    showAssetDetails = false,
    serialFilter = null,
}) => {
    const kitUnits = _.filter(
        allUnits,
        (unit) => unit.kit?.kit_id === kit.kit_id,
    );

    const assemblyParents = _.chain(kitUnits)
        .filter((unit) => unit.has_assembly_components)
        .filter((unit) => !unit.assembled_into_unit_id)
        .value();

    const kitAssemblies = _.map(
        assemblyParents,
        (parent) => buildAssembly(parent, allUnits),
    );

    const standaloneUnits = _.differenceBy(
        kitUnits,
        _.flatten(kitAssemblies),
        'unit_id',
    );

    const kitUnitSerials = _.chain(kitUnits)
        .map('serial_number')
        .compact()
        .value();

    if (serialFilter && !_.includes(kitUnitSerials, serialFilter)) {
        return null;
    }

    return <div className={'mb-2'}>
        <BaseRow $first={true} $bg={'secondary'}>
            <BaseCol xs={1}/>
            <BaseCol className={'text-primary'}>
                {`Label: ${kit.label}`}
            </BaseCol>
            <BaseCol>
                <AssetBarcode value={kit.label}/>
            </BaseCol>
            <BaseCol xs={1}>
                <UnitIcon/>
                <span>{_.size(kitUnits)}</span>
            </BaseCol>
        </BaseRow>
        {_.map(kitAssemblies, (assembly) =>
            _.map(assembly, (unit) => <UnitRow
                key={unit.unit_id}
                unit={unit}
                workTicketId={workTicketId}
                showAssetDetails={showAssetDetails}
            />),
        )}
        {_.map(standaloneUnits, (unit) => <UnitRow
            key={unit.unit_id}
            unit={unit}
            workTicketId={workTicketId}
            showAssetDetails={showAssetDetails}
        />)}
    </div>;
};

export default KitGroup;
