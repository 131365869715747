import styled from 'styled-components';
import _ from 'lodash';
import {Row} from 'react-bootstrap';

const BaseRow = styled(Row).attrs(({
    $bg,
    $first,
}) => ({
    className: _.join([
        'm-0',
        'py-2',
        'border-primary',
        `bg-${$bg}`,
        $first ? 'border-top' : '',
        'border-bottom',
        'border-start',
        'border-end',
    ], ' '),
}))`
    row-gap: 1.5rem;
`;

export default BaseRow;
