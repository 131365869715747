import React, {useCallback, useContext, useEffect, useState} from 'react';
import _ from 'lodash';
import {isResponseError} from '^utilities/isResponseError';
import {ApiContext} from '^contexts/api';
import {AppContext} from '^contexts/app';
import AsideHeader from '^common/asideHeader';
import DurationInput from '^common/durationInput';
import {Button, Col, Form, Row} from 'react-bootstrap';
import {toast} from 'react-toastify';

const SettingEdit = ({
    setting,
    updateSetting,
}) => {
    const api = useContext(ApiContext);
    const {setAsideChildren} = useContext(AppContext);
    const [processing, setProcessing] = useState(false);

    const [booleanValue, setBooleanValue] = useState(null);
    const [integerValue, setIntegerValue] = useState(null);
    const [stringValue, setStringValue] = useState(null);
    const [durationValue, setDurationValue] = useState(null);
    const [percentValue, setPercentValue] = useState(null);

    const settingId = setting?.setting_id;

    useEffect(() => {
        setBooleanValue(setting?.boolean_value ?? null);
        setIntegerValue(setting?.integer_value ?? null);
        setStringValue(setting?.string_value ?? null);
        setDurationValue(setting?.duration_value ?? null);
        setPercentValue(setting?.percent_value ?? null);
    }, [
        setting?.boolean_value,
        setting?.duration_value,
        setting?.integer_value,
        setting?.percent_value,
        setting?.string_value,
    ]);

    const editSetting = useCallback(async () => {
        setProcessing(true);

        const response = await api.patch(`/settings/${settingId}`, {
            boolean_value: booleanValue,
            integer_value: integerValue,
            string_value: stringValue,
            duration_value: durationValue,
            percent_value: percentValue,
        });

        setProcessing(false);

        if (isResponseError(response)) {
            toast.error(response?.data?.error);
            return;
        }

        updateSetting(response?.data);
        setAsideChildren(null);
    }, [
        api,
        booleanValue,
        durationValue,
        integerValue,
        percentValue,
        setAsideChildren,
        settingId,
        stringValue,
        updateSetting,
    ]);

    return <>
        <AsideHeader>
            {`Edit Setting "${setting?.description ?? ''}"`}
        </AsideHeader>
        <Form
            className={'py-2'}
            onSubmit={(event) => {
                event.preventDefault();
                editSetting();
            }}
        >
            {!_.isNil(booleanValue) && <Form.Check
                disabled={processing}
                type={'checkbox'}
                label={booleanValue ? '(Yes)': '(No)'}
                checked={booleanValue}
                onChange={(e) => setBooleanValue(e.target.checked)}
            />}
            {!_.isNil(integerValue) && <Form.Control
                disabled={processing}
                required={true}
                type={'number'}
                min={0}
                step={1}
                value={integerValue}
                onChange={(e) => setIntegerValue(
                    _.toInteger(e.target.value),
                )}
            />}
            {!_.isNil(stringValue) && <Form.Control
                disabled={processing}
                required={true}
                type={'text'}
                value={stringValue}
                onChange={(e) => setStringValue(e.target.value)}
            />}
            {!_.isNil(durationValue) && <DurationInput
                disabled={processing}
                value={durationValue}
                onChange={setDurationValue}
            />}
            {!_.isNil(percentValue) && <Row className={'align-items-center'}>
                <Col>
                    <Form.Control
                        disabled={processing}
                        required={true}
                        type={'number'}
                        min={0}
                        max={100}
                        step={1}
                        value={percentValue}
                        onChange={(e) => setPercentValue(
                            _.toInteger(e.target.value),
                        )}
                    />
                </Col>
                <Col xs={1}>{'%'}</Col>
            </Row>}
            <Button
                className={'w-100 my-3'}
                variant={'success'}
                disabled={processing}
                type={'submit'}
            >
                {'Submit'}
            </Button>
        </Form>
    </>;
};

export default SettingEdit;
