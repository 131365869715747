import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import _ from 'lodash';
import {toast} from 'react-toastify';
import {captureFields} from '^config/captureFields';
import {ApiContext} from '^contexts/api';
import {AppContext} from '^contexts/app';
import {isResponseError} from '^utilities/isResponseError';
import externalLink from '^assets/images/externalLink.svg';
import history from '^assets/images/history.svg';
import verticalEllipsis from '^assets/images/verticalEllipsis.svg';
import AsideGroupHeader from '^common/asideGroupHeader';
import AsideHeader from '^common/asideHeader';
import HistoryAside from '^common/historyAside';
import {TableIcon} from '^common/tableIcon';
import {
    countDescendants,
    getDescendants,
    renderDescendants,
} from '^pages/workTicket/utilities/kittingAssemblyHelper';
import {Container, Row, Col, Dropdown} from 'react-bootstrap';

const WorkTicketUnitAside = ({
    unit,
    dropdownItems,
}) => {
    const api = useContext(ApiContext);
    const {setAsideChildren} = useContext(AppContext);

    const [assemblyParent, setAssemblyParent] = useState(null);
    const [descendantUnits, setDescendantUnits] = useState({});
    const [descendantTree, setDescendantTree] = useState({});

    useEffect(() => {
        const getUnit = async () => {
            const response = await api.get(
                `units/${unit?.assembled_into_unit_id}`);

            if (isResponseError(response)) {
                toast.error('Failed to fetch Unit');
                return;
            }

            setAssemblyParent(response?.data);
        };

        const getAssembly = async () => {
            const response = await api.get(`/units/${unit?.unit_id}/assembly`);

            if (isResponseError(response)) {
                toast.error('Failed to fetch Unit assembly');
                return;
            }

            setDescendantUnits(response?.data);
        };

        if (unit?.assembled_into_unit_id) {
            getUnit();
        }
        if (!_.isNil(unit?.serial_number)) {
            getAssembly();
        }
    }, [
        api,
        unit?.unit_id,
        unit?.serial_number,
        unit?.assembled_into_unit_id,
    ]);

    useEffect(() => {
        if (descendantUnits) {
            setDescendantTree(
                getDescendants(descendantUnits, unit?.unit_id),
            );
        }
    }, [descendantUnits, unit?.unit_id]);

    const details = {
        standard_fields: {
            'Description': unit?.config?.part?.description,
            'Stage': unit?.stage,
            'Resource': unit?.resource?.label,
        },
        assemblies: {
            'SubComponent of':
                assemblyParent?.serial_number
                    ? `${assemblyParent?.serial_number} 
                    (${assemblyParent?.sku ?? ''})`
                    : 'N/A',
            'SubComponents': renderDescendants(descendantTree),
        },
        kits: {
            'Included In': unit?.kit?.label ?? 'N/A',
        },
    };

    const historyAsideProps = {
        label: unit?.serial_number ?? 'N/A',
        baseEndpoint: `/units/${unit?.unit_id}`,
        onReturn: useCallback(() => {
            setAsideChildren(<WorkTicketUnitAside
                unit={unit}
                dropdownItems={dropdownItems}
            />);
        }, [dropdownItems, setAsideChildren, unit]),
    };

    const historyOnClicks = {
        'Stage': () => {
            setAsideChildren(<HistoryAside
                {...historyAsideProps}
                field={'stage'}
            />);
        },
        'Resource': () => {
            setAsideChildren(<HistoryAside
                {...historyAsideProps}
                field={'resource.label'}
            />);
        },
    };

    return <>
        <AsideHeader
            className={'d-flex justify-content-between'}
        >
            <b>{unit?.serial_number ?? 'N/A'}</b>
            <Dropdown className={'mt-n1 pe-4'}>
                <Dropdown.Toggle
                    as={'img'}
                    role={'button'}
                    src={verticalEllipsis}
                    alt={'Dropdown Toggle'}
                    height={'20'}
                    width={'20'}
                />
                <Dropdown.Menu>
                    {dropdownItems(true, unit)}
                </Dropdown.Menu>
            </Dropdown>
        </AsideHeader>
        <AsideGroupHeader>
            {'Standard Fields'}
        </AsideGroupHeader>
        <Container>
            {_.map(details.standard_fields, (value, key) => (
                <Row key={key}>
                    <Col>
                        {`${key}: ${value ?? 'N/A'}`}
                    </Col>
                    {_.has(historyOnClicks, key)
                        ? <Col xs={1} className={'text-end'}>
                            <TableIcon
                                src={history}
                                onClick={historyOnClicks[key]}
                            />
                        </Col> : null}
                </Row>
            ))}
        </Container>
        <AsideGroupHeader>
            {'Asset Capture'}
        </AsideGroupHeader>
        <Container>
            {_.map(captureFields, (label, key) => (
                <Row key={key}>
                    <Col>
                        {`${label}: ${unit?.asset_capture?.[key] ?? 'N/A'}`}
                    </Col>
                </Row>
            ))}
        </Container>
        <AsideGroupHeader>
            {'Assemblies'}
        </AsideGroupHeader>
        <Container>
            {_.map(details.assemblies, (value, key) => (
                <Row key={key}>
                    <Col>
                        {`${key}: ${key === 'SubComponents'
                            ? countDescendants(descendantTree, true)
                            : ''
                        }`}
                        {value}
                        {key === 'SubComponent of' && value !== 'N/A'
                            ? <Link
                                onClick={() => setAsideChildren(null)}
                                to={'/work-tickets'
                                    + `/${assemblyParent?.work_ticket
                                        ?.work_ticket_id}`
                                    + `/${assemblyParent?.config
                                        ?.config_id}`
                                    + '/units'}
                            >
                                <TableIcon src={externalLink}/>
                            </Link>
                            : null
                        }
                    </Col>
                </Row>
            ))}
        </Container>
        <AsideGroupHeader>
            {'Kits'}
        </AsideGroupHeader>
        <Container>
            {_.map(details.kits, (value, key) => (
                <Row key={key}>
                    <Col>
                        {`${key}: `}
                        {value}
                        {value !== 'N/A'
                            ? <Link
                                onClick={() => setAsideChildren(null)}
                                to={'/work-tickets/'
                                    + unit?.work_ticket?.work_ticket_id
                                    + '/kits'}
                            >
                                <TableIcon src={externalLink}/>
                            </Link>
                            : null
                        }
                    </Col>
                </Row>
            ))}
        </Container>
    </>;
};

export default WorkTicketUnitAside;
