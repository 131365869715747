import TechnicianUnitsCompleted from './aside/technicianUnitsCompleted';
import WorkTicketStatus from './aside/workTicketStatus';

export default Object.freeze({
    unit_stage_by_user: {
        label: 'Technician Units Completed',
        Aside: TechnicianUnitsCompleted,
        widths: {
            'Customer': '40ch',
            'User': '25ch',
        },
    },
    work_ticket_status: {
        label: 'Work Ticket Status',
        Aside: WorkTicketStatus,
        types: {
            'Status Updated': 'datetime',
            'Priority': 'boolean',
            'Project': 'boolean',
            'Ship Date': 'date',
            'Start': 'datetime',
        },
        widths: {
            'Order#': '10ch',
            'Customer': '40ch',
            'Status Updated': '25ch',
            'Priority': '10ch',
            'Project': '10ch',
            'Start': '25ch',
            'Last Note': '40ch',
        },
    },
});
