import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import {Spinner} from 'react-bootstrap';
import {TableRow} from '^common/tableRow';
import {TableCol} from '^common/tableCol';

const SelectableRow = styled(TableRow)`
    cursor: pointer;
    background-color: ${({selected}) =>
        selected && 'var(--bs-info) !important'};
    font-weight: ${({selected}) => selected && 'bold'};
`;

const prettyIntervalName = (intervalName) => {
    const num = _.chain(intervalName)
        .split('_')
        .first()
        .toSafeInteger()
        .value();

    const suffix = num > 1
        ? 's'
        : '';

    return _.startCase(`${intervalName}${suffix}`);
};

const Table = ({
    metrics,
    loading,
    countLabel,
    selectedMetric,
    setSelectedMetric,
}) => {
    return <>
        <TableRow $header={true}>
            <TableCol xs={6}>
                {'Interval'}
            </TableCol>
            <TableCol>
                {countLabel}
            </TableCol>
        </TableRow>
        {loading && <Spinner/>}
        {!loading && _.map(metrics?.total, (val, key) =>
            <SelectableRow
                className={'bg-black'}
                key={key}
                selected={selectedMetric === key}
                onClick={() => setSelectedMetric(key)}
            >
                <TableCol xs={6}>
                    {prettyIntervalName(key)}
                </TableCol>
                <TableCol>
                    {val}
                </TableCol>
            </SelectableRow>)
        }
    </>;
};

export default Table;
