import React from 'react';
import _ from 'lodash';
import {captureFields} from '^config/captureFields';
import {Row} from 'react-bootstrap';
import BaseCol from '^pages/packing/common/baseCol';

const UnitAssetDetails = ({
    unit,
}) => {
    const assetCapture = _.pickBy(unit.asset_capture);

    if (_.size(assetCapture) === 0) {
        return null;
    }

    return <Row>
        {_.map(assetCapture, (val, field) => <BaseCol
            key={field}
            className={'text-center'}
            lg={4}
            md={6}
            xs={12}
        >
            <div className={'d-inline text-primary pe-2'}>
                {`${captureFields[field]}:`}
            </div>
            <div className={'d-inline fst-italic'}>
                {val}
            </div>
        </BaseCol>)}
    </Row>;
};

export default UnitAssetDetails;
