import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import AssemblyGroup from './assemblyGroup';
import KitGroup from './kitGroup';
import UnitRow from './unitRow';
import {
    getKits,
    getAssemblyParents,
    getStandaloneUnits,
} from '^pages/workTicket/unitOverview/unitOverviewHelper';

const SectionHeader = styled.h4.attrs(() => ({
    className: 'text-primary mt-3',
}))`
    text-decoration: underline;
`;

const UnitGroup = ({
    units,
    workTicketId,
    updateUnit,
    isStageEditable,
    isResourceEditable,
    showAssetDetails,
    stages,
    defaultResources,
    searchResources,
}) => {
    const kits = getKits(units);
    const assemblyParents = getAssemblyParents(units);
    const standaloneUnits = getStandaloneUnits(units);

    return <>
        <SectionHeader>{'Kits'}</SectionHeader>
        {_.map(kits, (kit) => <KitGroup
            key={kit.kit_id}
            kit={kit}
            allUnits={units}
            workTicketId={workTicketId}
            updateUnit={updateUnit}
            isStageEditable={isStageEditable}
            isResourceEditable={isResourceEditable}
            showAssetDetails={showAssetDetails}
            stages={stages}
            defaultResources={defaultResources}
            searchResources={searchResources}
        />)}
        <SectionHeader>{'Assemblies (Not in Kits)'}</SectionHeader>
        {_.map(assemblyParents, (parent) => <AssemblyGroup
            key={parent.unit_id}
            parent={parent}
            allUnits={units}
            workTicketId={workTicketId}
            updateUnit={updateUnit}
            isStageEditable={isStageEditable}
            isResourceEditable={isResourceEditable}
            showAssetDetails={showAssetDetails}
            stages={stages}
            defaultResources={defaultResources}
            searchResources={searchResources}
        />)}
        <SectionHeader>{'Standalone Units'}</SectionHeader>
        {_.map(standaloneUnits, (unit, idx) => <UnitRow
            key={unit.unit_id}
            first={idx === 0}
            unit={unit}
            workTicketId={workTicketId}
            updateUnit={updateUnit}
            isStageEditable={isStageEditable}
            isResourceEditable={isResourceEditable}
            showAssetDetails={showAssetDetails}
            stages={stages}
            defaultResources={defaultResources}
            searchResources={searchResources}
        />)}
    </>;
};

export default UnitGroup;
