import React, {useCallback, useContext, useEffect, useState} from 'react';
import {isResponseError} from '^utilities/isResponseError';
import {ApiContext} from '^contexts/api';
import {AppContext} from '^contexts/app';
import AsideHeader from '^common/asideHeader';
import {Button, Form} from 'react-bootstrap';
import {toast} from 'react-toastify';

const CustomerCreateEdit = ({
    customer,
    updateCustomer,
}) => {
    const api = useContext(ApiContext);
    const {setAsideChildren} = useContext(AppContext);
    const [processing, setProcessing] = useState(false);
    const [label, setLabel] = useState('');

    const customerId = customer?.customer_id;

    useEffect(() => {
        setLabel(customer?.label ?? '');
    }, [customer?.label]);

    const createEditCustomer = useCallback(async () => {
        setProcessing(true);
        const body = {label};

        const response = customerId
            ? await api.patch(`/customers/${customerId}`, body)
            : await api.post('/customers', body);

        setProcessing(false);

        if (isResponseError(response)) {
            toast.error(response?.data?.error);
            return;
        }

        updateCustomer(response?.data);
        setAsideChildren(null);
    }, [
        api,
        customerId,
        label,
        setAsideChildren,
        updateCustomer,
    ]);

    return <>
        <AsideHeader>
            {customerId
                ? `Edit Customer "${customer?.label ?? ''}"`
                : 'Create New Customer'}
        </AsideHeader>
        <Form onSubmit={(event) => {
            event.preventDefault();
            createEditCustomer();
        }}>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Label'}</Form.Text>
                <Form.Control
                    disabled={customer?.has_work_ticket || processing}
                    type={'text'}
                    required={true}
                    value={label}
                    onChange={(e) => setLabel(e.target.value)}
                />
            </Form.Group>
            <Button
                className={'w-100 my-3'}
                variant={'success'}
                disabled={processing || !label}
                type={'submit'}
            >
                {'Submit'}
            </Button>
        </Form>
    </>;
};

export default CustomerCreateEdit;
