import React from 'react';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import logout from '^assets/images/logout.svg';

const NavLogout = styled.img.attrs(() => ({
    className: 'mx-2',
    role: 'button',
    src: logout,
    alt: 'Logout',
}))`
  height: 1.5rem;
  width: 1.5rem;
`;

const TopNavUser = ({
    me,
}) => {
    const navigate = useNavigate();

    return <>
        <div className={'align-end text-nowrap'}>
            <div>
                {me?.name ?? ''}
            </div>
            <div>
                {me?.email ?? ''}
            </div>
        </div>
        <NavLogout
            onClick={() => navigate('/logout')}
        />
    </>;
};

export default TopNavUser;
