import React, {useState, useCallback} from 'react';
import _ from 'lodash';
import CustomCard from '^common/customCard';
import {Button, Card} from 'react-bootstrap';
import {countDescendants, renderDescendants, getAssemblyUnits}
    from '^pages/workTicket/utilities/kittingAssemblyHelper';
import {usePagination} from '^utilities/usePagination';

const SerializedCard = ({
    api,
    unit,
    unitId,
    descendantTrees,
    setDescendantUnits,
    handleSubmit,
    kittingFilterCard=false,
}) => {
    const [submitted, setSubmitted] = useState(false);

    const [loaded, setLoaded] = useState(false);

    const fetchAssemblyForUnit = useCallback(() => {
        if (!loaded && api && unit) {
            setLoaded(true);
            getAssemblyUnits(unit, api, true)
                .then((result) => {
                    setDescendantUnits((prevState) => ({
                        ...prevState,
                        available: {...prevState.available, ...result},
                    }));
                })
                .catch((e) => console.error(e));
        }
    }, [loaded, unit, api, setDescendantUnits]);

    const elementRef = usePagination(null, fetchAssemblyForUnit);

    const assembled = unit?.assembled_into_unit_id === unitId;
    const kitted = unit?.kit !== null;
    const type = kitted ? 'combined' : 'available';
    const disabled = _.isNil(unit?.serial_number) && unit?.serialized;

    return (<CustomCard ref={elementRef} className={'mt-1'}>
        <Card.Body>
            <div>
                <b>{`Serial Number: ${unit?.serial_number
                ?? 'NOT-CAPTURED'}`}</b>
            </div>
            <div>
                {`Desc: ${unit?.config?.part?.description}`}
            </div>
            <div>
                {`${unit?.stage} | ${unit?.resource?.label ?? 'N/A'}`}
            </div>
            {/* eslint-disable max-len */}
            {assembled && unitId
                ? !_.isEmpty(descendantTrees?.combined[unitId]?.children[unit.unit_id]?.children)
                && <div>
                    {`${countDescendants(descendantTrees?.combined[unitId]?.children[unit.unit_id])} Subcomponents`}
                </div>
                : unitId || kittingFilterCard
                    ? !_.isEmpty(descendantTrees?.available[unit.unit_id]?.children)
                    && <div>
                        {`${countDescendants(descendantTrees?.available[unit.unit_id]?.children, true)} Subcomponents`}
                    </div>
                    : !_.isEmpty(descendantTrees[type][unit.unit_id]?.children)
                    && <div>
                        {`${countDescendants(descendantTrees[type][unit.unit_id]?.children[unit.unit_id])} Subcomponents`}
                    </div>
            }
            {assembled && unitId
                ? descendantTrees?.combined && renderDescendants(
                    descendantTrees?.combined[unitId]?.children[unit.unit_id]?.children)
                : unitId || kittingFilterCard
                    ? descendantTrees?.available && renderDescendants(
                        descendantTrees?.available[unit.unit_id]?.children)
                    : descendantTrees[type] && renderDescendants(
                        descendantTrees[type][unit.unit_id]?.children[unit.unit_id]?.children)
            }
            {/* eslint-disable max-len */}
            <Button
                disabled={disabled || submitted}
                className={'position-absolute top-50 end-0 translate-middle-y me-3'}
                size={'sm'}
                variant={((assembled && unitId) || kitted)
                    ? 'danger' : 'success'}
                onClick={() => {
                    setSubmitted(true);
                    handleSubmit(
                        unitId ? !assembled : !kitted, [unit.unit_id]);
                }}
            >{((assembled && unitId) || kitted)
                    ? 'Remove' : 'Add'}</Button>
        </Card.Body>
    </CustomCard>);
};

export default SerializedCard;
