import React, {useContext, useState, useEffect, useCallback} from 'react';
import {createSearchParams, useSearchParams} from 'react-router-dom';
import _ from 'lodash';
import {DateTime} from 'luxon';
import {ApiContext} from '^contexts/api';
import {isResponseError} from '^utilities/isResponseError';
import AsideHeader from '^common/asideHeader';
import {AsyncFormSelect, FormSelect} from '^common/formSelect';
import {Button, Form} from 'react-bootstrap';

const STAGE_OPTIONS = _.map([
    'On-Bench',
    'Pending-QC',
    'QC-Complete',
    'Work-Complete',
], (stage) => ({
    label: stage,
    value: stage,
}));

const userToOption = (user) => ({
    label: user?.name,
    value: user?.user_id,
});

const customerToOption = (customer) => ({
    label: customer?.label,
    value: customer?.customer_id,
});

const TechnicianUnitsCompleted = ({
    onSubmit,
}) => {
    const api = useContext(ApiContext);
    const [searchParams, setSearchParams] = useSearchParams();

    const [
        facilityId,
        setFacilityId,
    ] = useState(searchParams.get('facility_id'));

    const [
        userId,
        setUserId,
    ] = useState((() => {
        const val = _.chain(searchParams.get('user_id'))
            .split(',')
            .compact()
            .value();

        return _.isEmpty(val) ? null : val;
    })());

    const [
        stage,
        setStage,
    ] = useState(_.compact(_.split(searchParams.get('stage'), ',')));

    const [
        startDate,
        setStartDate,
    ] = useState(searchParams.get('start_date'));

    const [
        endDate,
        setEndDate,
    ] = useState(searchParams.get('end_date'));

    const [
        customerId,
        setCustomerId,
    ] = useState(searchParams.get('customer_id'));

    const [facilities, setFacilities] = useState([]);
    const [users, setUsers] = useState([]);
    const [customers, setCustomers] = useState([]);

    useEffect(() => {
        const fetchFacilities = async () => {
            const response = await api.get(
                '/facilities',
                {params: {active: 1}},
            );

            if (isResponseError(response)) {
                return;
            }

            setFacilities(response?.data?.results);
        };

        if (!api) {
            return;
        }

        fetchFacilities();
    }, [api]);

    const searchUsers = useCallback(async (name) => {
        if (!api) {
            return [];
        }

        const response = facilityId
            ? await api.get(`/facilities/${facilityId}/users`, {
                params: name ? {name} : {},
            })
            : await api.get('/users', {
                params: name ? {name} : {},
            });

        if (isResponseError(response)) {
            return [];
        }

        const users = response?.data?.results ?? [];

        setUsers((prevState) => _.uniqBy(
            [...prevState, ...users],
            'user_id',
        ));

        return _.map(users, userToOption);
    }, [api, facilityId]);

    useEffect(() => {
        const addUser = async (userId) => {
            if (!api) {
                return;
            }

            if (!userId) {
                return;
            }

            const response = await api.get(`/users/${userId}`);

            if (isResponseError(response)) {
                return;
            }

            setUsers((prevState) => _.uniqBy(
                [...prevState, response?.data],
                'user_id',
            ));
        };

        _.chain(userId)
            .difference(_.map(users, 'user_id'))
            .forEach(addUser)
            .value();
    }, [api, userId, users]);

    const searchCustomers = useCallback(async (label) => {
        if (!api) {
            return [];
        }

        const response = await api.get('/customers', {
            params: label ? {label} : {},
        });

        if (isResponseError(response)) {
            return [];
        }

        const customers = response?.data?.results ?? [];

        setCustomers((prevState) => _.uniqBy(
            [...prevState, ...customers],
            'customer_id',
        ));

        return _.map(customers, customerToOption);
    }, [api]);

    useEffect(() => {
        const addCustomer = async (customerId) => {
            if (!api) {
                return;
            }

            if (!customerId) {
                return;
            }

            const response = await api.get(`/customers/${customerId}`);

            if (isResponseError(response)) {
                return;
            }

            setCustomers((prevState) => _.uniqBy(
                [...prevState, response?.data],
                'customer_id',
            ));
        };

        const customerIds = _.map(customers, 'customer_id');

        if (!_.includes(customerIds, customerId)) {
            addCustomer(customerId);
        }
    }, [api, customerId, customers]);

    const resetFields = () => {
        setFacilityId(null);
        setUserId(null);
        setStage(null);
        setStartDate(null);
        setEndDate(null);
        setCustomerId(null);
    };

    const formSubmit = () => {
        const newSearchParams = {
            ...facilityId ? {facility_id: facilityId} : {},
            ..._.size(userId) ? {user_id: _.join(userId, ',')} : {},
            ..._.size(stage) ? {stage: _.join(stage, ',')} : {},
            ...startDate ? {start_date: startDate} : {},
            ...endDate ? {end_date: endDate} : {},
            ...customerId ? {customer_id: customerId} : {},
        };

        setSearchParams(_.size(newSearchParams)
            ? `?${createSearchParams(newSearchParams)}`
            : '');

        onSubmit({
            facility_id: facilityId,
            user_id: userId,
            stage: stage,
            start_date: startDate,
            end_date: endDate,
            customer_id: customerId,
        });
    };

    const facilityOptions = _.map(facilities, (facility) => ({
        label: facility?.label,
        value: facility?.facility_id,
    }));

    const userOptions = _.chain(users)
        .uniqBy('user_id')
        .compact()
        .map(userToOption)
        .value();

    const customerOptions = _.chain(customers)
        .uniqBy('customer_id')
        .compact()
        .map(customerToOption)
        .value();

    return <>
        <AsideHeader>
            <b>{'Report Options'}</b>
        </AsideHeader>
        <Form onSubmit={(event) => {
            event.preventDefault();
            formSubmit();
        }}>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Facility'}</Form.Text>
                <FormSelect
                    isClearable={true}
                    backspaceRemovesValue={true}
                    options={facilityOptions}
                    value={_.find(facilityOptions, {value: facilityId}) ?? null}
                    onChange={(e) => {
                        setFacilityId(e?.value);
                        setUserId(null);
                    }}
                />
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Technician'}</Form.Text>
                <AsyncFormSelect
                    key={`technician-${facilityId}`}
                    isMulti={true}
                    isClearable={true}
                    backspaceRemovesValue={true}
                    loadOptions={searchUsers}
                    value={_.filter(
                        userOptions,
                        ({value}) => _.includes(userId, value),
                    ) ?? null}
                    onChange={(e) => {
                        const val = _.map(e, 'value');
                        setUserId(_.isEmpty(val) ? null : val);
                    }}
                />
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Stage*'}</Form.Text>
                <FormSelect
                    isMulti={true}
                    isClearable={true}
                    backspaceRemovesValue={true}
                    value={_.filter(
                        STAGE_OPTIONS,
                        ({value}) => _.includes(stage, value),
                    )}
                    options={STAGE_OPTIONS}
                    onChange={(e) => setStage(_.map(e, 'value'))}
                />
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Start Date*'}</Form.Text>
                <Form.Control
                    type={'datetime-local'}
                    value={DateTime
                        .fromISO(startDate)
                        .toLocal()
                        .toISO({
                            includeOffset: false,
                            suppressSeconds: true,
                            suppressMilliseconds: true,
                        }) ?? ''}
                    onChange={(e) => setStartDate(DateTime
                        .fromISO(e.target.value)
                        .toUTC()
                        .toISO())}
                />
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'End Date*'}</Form.Text>
                <Form.Control
                    type={'datetime-local'}
                    value={DateTime
                        .fromISO(endDate)
                        .toLocal()
                        .toISO({
                            includeOffset: false,
                            suppressSeconds: true,
                            suppressMilliseconds: true,
                        }) ?? ''}
                    onChange={(e) => setEndDate(DateTime
                        .fromISO(e.target.value)
                        .toUTC()
                        .toISO())}
                />
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Customer'}</Form.Text>
                <AsyncFormSelect
                    isClearable={true}
                    backspaceRemovesValue={true}
                    loadOptions={searchCustomers}
                    value={_.find(customerOptions, {value: customerId}) ?? null}
                    onChange={(e) => setCustomerId(e?.value)}
                />
            </Form.Group>
            <Button
                className={'w-100 mt-3'}
                type={'button'}
                onClick={() => resetFields()}
            >{'Reset Options'}</Button>
            <Button
                className={'w-100 my-3'}
                variant={'success'}
                type={'submit'}
                disabled={!_.every([
                    _.size(stage),
                    startDate,
                    endDate,
                ])}
            >{'Run Report'}</Button>
        </Form>
    </>;
};

export default TechnicianUnitsCompleted;
