import styled from 'styled-components';
import _ from 'lodash';

export const TableIcon = styled.img.attrs(({
    className,
    $background,
    $disabled,
    onClick,
}) => ({
    className: _.join([
        className,
        'mx-1',
        $background || $disabled ? 'p-2' : '',
        $background ? `bg-${$background}` : '',
        $disabled ? 'bg-info': '',
    ], ' '),
    role: 'button',
    onClick: $disabled ? null : onClick,
}))`
  width: ${({$background, $disabled}) =>
        ($background || $disabled) ? '2rem': '1.5rem'};
  height: ${({$background, $disabled}) =>
        ($background || $disabled) ? '2rem': '1.5rem'};
  border-radius: ${({$background, $disabled}) =>
        ($background || $disabled) && '15%'};
  cursor: ${({$disabled}) => $disabled && 'not-allowed'};
`;

export const TableIconText = styled.span.attrs(() => ({
    className: 'text-start me-1',
}))`
  min-width: ${({$characters = 2}) => `${$characters}ch`};
`;
