import React, {useCallback, useContext, useState} from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import {toast} from 'react-toastify';
import {DateTime} from 'luxon';
import papa from 'papaparse';
import {CSVLink} from 'react-csv';
import readXlsxFile from 'read-excel-file';
import writeXlsxFile from 'write-excel-file';
import {useLocalStorage} from 'usehooks-ts';
import {ApiContext} from '^contexts/api';
import {AppContext} from '^contexts/app';
import uploadIcon from '^assets/images/upload.svg';
import downloadIcon from '^assets/images/download.svg';
import {isResponseError} from '^utilities/isResponseError';
import AsideHeader from '^common/asideHeader';
import {FormSelect} from '^common/formSelect';
import {Button, Col, Container, Form, Row, Table} from 'react-bootstrap';

const templateFileName = 'LabTracker Staged Work Import Template';

const templateHeaders = [
    'Work#',
    'Priority',
    'Project',
    'Ship Date',
    'Group Work#(s)',
];

const mimeTypes = {
    csv: 'text/csv',
    excel: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};

const ImportIcon = styled.img`
    height: 30px;
    width: 30px;
`;

const ImportTable = styled(Table).attrs(() => ({
    size: 'sm',
}))`
    min-width: 75ch;
`;

const fileToData = async (file) => {
    switch (file?.type) {
        case mimeTypes.csv:
            return new Promise((resolve) => {
                papa.parse(file, {
                    complete: ({data}) => resolve(data),
                    error: (e) => {
                        console.warn(e);
                        resolve(null);
                    },
                });
            });
        case mimeTypes.excel:
            return new Promise((resolve) => {
                file.arrayBuffer()
                    .then((buffer) => readXlsxFile(buffer).then(resolve))
                    .catch((e) => {
                        console.warn(e);
                        resolve(null);
                    });
            });
        default:
            return null;
    }
};

const parseString = (val, optional) => {
    const asString = _.toString(val);
    const emptyString = asString === '';

    return {
        render_value: asString,
        submit_value: emptyString ? null : asString,
        error: emptyString && !optional,
    };
};

const parseBool = (val, optional) => {
    switch (_.toUpper(val)) {
        case 'TRUE':
        case 'T':
        case 'YES':
        case 'Y':
        case '1':
            return {
                submit_value: true,
                render_value: 'Yes',
                error: false,
            };
        case 'FALSE':
        case 'F':
        case 'NO':
        case 'N':
        case '0':
            return {
                submit_value: false,
                render_value: 'No',
                error: false,
            };
        case '':
            return {
                submit_value: null,
                render_value: '',
                error: !optional,
            };
        default:
            return {
                submit_value: null,
                render_value: val,
                error: !optional,
            };
    }
};

const parseDate = (val, optional) => {
    const formatDateAs = (format, ...opts) => {
        const asFormat = format(val, ...opts).toUTC();

        if (!asFormat.isValid) {
            return false;
        }

        return {
            submit_value: asFormat.toISODate(),
            render_value: asFormat.toLocaleString(),
            error: false,
        };
    };

    switch (true) {
        case _.isNil(val):
        case val === '':
            return {
                submit_value: null,
                render_value: '',
                error: !optional,
            };
        case !!formatDateAs(DateTime.fromJSDate):
            return formatDateAs(DateTime.fromJSDate);
        case !!formatDateAs(DateTime.fromISO):
            return formatDateAs(DateTime.fromISO);
        case !!formatDateAs(DateTime.fromHTTP):
            return formatDateAs(DateTime.fromHTTP);
        case !!formatDateAs(DateTime.fromSQL):
            return formatDateAs(DateTime.fromSQL);
        case !!formatDateAs(DateTime.fromRFC2822):
            return formatDateAs(DateTime.fromRFC2822);
        case !!formatDateAs(DateTime.fromFormat, 'M/d/yy'):
            return formatDateAs(DateTime.fromFormat, 'M/d/yy');
        case !!formatDateAs(DateTime.fromFormat, 'M/d/yyyy'):
            return formatDateAs(DateTime.fromFormat, 'M/d/yyyy');
        default:
            return {
                submit_value: null,
                render_value: val,
                error: true,
            };
    }
};

const parseArray = (val, optional) => {
    const asArray = _.chain(val)
        .split('|')
        .map(_.trim)
        .compact()
        .value();

    return {
        render_value: _.join(asArray, ' | '),
        submit_value: asArray,
        error: _.size(asArray) < 1 && !optional,
    };
};

const StagedWorkTicketImport = ({
    onImport,
}) => {
    const api = useContext(ApiContext);
    const {setAsideChildren} = useContext(AppContext);
    const [userFacility] = useLocalStorage('facility_id', '');
    const [userFacilityLabel] = useLocalStorage('facility_label', '');
    const hiddenFileInput = React.useRef(null);

    const [submitData, setSubmitData] = useState([]);
    const [processing, setProcessing] = useState(false);

    const hasError = _.some(
        submitData,
        (col) => _.some(
            col,
            {error: true},
        ),
    );

    const handleImport = useCallback(async (file) => {
        if (hiddenFileInput.current) {
            hiddenFileInput.current.value = null;
        }

        const inputData = await fileToData(file);

        if (!_.isArray(inputData)) {
            toast.error('Unable to parse file');
            return;
        }

        if (!_.isEqual(inputData?.[0], templateHeaders)) {
            toast.error('Headers do not match template');
            return;
        }

        const inputDataNoHeaders = _.filter(
            _.drop(inputData),
            (row) => _.size(row) > 1,
        );

        if (_.isEmpty(inputDataNoHeaders)) {
            toast.error('No data in file');
            return;
        }

        const submitData = _.map(
            inputDataNoHeaders,
            (row) => _.map(
                row,
                (col, colIdx) => {
                    switch (colIdx) {
                        case 0:
                            return parseString(col, false);
                        case 1:
                        case 2:
                            return parseBool(col, false);
                        case 3:
                            return parseDate(col, true);
                        case 4:
                            return parseArray(col, true);
                        default:
                            return parseString(col, true);
                    }
                },
            ),
        );

        setSubmitData(submitData);
    }, []);

    const handleSubmit = useCallback(async () => {
        setProcessing(true);

        const response = await api.post(
            `/facilities/${userFacility}/staged-work-tickets`,
            _.map(submitData, (data) => ({
                work_ticket_number: data[0].submit_value,
                is_priority: data[1].submit_value,
                is_project: data[2].submit_value,
                ship_date: data[3].submit_value,
                group_work_ticket_numbers: data[4].submit_value,
            })),
        );

        setProcessing(false);

        if (isResponseError(response)) {
            toast.error(response?.data?.error);
            return;
        }

        onImport(response?.data);
        setAsideChildren(null);
    }, [api, onImport, setAsideChildren, submitData, userFacility]);

    return <>
        <AsideHeader>
            <b>{'Import Staged Work'}</b>
        </AsideHeader>
        <Form.Group className={'mb-3'}>
            <Form.Text>{'Facility'}</Form.Text>
            <FormSelect
                isDisabled={true}
                value={{
                    value: userFacility,
                    label: userFacilityLabel,
                }}
            />
        </Form.Group>
        <Container className={'text-primary text-center'}>
            <Row className={'my-3'}>
                <Col>
                    <div
                        role={'button'}
                        onClick={() => hiddenFileInput.current?.click?.()}
                    >
                        <ImportIcon src={uploadIcon}/>
                        <div>{'Import'}</div>
                    </div>
                </Col>
                <Col>
                    <CSVLink
                        className={'text-decoration-none'}
                        data={[]}
                        headers={templateHeaders}
                        filename={`${templateFileName}.csv`}
                    >
                        <ImportIcon src={downloadIcon}/>
                        <div>{'CSV Template'}</div>
                    </CSVLink>
                </Col>
                <Col className={'px-2'}>
                    <div
                        role={'button'}
                        onClick={() => writeXlsxFile(
                            [_.map(
                                templateHeaders,
                                (header) => ({
                                    type: String,
                                    value: header,
                                }),
                            )]
                            , {fileName: `${templateFileName}.xlsx`},
                        )}
                    >
                        <ImportIcon src={downloadIcon}/>
                        <div>{'XLSX Template'}</div>
                    </div>
                </Col>
            </Row>
        </Container>
        <input
            hidden={true}
            type={'file'}
            accept={_.join(_.values(mimeTypes), ',')}
            onChange={(e) => handleImport(e.target.files[0])}
            ref={hiddenFileInput}
        />
        <ImportTable>
            <thead>
                <tr>{_.map(
                    templateHeaders,
                    (header) => <th key={header}>{header}</th>,
                )}</tr>
            </thead>
            <tbody>{_.map(
                submitData,
                (row, rowIdx) => <tr key={`row-${rowIdx}`}>{_.map(
                    row,
                    (col, colIdx) => <td
                        className={col.error ? 'bg-danger text-white' : ''}
                        key={`col-${colIdx}`}
                    >
                        {col.render_value}
                    </td>)}
                </tr>,
            )}</tbody>
        </ImportTable>
        {hasError && <Form.Text className={'mb-3'}>
            {'Parsing errors indicated in highlighted cells.'}
            {' Correct errors and reattempt import.'}
        </Form.Text>}
        <Button
            className={'w-100 my-3'}
            variant={'success'}
            disabled={hasError || processing || _.size(submitData) === 0}
            type={'submit'}
            onClick={handleSubmit}
        >
            {'Submit'}
        </Button>
        <Form.Text className={'mb-3'}>
            {'Note: Include all Group Work#(s) for every row they apply to.'}
            {' Multiple values may be delimited with "|" (pipe) character.'}
        </Form.Text>
    </>;
};

export default StagedWorkTicketImport;
