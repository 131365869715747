import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import {apiHost} from '^utilities/dataSet';

const BarTop = styled.div.attrs(() => ({
    className: _.join([
        'd-print-none',
        'position-fixed',
        'top-0',
        'w-100',
    ], ' '),
}))`
  height: 0.5rem;
  background-color: ${({color}) => color};
  z-index: 1100; // Bootstrap Navbar z-index is 1030
`;

const EnvIndicator = () => {
    const apiHostWords = _.words(apiHost);

    switch (true) {
        case _.includes(apiHostWords, 'dev'):
            return <BarTop color={'var(--bs-danger)'}/>;
        case _.includes(apiHostWords, 'staging'):
            return <BarTop color={'orange'}/>;
        case _.includes(apiHostWords, 'test'):
            return <BarTop color={'var(--bs-warning)'}/>;
        default:
            return null;
    }
};

export default EnvIndicator;
