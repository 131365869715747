import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useLocalStorage} from 'usehooks-ts';
import _ from 'lodash';
import {isResponseError} from '^utilities/isResponseError';
import {ApiContext} from '^contexts/api';
import {AppContext} from '^contexts/app';
import AsideHeader from '^common/asideHeader';
import {AsyncFormSelect, FormSelect} from '^common/formSelect';
import {Button, ButtonGroup, Form} from 'react-bootstrap';
import {toast} from 'react-toastify';

const customerToOption = (customer) => ({
    label: customer?.label,
    value: customer?.customer_id,
});

const storageLocationToOption = (storageLocation) => ({
    label: storageLocation?.label,
    value: storageLocation?.storage_location_id,
});

const WorkTicketCreateEdit = ({
    workTicket,
    updateWorkTicket,
}) => {
    const api = useContext(ApiContext);
    const workTicketId = workTicket?.work_ticket_id;
    const {setAsideChildren} = useContext(AppContext);

    const [createMethod, setCreateMethod] = useState('import');
    const [processing, setProcessing] = useState(false);
    const [importMessage, setImportMessage] = useState('');
    const [facility, setFacility] = useState(null);
    const [customers, setCustomers] = useState([]);
    const [customer, setCustomer] = useState('');
    const [storageLocations, setStorageLocations] = useState([]);
    const [inboundStorageLocation, setInboundStorageLocation] = useState('');
    const [outboundStorageLocation, setOutboundStorageLocation] = useState('');
    const [priority, setPriority] = useState(false);
    const [project, setProject] = useState(false);
    const [pool, setPool] = useState('');
    const [shipDate, setShipDate] = useState('');
    const [workTicketNumber, setWorkTicketNumber] = useState('');
    const [shipmentNumber, setShipmentNumber] = useState('');
    const [orderNumber, setOrderNumber] = useState('');
    const [status, setStatus] = useState('Activated');
    const [statuses, setStatuses] = useState([]);
    const [userFacility] = useLocalStorage('facility_id', '');
    const [userFacilityLabel] = useLocalStorage('facility_label', '');

    useEffect(() => {
        if (!_.isNil(workTicket)) {
            setCustomer(workTicket?.customer?.customer_id);
            setInboundStorageLocation(
                workTicket?.inbound_storage_location?.storage_location_id,
            );
            setOutboundStorageLocation(
                workTicket?.outbound_storage_location?.storage_location_id,
            );
            setPriority(workTicket?.is_priority);
            setProject(workTicket?.is_project);
            setPool(workTicket?.pool);
            setShipDate(workTicket?.ship_date ?? '');
            setWorkTicketNumber(workTicket?.work_ticket_number);
            setShipmentNumber(workTicket?.shipment_number);
            setOrderNumber(workTicket?.order_number);
            setStatus(workTicket?.status);
        }
    }, [workTicket]);

    useEffect(() => {
        const getFacility = async () => {
            const response = await api.get(`/facilities/${userFacility}`);

            if (isResponseError(response)) {
                return;
            }

            setFacility(response?.data);
        };

        if (api) {
            getFacility();
        }
    }, [api, userFacility]);

    const searchCustomers = useCallback(async (label) => {
        if (!api) {
            return [];
        }

        const response = await api.get('/customers', {
            params: label ? {label} : {},
        });

        if (isResponseError(response)) {
            return [];
        }

        const customers = response?.data?.results ?? [];

        setCustomers((prevState) => _.uniqBy(
            [...prevState, ...customers],
            'customer_id',
        ));

        return _.map(customers, customerToOption);
    }, [api]);

    const searchStorageLocations = useCallback(async (label) => {
        if (!api) {
            return [];
        }

        if (!userFacility) {
            return [];
        }

        const response = await api.get('/storage-locations', {
            params: {
                active: 1,
                facility_id: userFacility,
                ...label ? {label} : {},
            },
        });

        if (isResponseError(response)) {
            return [];
        }

        const storageLocations = response?.data?.results ?? [];

        setStorageLocations((prevState) => _.uniqBy(
            [...prevState, ...storageLocations],
            'storage_location_id',
        ));

        return _.map(storageLocations, storageLocationToOption);
    }, [api, userFacility]);

    useEffect(() => {
        const fetchStatuses = async () => {
            const response = await api.get('/types/status');

            if (isResponseError(response)) {
                return;
            }

            setStatuses(response?.data);
        };

        if (api) {
            fetchStatuses();
        }
    }, [api]);

    const createEditWorkTicket = useCallback(async () => {
        setProcessing(true);
        setImportMessage('');

        const body = {
            status,
            work_ticket_number: workTicketNumber,
            shipment_number: shipmentNumber,
            order_number: orderNumber,
            ...!workTicketId && {
                work_ticket_group: {
                    facility: {
                        facility_id: userFacility,
                    },
                },
            },
            customer: {
                customer_id: customer,
            },
            inbound_storage_location: {
                storage_location_id: inboundStorageLocation,
            },
            outbound_storage_location: {
                storage_location_id: outboundStorageLocation,
            },
            is_priority: priority,
            is_project: project,
            pool: pool,
            ship_date: shipDate,
        };

        const response = workTicketId
            ? await api.patch(`/work-tickets/${workTicketId}`, body)
            : await api.post('/work-tickets', body);

        setProcessing(false);

        if (isResponseError(response)) {
            toast.error(response?.data?.error);
            return;
        }

        updateWorkTicket(response?.data);
        setAsideChildren(null);
    }, [
        api,
        customer,
        inboundStorageLocation,
        orderNumber,
        outboundStorageLocation,
        pool,
        priority,
        project,
        setAsideChildren,
        shipDate,
        shipmentNumber,
        status,
        updateWorkTicket,
        userFacility,
        workTicketId,
        workTicketNumber,
    ]);

    const pollImportStatus = useCallback(async (importId) => {
        const response = await api.get(`/work-tickets/import/${importId}`);

        if (isResponseError(response)) {
            toast.error(response?.data?.error);
            setProcessing(false);
            setImportMessage('');
            return;
        }

        if (response.data?.work_ticket) {
            updateWorkTicket(response.data.work_ticket);
            setAsideChildren(null);
            return;
        }

        if (response.data?.status === 'ERROR') {
            toast.error(response.data?.message);
            setProcessing(false);
            setImportMessage('');
            return;
        }

        setImportMessage(response.data?.message);
        await new Promise((x) => setTimeout(x, 500));
        await pollImportStatus(importId);
    }, [api, setAsideChildren, updateWorkTicket]);

    const importWorkTicket = useCallback(async () => {
        setProcessing(true);
        setImportMessage('');

        const body = {
            status,
            work_ticket_number: workTicketNumber,
            work_ticket_group: {
                facility: {
                    facility_id: userFacility,
                },
            },
            inbound_storage_location: {
                storage_location_id: inboundStorageLocation,
            },
            outbound_storage_location: {
                storage_location_id: outboundStorageLocation,
            },
            is_priority: priority,
            is_project: project,
            ship_date: shipDate,
        };

        const response = await api.post('/work-tickets/import', body);

        if (isResponseError(response)) {
            toast.error(response?.data?.error);
            setProcessing(false);
            return;
        }

        await pollImportStatus(response?.data?.work_ticket_import_id);
    }, [
        api,
        inboundStorageLocation,
        outboundStorageLocation,
        pollImportStatus,
        priority,
        project,
        shipDate,
        status,
        userFacility,
        workTicketNumber,
    ]);

    const statusOptions = _.map(statuses, (status) => ({
        label: status,
        value: status,
    }));

    const customerOptions = _.chain(customers)
        .concat(workTicket?.customer)
        .uniqBy('customer_id')
        .compact()
        .map(customerToOption)
        .value();

    const storageLocationOptions = _.chain(storageLocations)
        .concat(workTicket?.inbound_storage_location)
        .concat(workTicket?.outbound_storage_location)
        .uniqBy('storage_location_id')
        .compact()
        .map(storageLocationToOption)
        .value();

    return <>
        <AsideHeader>
            {workTicketId
                ? `Edit Work: ${workTicket.work_ticket_number}`
                : 'Create New Work'}
        </AsideHeader>
        {!workTicketId && <ButtonGroup size={'sm'} className={'w-100 my-2'}>
            <Button
                disabled={processing}
                onClick={() => setCreateMethod('import')}
                variant={createMethod === 'import'
                    ? 'primary'
                    : 'outline-primary'}
            >
                {'D365 Import'}
            </Button>
            <Button
                disabled={processing}
                onClick={() => setCreateMethod('manual')}
                variant={createMethod === 'manual'
                    ? 'primary'
                    : 'outline-primary'}
            >
                {'Manual'}
            </Button>
        </ButtonGroup>}
        <Form
            onSubmit={(event) => {
                event.preventDefault();

                if (workTicketId || createMethod === 'manual') {
                    createEditWorkTicket();
                    return;
                }

                importWorkTicket();
            }}
        >
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Work Number'}</Form.Text>
                <Form.Control
                    disabled={workTicket?.config_count > 0 || processing}
                    type={'text'}
                    required={true}
                    value={workTicketNumber}
                    onChange={(e) => setWorkTicketNumber(e.target.value)}
                />
            </Form.Group>
            {(workTicketId || createMethod === 'manual') && <Form.Group
                className={'mb-3'}
            >
                <Form.Text>{'Shipment Number'}</Form.Text>
                <Form.Control
                    disabled={workTicket?.config_count > 0 || processing}
                    type={'text'}
                    required={true}
                    value={shipmentNumber}
                    onChange={(e) => setShipmentNumber(e.target.value)}
                />
            </Form.Group>}
            {(workTicketId || createMethod === 'manual') && <Form.Group
                className={'mb-3'}
            >
                <Form.Text>{'Order Number'}</Form.Text>
                <Form.Control
                    disabled={workTicket?.config_count > 0 || processing}
                    type={'text'}
                    required={true}
                    value={orderNumber}
                    onChange={(e) => setOrderNumber(e.target.value)}
                />
            </Form.Group>}
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Status'}</Form.Text>
                <FormSelect
                    isDisabled={processing}
                    value={_.find(statusOptions, {value: status})}
                    options={statusOptions}
                    onChange={(e) => setStatus(e.value)}
                />
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Facility'}</Form.Text>
                <FormSelect
                    isDisabled={true}
                    value={{
                        label: userFacilityLabel,
                        value: userFacility,
                    }}
                />
            </Form.Group>
            {(workTicketId || createMethod === 'manual') && <Form.Group
                className={'mb-3'}
            >
                <Form.Text>{'Customer'}</Form.Text>
                <AsyncFormSelect
                    isDisabled={workTicket?.config_count > 0 || processing}
                    loadOptions={searchCustomers}
                    value={_.find(customerOptions, {value: customer})}
                    onChange={(e) => setCustomer(e.value)}
                />
            </Form.Group>}
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Inbound Storage Location'}</Form.Text>
                <AsyncFormSelect
                    isDisabled={processing}
                    isClearable={true}
                    backspaceRemovesValue={true}
                    loadOptions={searchStorageLocations}
                    value={_.find(storageLocationOptions, {
                        value: inboundStorageLocation,
                    })}
                    onChange={(e) => setInboundStorageLocation(e?.value)}
                />
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Outbound Storage Location'}</Form.Text>
                <AsyncFormSelect
                    isDisabled={processing}
                    isClearable={true}
                    backspaceRemovesValue={true}
                    loadOptions={searchStorageLocations}
                    value={_.find(storageLocationOptions, {
                        value: outboundStorageLocation,
                    })}
                    onChange={(e) => setOutboundStorageLocation(e?.value)}
                />
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Check
                    disabled={processing}
                    type={'checkbox'}
                    label={'Priority'}
                    checked={priority}
                    onChange={(e) => setPriority(e.target.checked)}
                />
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Check
                    disabled={processing}
                    type={'checkbox'}
                    label={'Project'}
                    checked={project}
                    onChange={(e) => setProject(e.target.checked)}
                />
            </Form.Group>
            {(workTicketId || createMethod === 'manual') && <Form.Group
                className={'mb-3'}
            >
                <Form.Text>{'Pool'}</Form.Text>
                <Form.Control
                    disabled={workTicket?.config_count > 0 || processing}
                    type={'text'}
                    value={pool}
                    onChange={(e) => setPool(e.target.value)}
                />
            </Form.Group>}
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Ship Date'}</Form.Text>
                <Form.Control
                    type={'date'}
                    value={shipDate}
                    onChange={(e) => setShipDate(e?.target?.value)}
                />
            </Form.Group>
            <Button
                className={'w-100 mb-3'}
                variant={'success'}
                type={'submit'}
                disabled={(() => {
                    if (processing) {
                        return true;
                    }

                    if (workTicketId || createMethod === 'manual') {
                        return !_.every([
                            customer,
                            shipmentNumber,
                            orderNumber,
                            workTicketNumber,
                            status,
                            userFacility,
                        ]);
                    }

                    return !_.every([
                        workTicketNumber,
                        status,
                        userFacility,
                    ]);
                })()}
            >
                {'Submit'}
            </Button>
            {facility && createMethod === 'import'
                && !workTicketId && <Form.Text
                className={'mb-3 d-block'}
            >
                {`D365 Credentials: ${facility?.d365_credentials}`}
            </Form.Text>}
            <Form.Text className={'mb-3 d-block'}>
                {importMessage}
            </Form.Text>
        </Form>
    </>;
};

export default WorkTicketCreateEdit;
