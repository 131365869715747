import React, {useEffect} from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import {BrowserRouter} from 'react-router-dom';
import LogRocket from 'logrocket';
import {logRocketId} from '^utilities/dataSet';
import {ApiContextProvider} from '^contexts/api';
import {AppContextProvider} from '^contexts/app';
import Fonts from '^themes/fonts';
import '^themes/bootstrap.scss';
import '^themes/monaco.scss';
import '^themes/toastify.scss';
import BottomNav from '^sections/bottomNav';
import EnvIndicator from '^sections/envIndicator';
import Main from '^sections/main';
import TopNav from '^sections/topNav';
import Error from '^pages/error';
import {ToastContainer} from 'react-toastify';

const App = () => {
    useEffect(() => {
        if (logRocketId) {
            LogRocket.init(logRocketId);
        }
    }, []);

    return <>
        <Fonts/>
        <EnvIndicator/>
        <ErrorBoundary fallback={<Error/>}>
            <AppContextProvider>
                <ApiContextProvider>
                    <ToastContainer
                        position={'bottom-left'}
                        autoClose={5000}
                        pauseOnHover={true}
                        closeOnClick={false}
                        newestOnTop={true}
                        theme={'colored'}
                        limit={3}
                    />
                    <BrowserRouter>
                        <TopNav/>
                        <Main/>
                        <BottomNav/>
                    </BrowserRouter>
                </ApiContextProvider>
            </AppContextProvider>
        </ErrorBoundary>
    </>;
};

export default App;
