import React, {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import {ApiContext} from '^contexts/api';
import {stringToReadableDateTime} from '^utilities/dateTime';
import {isResponseError} from '^utilities/isResponseError';
import addIcon from '^assets/images/add.svg';
import threadIcon from '^assets/images/thread.svg';
import AsideHeader from '^common/asideHeader';
import CustomCard from '^common/customCard';
import {Button, Col, Container, Form, Row} from 'react-bootstrap';
import AsideBackIcon from '^common/asideBackIcon';
import {toast} from 'react-toastify';

const AddIcon = styled.img.attrs(() => ({
    className: 'mx-3',
    role: 'button',
    src: addIcon,
}))`
  height: 1.5rem;
  width: 1.5rem;
`;

const ThreadIcon = styled.img.attrs(() => ({
    src: threadIcon,
}))`
  width: 2rem;
`;

const NoteForm = ({
    workTicketId,
    editNote,
    replyToNote,
    onSubmit,
}) => {
    const api = useContext(ApiContext);
    const formRef = useRef(null);
    const textareaRef = useRef(null);
    const [newNoteText, setNewNoteText] = useState(editNote?.text ?? '');
    const [submitDisabled, setSubmitDisabled] = useState(false);

    useEffect(() => {
        if (formRef.current) {
            formRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
            });
        }

        if (textareaRef.current) {
            textareaRef.current.focus();
        }
    }, []);

    const createEditNote = async (text) => {
        setSubmitDisabled(true);
        const noteId = editNote?.note_id;

        const body = {
            text,
            work_ticket: {
                work_ticket_id: workTicketId,
            },
            thread_id: replyToNote?.thread_id
                ?? replyToNote?.note_id
                ?? editNote?.thread_id
                ?? null,
        };

        const response = noteId
            ? await api.patch(`/notes/${noteId}`, body)
            : await api.post('/notes', body);


        if (isResponseError(response)) {
            toast.error(response?.data?.error);
            return;
        }

        onSubmit(response.data);
    };

    return <Form
        ref={formRef}
        onSubmit={(event) => {
            event.preventDefault();
            createEditNote(newNoteText);
        }}
    >
        <Form.Control
            ref={textareaRef}
            className={'mb-3'}
            type={'text'}
            as={'textarea'}
            rows={3}
            required={true}
            value={newNoteText}
            onChange={(e) => setNewNoteText(e.target.value)}
        />
        <Button
            className={'w-100 mb-3'}
            variant={'success'}
            type={'submit'}
            disabled={submitDisabled}
        >
            {'Submit'}
        </Button>
    </Form>;
};

const WorkTicketNotesAside = ({
    workTicket,
    updateWorkTicket,
    onReturn,
}) => {
    const api = useContext(ApiContext);
    const workTicketId = workTicket?.work_ticket_id;

    const [notes, setNotes] = useState([]);
    const [createNewNote, setCreateNewNote] = useState(false);
    const [editNote, setEditNote] = useState(null);
    const [replyToNote, setReplyToNote] = useState(null);

    useEffect(() => {
        const fetchNotes = async () => {
            const response = await api.get('/notes', {
                params: {work_ticket_id: workTicketId},
            });

            if (isResponseError(response)) {
                return;
            }

            setNotes(response?.data?.results);
        };

        if (api && workTicketId) {
            fetchNotes();
        }
    }, [api, workTicketId]);

    useEffect(() => {
        updateWorkTicket({
            ...workTicket,
            note_count: _.size(notes),
        });
    }, [notes, workTicket, updateWorkTicket]);

    const noteSubmitCallback = useCallback((note) => {
        setNotes((prevState) => _.chain(prevState)
            .concat(note)
            .reverse()
            .uniqBy('note_id')
            .sortBy((note) => {
                const parentNote = _.find(notes, {note_id: note.thread_id});

                return parentNote
                    ? `${parentNote.created}-${note.created}`
                    : `${note.created}`;
            })
            .value());

        setCreateNewNote(false);
        setReplyToNote(null);
        setEditNote(null);
    }, [notes]);

    return <>
        <AsideHeader className={'d-flex justify-content-between'}>
            <div className={'d-flex align-items-center'}>
                <div>{onReturn && <AsideBackIcon
                    alt={'Back'}
                    onClick={onReturn}
                />}</div>
                <div>
                    <div>{'Notes'}</div>
                    <h6>{workTicket.work_ticket_number}</h6>
                </div>
            </div>
            <AddIcon
                alt={'New Note'}
                onClick={() => {
                    setCreateNewNote(true);
                    setReplyToNote(null);
                    setEditNote(null);
                }}
            />
        </AsideHeader>
        {_.map(notes, (note) => {
            const parent = _.find(notes, {note_id: note.thread_id});
            const hasReplies = _.some(notes, {thread_id: note.note_id});

            const firstReply = _.isEqual(
                note,
                _.find(notes, {thread_id: note.thread_id}),
            );

            const lastReply = _.isEqual(
                note,
                _.findLast(notes, {thread_id: note.thread_id}),
            );

            const canReply = (!parent && !hasReplies) || (parent && lastReply);
            const indent = note?.thread_id;

            return <Container key={note?.note_id}>
                <Row>
                    {indent && <Col className={'text-end'} xs={2}>
                        {firstReply && <ThreadIcon/>}
                    </Col>}
                    <Col xs={indent ? 10 : 12}>
                        <div className={'text-primary'}>
                            <span className={'me-2 fw-semibold'}>
                                {note?.updated_by?.name}
                            </span>
                            {note?.created === note?.updated && <span>
                                {stringToReadableDateTime(note?.created)}
                            </span>}
                            {note?.created !== note?.updated && <span>
                                {'Edited: '}
                                {stringToReadableDateTime(note?.updated)}
                            </span>}
                            <CustomCard className={'p-1'}>
                                {editNote?.note_id === note.note_id
                                    ? <NoteForm
                                        workTicketId={workTicketId}
                                        editNote={editNote}
                                        onSubmit={noteSubmitCallback}
                                    />
                                    : note?.text}
                            </CustomCard>
                        </div>
                        <div className={'text-end pb-3'}>
                            {canReply && <div
                                role={'button'}
                                className={_.join([
                                    'd-inline',
                                    'me-2',
                                    'text-primary',
                                    'text-decoration-underline',
                                ], ' ')}
                                onClick={() => {
                                    setCreateNewNote(false);
                                    setReplyToNote(note);
                                    setEditNote(null);
                                }}>
                                {'Reply'}
                            </div>}
                            <div
                                role={'button'}
                                className={_.join([
                                    'd-inline',
                                    'text-primary',
                                    'text-decoration-underline',
                                ], ' ')}
                                onClick={() => {
                                    setCreateNewNote(false);
                                    setReplyToNote(null);
                                    setEditNote(note);
                                }}
                            >
                                {'Edit Note'}
                            </div>
                        </div>

                    </Col>
                </Row>
                {replyToNote?.note_id === note.note_id && <Row>
                    <Col className={'text-end'} xs={2}>
                        {!parent && <ThreadIcon/>}
                    </Col>
                    <Col xs={10}>
                        <NoteForm
                            workTicketId={workTicketId}
                            replyToNote={replyToNote}
                            onSubmit={noteSubmitCallback}
                        />
                    </Col>
                </Row>}
            </Container>;
        })}
        {createNewNote && <NoteForm
            workTicketId={workTicketId}
            onSubmit={noteSubmitCallback}
        />}
    </>;
};

export default WorkTicketNotesAside;
