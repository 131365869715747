import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import {Container, Row} from 'react-bootstrap';
import warningIcon from '^assets/images/warning.svg';

const WarningIcon = styled.img.attrs(() => ({
    src: warningIcon,
}))`
    height: 10rem;
    width: 10rem;
`;

const Error = ({
    message = 'Unexpected Error Occurred',
}) => {
    return <Container fluid={true} className={'my-3'}>
        <Row className={'text-center justify-content-center'}>
            <WarningIcon/>
            <h1 className={'text-danger fw-bold'}>
                {message}
            </h1>
            <div
                className={_.join([
                    'text-primary',
                    'fw-semibold',
                    'text-decoration-underline',
                ], ' ')}
                role={'button'}
                onClick={() => {
                    window.location = '/';
                }}
            >
                {'Navigate Home'}
            </div>
        </Row>
    </Container>;
};

export default Error;
