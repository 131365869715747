import React, {useCallback} from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import {Duration} from 'luxon';
import {Col, Form, Row} from 'react-bootstrap';

const UnitLabel = styled.div.attrs(() => ({
    className: 'text-center',
}))`
    font-size: 0.8rem;
`;

const DurationInput = ({
    value,
    onChange,
    disabled,
}) => {
    const valueDuration = Duration
        .fromObject(value ?? {})
        .rescale()
        .shiftTo('days', 'hours', 'minutes');

    const zeroDuration = Duration
        .fromMillis(0)
        .shiftTo('days', 'hours', 'minutes');

    const duration = valueDuration < 0
        ? zeroDuration
        : valueDuration;

    const change = useCallback((e, units) => {
        const newDuration = duration.set({
            [units]: _.toFinite(e.target?.value),
        });

        onChange(newDuration.rescale().toObject());
    }, [duration, onChange]);

    return <Row>
        <Col>
            <Form.Control
                disabled={disabled}
                type={'number'}
                value={duration.days ?? null}
                onChange={(e) => change(e, 'days')}
            />
            <UnitLabel>{'Days'}</UnitLabel>
        </Col>
        <Col>
            <Form.Control
                disabled={disabled}
                type={'number'}
                value={duration.hours ?? null}
                onChange={(e) => change(e, 'hours')}
            />
            <UnitLabel>{'Hours'}</UnitLabel>
        </Col>
        <Col>
            <Form.Control
                disabled={disabled}
                type={'number'}
                value={duration.minutes ?? null}
                onChange={(e) => change(e, 'minutes')}
            />
            <UnitLabel>{'Minutes'}</UnitLabel>
        </Col>
    </Row>;
};

export default DurationInput;
