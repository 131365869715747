import React, {useCallback, useContext, useEffect, useState} from 'react';
import {isResponseError} from '^utilities/isResponseError';
import {ApiContext} from '^contexts/api';
import {AppContext} from '^contexts/app';
import AsideHeader from '^common/asideHeader';
import {Button, Form} from 'react-bootstrap';
import {toast} from 'react-toastify';

const PartEdit = ({
    part,
    updatePart,
}) => {
    const api = useContext(ApiContext);
    const {setAsideChildren} = useContext(AppContext);
    const [processing, setProcessing] = useState(false);
    const [isLabService, setIsLabService] = useState(false);

    const partId = part?.part_id;

    useEffect(() => {
        setIsLabService(part?.is_lab_service ?? false);
    }, [
        part?.is_lab_service,
    ]);

    const editPart = useCallback(async () => {
        setProcessing(true);

        const response = await api.patch(`/parts/${partId}`, {
            is_lab_service: isLabService,
        });

        setProcessing(false);

        if (isResponseError(response)) {
            toast.error(response?.data?.error);
            return;
        }

        updatePart(response?.data);
        setAsideChildren(null);
    }, [
        api,
        isLabService,
        partId,
        setAsideChildren,
        updatePart,
    ]);

    return <>
        <AsideHeader>
            {`Edit Part "${part?.part_number ?? ''}"`}
        </AsideHeader>
        <Form onSubmit={(event) => {
            event.preventDefault();
            editPart();
        }}>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Part Number'}</Form.Text>
                <Form.Control
                    disabled={true}
                    type={'text'}
                    value={part?.part_number ?? ''}
                />
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Manufacturer Part Number'}</Form.Text>
                <Form.Control
                    disabled={true}
                    type={'text'}
                    value={part?.manufacturer_part_number ?? ''}
                />
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Type'}</Form.Text>
                <Form.Control
                    disabled={true}
                    type={'text'}
                    value={part?.type ?? ''}
                />
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Description'}</Form.Text>
                <Form.Control
                    disabled={true}
                    type={'text'}
                    value={part?.description ?? ''}
                />
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Check
                    disabled={true}
                    type={'checkbox'}
                    label={'Service'}
                    checked={part?.is_service ?? false}
                />
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Check
                    disabled={processing || !part?.is_service}
                    type={'checkbox'}
                    label={'Lab Service'}
                    checked={isLabService}
                    onChange={(e) => setIsLabService(e.target.checked)}
                />
            </Form.Group>
            <Button
                className={'w-100 my-3'}
                variant={'success'}
                disabled={processing}
                type={'submit'}
            >
                {'Submit'}
            </Button>
        </Form>
    </>;
};

export default PartEdit;
