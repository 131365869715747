import {createGlobalStyle} from 'styled-components';

import MyriadProRegular from '^assets/fonts/MyriadPro-Regular.otf';
import MyriadProSemibold from '^assets/fonts/MyriadPro-Semibold.otf';
import MyriadProBold from '^assets/fonts/MyriadPro-Bold.otf';

import MyriadProItalic from '^assets/fonts/MyriadPro-Italic.otf';
import MyriadProSemiboldItalic from '^assets/fonts/MyriadPro-SemiboldItalic.otf';
import MyriadProBoldItalic from '^assets/fonts/MyriadPro-BoldItalic.otf';

const Fonts = createGlobalStyle`
  @font-face {
    font-family: 'myriad-pro';
    src: url(${MyriadProRegular}) format('opentype');
    font-display: auto;
    font-style: normal;
    font-weight: 400;
    font-stretch: normal;
  }

  @font-face {
    font-family: 'myriad-pro';
    src: url(${MyriadProSemibold}) format('opentype');
    font-display: auto;
    font-style: normal;
    font-weight: 600;
    font-stretch: normal;
  }

  @font-face {
    font-family: 'myriad-pro';
    src: url(${MyriadProBold}) format('opentype');
    font-display: auto;
    font-style: normal;
    font-weight: 700;
    font-stretch: normal;
  }

  @font-face {
    font-family: 'myriad-pro';
    src: url(${MyriadProItalic}) format('opentype');
    font-display: auto;
    font-style: italic;
    font-weight: 400;
    font-stretch: normal;
  }

  @font-face {
    font-family: 'myriad-pro';
    src: url(${MyriadProSemiboldItalic}) format('opentype');
    font-display: auto;
    font-style: italic;
    font-weight: 600;
    font-stretch: normal;
  }

  @font-face {
    font-family: 'myriad-pro';
    src: url(${MyriadProBoldItalic}) format('opentype');
    font-display: auto;
    font-style: italic;
    font-weight: 700;
    font-stretch: normal;
  }
`;

export default Fonts;
