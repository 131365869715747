import React, {useContext, useEffect, useRef, useState} from 'react';
import LogRocket from 'logrocket';
import {useLocalStorage} from 'usehooks-ts';
import styled from 'styled-components';
import {toast} from 'react-toastify';
import {isResponseError} from '^utilities/isResponseError';
import {ApiContext} from '^contexts/api';
import {AppContext} from '^contexts/app';
import {Container, Navbar} from 'react-bootstrap';
import TopNavBrand from '^sections/topNavBrand';
import TopNavSearch from '^sections/topNavSearch';
import TopNavUser from '^sections/topNavUser';

const TopNavbar = styled(Navbar).attrs(() => ({
    className: 'bg-white d-print-none',
    fixed: 'top',
}))`
  height: 4rem;
  box-shadow: 0 3px 6px #00000029;
`;

const TopNav = () => {
    const api = useContext(ApiContext);
    const {setLeftNavOpen} = useContext(AppContext);

    const [userFacility, setUserFacility] = useLocalStorage('facility_id', '');
    const [, setUserFacilityLabel] = useLocalStorage('facility_label', '');
    const selectFacilityToastId = useRef(null);
    const [me, setMe] = useState(null);

    useEffect(() => {
        const getMe = async () => {
            const response = await api.get('/me');

            if (isResponseError(response)) {
                return;
            }

            setMe(response?.data);

            setUserFacility(response
                ?.data
                ?.default_facility
                ?.facility_id
                ?? '');

            setUserFacilityLabel(response
                ?.data
                ?.default_facility
                ?.label
                ?? '');
        };

        if (api) {
            getMe();
        }
    }, [api, setUserFacility, setUserFacilityLabel]);

    useEffect(() => {
        if (!me) {
            return;
        }

        if (!userFacility && !selectFacilityToastId.current) {
            selectFacilityToastId.current = toast.warning(
                'Select a Facility to get started',
                {
                    autoClose: false,
                    closeButton: false,
                    draggable: false,
                },
            );
            setLeftNavOpen(true);
            return;
        }

        if (userFacility && selectFacilityToastId.current) {
            toast.dismiss(selectFacilityToastId.current);
        }
    }, [me, setLeftNavOpen, userFacility]);

    useEffect(() => {
        const userId = me?.user_id;

        if (userId) {
            LogRocket.identify(userId, {
                name: me?.name,
                email: me?.email,
            });
        }
    }, [me]);

    return <TopNavbar>
        <TopNavBrand/>
        {me && <>
            <Container className={'justify-content-center'}>
                <TopNavSearch userFacility={userFacility}/>
            </Container>
            <TopNavUser me={me}/>
        </>}
    </TopNavbar>;
};

export default TopNav;
