import React, {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import _ from 'lodash';
import {isResponseError} from '^utilities/isResponseError';
import {ApiContext} from '^contexts/api';
import {AppContext} from '^contexts/app';
import AsideHeader from '^common/asideHeader';
import {AsyncFormSelect, FormSelect} from '^common/formSelect';
import {Button, Form} from 'react-bootstrap';
import UnitAssetCapture from '^pages/workTicket/units/unitAssetCapture';
import {toast} from 'react-toastify';

const resourceToOption = (resource) => ({
    label: resource?.label,
    value: resource?.resource_id,
});

const UnitEdit = ({
    unit,
    updateAllUnits,
}) => {
    const api = useContext(ApiContext);
    const {setAsideChildren} = useContext(AppContext);
    const submitButtonRef = useRef(null);
    const [processing, setProcessing] = useState(false);
    const [serialized, setSerialized] = useState(false);
    const [serialNumber, setSerialNumber] = useState('');
    const [stage, setStage] = useState('');
    const [stages, setStages] = useState([]);
    const [resource, setResource] = useState('');
    const [resources, setResources] = useState([]);
    const [assetCapture, setAssetCapture] = useState({});

    const setFieldsFromUnit = useCallback(() => {
        if (unit) {
            setSerialized(unit?.serialized);
            setSerialNumber(unit?.serial_number ?? '');
            setStage(unit?.stage);
            setResource(unit?.resource?.resource_id ?? '');
            setAssetCapture(unit?.asset_capture ?? {});
        }
    }, [unit]);

    useEffect(() => {
        setFieldsFromUnit();
    }, [setFieldsFromUnit]);

    useEffect(() => {
        const fetchStages = async () => {
            const response = await api.get('/types/stage');

            if (isResponseError(response)) {
                return;
            }

            setStages(response?.data);
        };

        if (api) {
            fetchStages();
        }
    }, [api, unit]);

    const searchResources = useCallback(async (label) => {
        if (!api) {
            return [];
        }

        const response = await api.get('/resources', {
            params: {
                active: 1,
                facility_id: unit?.work_ticket
                    ?.work_ticket_group?.facility?.facility_id,
                ...label ? {label} : {},
            },
        });

        if (isResponseError(response)) {
            return [];
        }

        const resources = response?.data?.results ?? [];

        setResources((prevState) => _.uniqBy(
            [...prevState, ...resources],
            'resource_id',
        ));

        return _.map(resources, resourceToOption);
    }, [api, unit?.work_ticket?.work_ticket_group?.facility?.facility_id]);

    const setAssetCaptureField = useCallback((field, value, last) => {
        setAssetCapture((assetCapture) => ({
            ...assetCapture,
            [field]: _.trim(value),
        }));

        if (last && submitButtonRef) {
            submitButtonRef.current.focus();
        }
    }, []);

    const editUnit = useCallback(async () => {
        setProcessing(true);

        const response = await api.patch(`/units/${unit?.unit_id}`, {
            serialized: serialized,
            serial_number: _.isEmpty(serialNumber)
                ? null
                : serialNumber,
            stage: stage,
            resource: {
                resource_id: resource,
            },
            asset_capture: _.isEmpty(serialNumber)
                ? null
                : assetCapture,
        });

        setProcessing(false);

        if (isResponseError(response)) {
            toast.error(response?.data?.error);
            setFieldsFromUnit();
            return;
        }

        updateAllUnits(response?.data);
        setAsideChildren(null);
    }, [
        api,
        assetCapture,
        setFieldsFromUnit,
        resource,
        serialized,
        serialNumber,
        setAsideChildren,
        stage,
        unit?.unit_id,
        updateAllUnits,
    ]);

    const stageOptions = _.map(stages, (stage) => ({
        label: stage,
        value: stage,
    }));

    const resourceOptions = _.chain(resources)
        .concat(unit?.resource)
        .uniqBy('resource_id')
        .compact()
        .map(resourceToOption)
        .value();

    return <>
        <AsideHeader>
            {'Edit Unit'}
        </AsideHeader>
        <Form onSubmit={(event) => {
            event.preventDefault();
            editUnit();
        }}>
            <Form.Check
                disabled={processing}
                label={'Serialized'}
                className={'mb-3'}
                checked={serialized}
                onChange={(e) => setSerialized(e.target.checked)}
            />
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Serial Number'}</Form.Text>
                <Form.Control
                    type={'text'}
                    disabled={processing || unit?.has_assembly_components}
                    value={serialNumber}
                    onChange={(e) => setSerialNumber(e.target.value)}
                />
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Stage'}</Form.Text>
                <FormSelect
                    isDisabled={processing}
                    value={_.find(stageOptions, {value: stage})}
                    options={stageOptions}
                    onChange={(e) => setStage(e.value)}
                />
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Resource'}</Form.Text>
                <AsyncFormSelect
                    isDisabled={processing}
                    isClearable={true}
                    backspaceRemovesValue={true}
                    loadOptions={searchResources}
                    value={_.find(resourceOptions, {value: resource})}
                    onChange={(e) => setResource(e?.value)}
                />
            </Form.Group>
            {!_.isEmpty(serialNumber) && <UnitAssetCapture
                assetCapture={assetCapture}
                setAssetCaptureField={setAssetCaptureField}
                onLastBlur={() => submitButtonRef?.current?.focus?.()}
            />}
            <Button
                ref={submitButtonRef}
                className={'w-100 my-3'}
                variant={'success'}
                type={'submit'}
                disabled={processing || !stage}
            >
                {'Submit'}
            </Button>
        </Form>
    </>;
};

export default UnitEdit;
