import React, {useRef, useState, useEffect} from 'react';
import {createSearchParams, Link} from 'react-router-dom';
import _ from 'lodash';
import {OverlayTrigger, Popover} from 'react-bootstrap';
import AssetBarcode from '../common/assetBarcode';
import BaseCol from '../common/baseCol';
import OverFlowContainer from '../common/overFlowContainer';
import BaseRow from '../common/baseRow';
import {
    ArrowRight,
    ExternalLinkIcon,
    UnitIcon,
    WarningIcon,
} from '../common/icon';
import UnitAssetDetails from '../common/unitAssetDetails';
import {ClipboardText} from '^common/clipboardText';

const UnitRow = ({
    unit,
    workTicketId,
    count = 0,
    bg = 'white',
    first = false,
    showAssetDetails = false,
}) => {
    const overFlowRef = useRef(null);
    const [serialOverFlow, setSerialOverFlow] = useState(false);

    const serialized = unit?.serialized ?? true;
    const serial = unit?.serial_number;
    const sku = unit?.sku ?? '';
    const barcode = unit?.barcode;

    useEffect(() => {
        if (overFlowRef.current
            && overFlowRef.current.scrollWidth
            > overFlowRef.current.clientWidth) {
            setSerialOverFlow(true);
        } else {
            setSerialOverFlow(false);
        }
    }, [serial]);

    return <BaseRow $first={first} $bg={bg}>
        {/* indentation and different work group col */}
        <BaseCol xs={2} sm={1} className={'pe-0'}>
            {_.times(
                unit.assembly_level ?? 0,
                (idx) => <ArrowRight key={idx}/>,
            )}
            {unit.work_ticket.work_ticket_id !== workTicketId
                && <OverlayTrigger
                    trigger={'click'}
                    rootClose={true}
                    overlay={<Popover>
                        <Popover.Body>
                            <Link
                                className={'text-decoration-none text-primary'}
                                to={{
                                    pathname: '/packing',
                                    search: `?${createSearchParams({
                                        work_ticket_number:
                                        unit.work_ticket.work_ticket_number,
                                    })}`,
                                }}
                            >
                                {unit.work_ticket.work_ticket_number}
                                <ExternalLinkIcon/>
                            </Link>
                        </Popover.Body>
                    </Popover>}
                >
                    <WarningIcon/>
                </OverlayTrigger>}
        </BaseCol>
        <BaseCol className={'text-primary'} sm={6} lg={2}>
            <OverlayTrigger
                trigger={'click'}
                rootClose={true}
                overlay={<Popover>
                    <Popover.Body>
                        {unit.config.part.description}
                    </Popover.Body>
                </Popover>}
            >
                <div role={'button'} className={'d-inline'}>
                    {`P/N: ${barcode ?? sku}`}
                </div>
            </OverlayTrigger>
            <ClipboardText value={barcode ?? sku}/>
        </BaseCol>
        <BaseCol sm={5} lg={3}>
            <AssetBarcode value={barcode ?? sku}/>
        </BaseCol>
        <BaseCol className={'text-primary'} sm={5} lg={2}
            ref={overFlowRef}
        >
            {serialOverFlow
                ? <OverFlowContainer>
                    {`S/N: ${serialized
                        ? serial ?? 'NOT-CAPTURED'
                        : 'Non-Serialized'}`}
                </OverFlowContainer>
                : <div className={'d-inline'}>
                    {`S/N: ${serialized
                        ? serial ?? 'NOT-CAPTURED'
                        : 'Non-Serialized'}`}
                </div>}
            {serialized && serial && <ClipboardText value={serial}/>}
        </BaseCol>
        <BaseCol sm={5} lg={3}>
            {serialized && serial && <AssetBarcode value={serial}/>}
        </BaseCol>
        <BaseCol xs={2} sm={1}>
            {count > 0 && <>
                <UnitIcon/>
                <span>{count}</span>
            </>}
        </BaseCol>
        {showAssetDetails && <UnitAssetDetails unit={unit}/>}
    </BaseRow>;
};

export default UnitRow;
