import styled from 'styled-components';
import {Card} from 'react-bootstrap';

const CustomCard = styled(Card).attrs(({className}) => ({
    className: `${className} shadow-sm bg-white rounded p-n1`,
}))`
  && {
    margin-bottom: 0.1rem;
    border: 1.5px solid #DAE7FE;
  }
`;

export default CustomCard;
