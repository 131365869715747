import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import {createSearchParams, Link} from 'react-router-dom';
import _ from 'lodash';
import {useLocalStorage} from 'usehooks-ts';
import {isResponseError} from '^utilities/isResponseError';
import {ApiContext} from '^contexts/api';
import {AppContext} from '^contexts/app';
import exportIcon from '^assets/images/export.svg';
import reportIcon from '^assets/images/report.svg';
import {Col, Container, Row} from 'react-bootstrap';
import {CSVLink} from 'react-csv';
import {PageActionFilter, PageActionIcon} from '^common/pageAction';
import defaultFilters from './defaultFilters';
import Chart from './chart';
import Filter from './filter';
import Table from './table';

const StatusIntervals = ({
    metricName,
    exportPrefix,
    countLabel,
}) => {
    const api = useContext(ApiContext);
    const {setAsideChildren} = useContext(AppContext);
    const chartColRef = useRef(null);

    const [userFacility] = useLocalStorage('facility_id', '');
    const [userFacilityLabel] = useLocalStorage('facility_label', '');

    const [metrics, setMetrics] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedMetric, setSelectedMetric] = useState('today');

    const [
        filterParams,
        setFilterParams,
    ] = useState(defaultFilters);

    const intervalStatusFilter = filterParams?.interval_status;
    const totalStatusFilter = filterParams?.total_status;
    const poolFilter = filterParams?.pool;

    useEffect(() => {
        const fetchMetrics = async () => {
            setLoading(true);

            const response = await api.get(
                `/metrics/${metricName}`,
                {
                    params: {
                        facility_id: userFacility,
                        ..._.size(intervalStatusFilter)
                            ? {interval_status: _.join(
                                intervalStatusFilter,
                                ',',
                            )}
                            : {},
                        ..._.size(totalStatusFilter)
                            ? {total_status: _.join(
                                totalStatusFilter,
                                ',',
                            )}
                            : {},
                        ..._.size(poolFilter)
                            ? {pool: _.join(poolFilter, ',')}
                            : {},
                    },
                },
            );

            setLoading(false);

            if (isResponseError(response)) {
                return;
            }

            setMetrics(response?.data);
        };

        if (!api) {
            return;
        }

        if (!userFacility) {
            return;
        }

        fetchMetrics();
    }, [
        api,
        filterParams,
        intervalStatusFilter,
        metricName,
        poolFilter,
        totalStatusFilter,
        userFacility,
    ]);

    const openFilter = useCallback(() => {
        setAsideChildren(<Filter
            filterParams={filterParams}
            setFilterParams={setFilterParams}
        />);
    }, [filterParams, setAsideChildren]);

    const csvExport = useMemo(() => {
        if (!metrics) {
            return [];
        }

        return [
            ['total'],
            ..._.map(metrics.total, (val, key) => [key, val]),
            ..._.flatMap(metrics.by_customer, (val, key) => [
                [],
                [key],
                ..._.map(val, (count, customer) => [customer, count]),
            ]),
        ];
    }, [metrics]);

    return <Container fluid={true} className={'h-100 d-flex flex-column'}>
        <Row>
            <Col className={'text-end h-25'}>
                <Link to={{
                    pathname: 'reports/work_ticket_status',
                    search: `?${createSearchParams({
                        facility_id: userFacility,
                        ...selectedMetric
                            ? {time: selectedMetric}
                            : {},
                        ...intervalStatusFilter
                            ? {status: _.join(intervalStatusFilter, ',')}
                            : {},
                        ...poolFilter
                            ? {pool: _.join(poolFilter, ',')}
                            : {},
                    })}`,
                }}>
                    <PageActionIcon
                        src={reportIcon}
                        alt={'Report'}
                        onClick={_.noop}
                    />
                </Link>
                {!loading && <CSVLink
                    data={csvExport}
                    filename={`${exportPrefix} (${userFacilityLabel}).csv`}
                >
                    <PageActionIcon
                        src={exportIcon}
                        alt={'Export'}
                        onClick={_.noop}
                    />
                </CSVLink>}
                <PageActionFilter
                    onClick={openFilter}
                    filterParams={filterParams}
                    defaultFilterParams={defaultFilters}
                />
            </Col>
        </Row>
        <Row className={'flex-grow-1'}>
            <Col xs={12} md={4}>
                <Table
                    metrics={metrics}
                    loading={loading}
                    countLabel={countLabel}
                    selectedMetric={selectedMetric}
                    setSelectedMetric={setSelectedMetric}
                />
            </Col>
            <Col xs={12} md={8} ref={chartColRef}>
                <Chart
                    container={chartColRef}
                    metrics={metrics}
                    loading={loading}
                    selectedMetric={selectedMetric}
                />
            </Col>
        </Row>
    </Container>;
};

export default StatusIntervals;
