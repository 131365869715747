import React, {useCallback, useContext, useEffect, useState} from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import scrollUp from '^assets/images/scrollUp.svg';
import scrollDown from '^assets/images/scrollDown.svg';
import {AppContext} from '^contexts/app';

const ScrollIcon = styled.img.attrs(() => ({
    className: 'd-block my-1 d-print-none',
    type: 'button',
}))`
  height: 2rem;
  visibility: ${({hidden}) => hidden && 'hidden'};
`;

const ScrollPlacement = styled.div.attrs(() => ({
    className: _.join([
        'd-none',
        'd-sm-block',
        'position-absolute',
        'bottom-0',
        'end-0',
    ], ' '),
}))`
    margin-right: 1.5rem;
    margin-bottom: 4rem;
`;

export const ScrollButtons = ({
    container,
}) => {
    const [scrollHeight, setScrollHeight] = useState(0);
    const [clientHeight, setClientHeight] = useState(0);
    const [scrollTop, setScrollTop] = useState(0);

    const isScrollAtTop = scrollTop < 1;
    const isScrollAtBottom = scrollTop + clientHeight > scrollHeight - 1;

    // There's no JS listener/observer that handles this for us,
    //  so we need to invoke this callback for events that might affect
    //  the scroll height & client height
    const updateScrollPosition = useCallback(() => {
        setScrollHeight(container?.current?.scrollHeight ?? 0);
        setClientHeight(container?.current?.clientHeight ?? 0);
        setScrollTop(container?.current?.scrollTop ?? 0);
    }, [container]);

    // Trigger the callback when the window resizes
    useEffect(() => {
        const resizeObserver = new ResizeObserver(updateScrollPosition);
        resizeObserver.observe(container?.current);

        return () => {
            resizeObserver.disconnect();
        };
    }, [container, updateScrollPosition]);

    // Trigger the callback when the content changes (e.g. infinite scroll)
    useEffect(() => {
        const mutationObserver = new MutationObserver(updateScrollPosition);

        mutationObserver.observe(container?.current, {
            childList: true,
            subtree: true,
        });

        return () => {
            mutationObserver.disconnect();
        };
    }, [container, updateScrollPosition]);

    // Trigger the callback on scroll events
    useEffect(() => {
        window.addEventListener('scroll', updateScrollPosition, true);

        return () => {
            window.removeEventListener('scroll', updateScrollPosition, true);
        };
    }, [updateScrollPosition]);

    // Trigger the callback when the fonts are loaded (can affect the
    //  content size after the content changes on initial page load)
    useEffect(() => {
        document.fonts.onloadingdone = updateScrollPosition;
    }, [updateScrollPosition]);

    return <ScrollPlacement>
        <ScrollIcon
            src={scrollUp}
            alt={'Scroll Up'}
            hidden={isScrollAtTop}
            onClick={() => container.current.scrollBy({
                top: -clientHeight,
                behavior: 'smooth',
            })}
        />
        <ScrollIcon
            src={scrollDown}
            alt={'Scroll Down'}
            hidden={isScrollAtBottom}
            onClick={() => container.current.scrollBy({
                top: clientHeight,
                behavior: 'smooth',
            })}
        />
    </ScrollPlacement>;
};

export const useScrollButtons = () => {
    const {setShowScrollButtons} = useContext(AppContext);

    useEffect(() => {
        setShowScrollButtons(true);

        return () => {
            setShowScrollButtons(false);
        };
    }, [setShowScrollButtons]);
};
