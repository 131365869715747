import React, {useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import {CSVLink} from 'react-csv';
import {ApiContext} from '^contexts/api';
import {isResponseError} from '^utilities/isResponseError';
import exportIcon from '^assets/images/export.svg';
import {PageActionIcon} from '^common/pageAction';
import {Col, Container, Form, Row, Spinner} from 'react-bootstrap';
import AssemblyGroup from './assemblyGroup';
import KitGroup from './kitGroup';
import UnitRow from './unitRow';
import {toast} from 'react-toastify';
import {buildAssembly, buildUnitsCsv} from '../common/util';

const SectionHeader = styled.h4.attrs(() => ({
    className: 'text-primary mt-3',
}))`
    text-decoration: underline;
`;

const WorkTicketResult = ({
    workTicket,
    setActionButtons,
    showAssetDetails,
    showGroupUnits,
    filterStages,
}) => {
    const api = useContext(ApiContext);

    const [units, setUnits] = useState([]);
    const [loading, setLoading] = useState(false);
    const [serialFilter, setSerialFilter] = useState('');
    const [kitFilter, setKitFilter] = useState('');

    const workTicketId = workTicket?.work_ticket_id;

    const workTicketGroupId = workTicket
        ?.work_ticket_group?.work_ticket_group_id;

    useEffect(() => {
        const getUnits = async () => {
            setLoading(true);
            let nextOffset = null;
            setUnits([]);

            do {
                const response = await api.get('/units', {
                    params: {
                        work_ticket_group_id: workTicketGroupId,
                        stage: _.join(filterStages, ','),
                        offset: nextOffset,
                    },
                });

                if (isResponseError(response)) {
                    toast.error(response?.data?.error);
                    nextOffset = null;
                    break;
                }

                setUnits((prevState) => _.uniqBy(
                    [...prevState, ...response.data.results],
                    'unit_id',
                ));

                nextOffset = response.data?.next_offset;
            } while (nextOffset);

            setLoading(false);
        };

        if (!api) {
            return;
        }

        if (!workTicketGroupId) {
            return;
        }

        if (_.size(filterStages) < 1) {
            return;
        }

        getUnits();
    }, [api, filterStages, workTicketGroupId]);

    const kits = _.chain(units)
        .map('kit')
        .compact()
        .uniqBy('kit_id')
        .filter((kit) => showGroupUnits
            ? true
            : _.some(
                _.filter(units, (unit) => unit.kit?.kit_id === kit.kit_id),
                (unit) => unit.work_ticket.work_ticket_id === workTicketId,
            ))
        .filter((kit) => kitFilter
            ? kit?.label === kitFilter
            : true)
        .value();

    const assemblyParents = _.chain(units)
        .filter(() => !kitFilter)
        .filter((unit) => _.isNil(unit.kit))
        .filter((unit) => unit.has_assembly_components)
        .filter((unit) => !unit.assembled_into_unit_id)
        .filter((unit) => showGroupUnits
            ? true
            : _.some(
                buildAssembly(unit, units),
                (unit) => unit.work_ticket.work_ticket_id === workTicketId,
            ))
        .value();

    const standaloneUnits = _.chain(units)
        .filter(() => !kitFilter)
        .filter((unit) => _.isNil(unit.kit))
        .filter((unit) => !unit.has_assembly_components)
        .filter((unit) => !unit.assembled_into_unit_id)
        .filter((unit) => showGroupUnits
            ? true
            : unit.work_ticket.work_ticket_id === workTicketId)
        .filter((unit) => serialFilter
            ? unit?.serial_number === serialFilter
            : true)
        .value();

    useEffect(() => {
        const csvExport = buildUnitsCsv(units);

        setActionButtons(<CSVLink
            data={csvExport}
            filename={`${workTicket?.work_ticket_number}.csv`}>
            <PageActionIcon
                src={exportIcon}
                alt={'Export'}
                onClick={loading ? null : _.noop}
            />
        </CSVLink>);
    }, [
        loading,
        setActionButtons,
        units,
        workTicket?.work_ticket_number,
    ]);

    if (loading) {
        return <Row className={'justify-content-center'}>
            <Spinner/>
        </Row>;
    }

    return <>
        <Container fluid={true} className={'my-2'}>
            <Row className={'justify-content-center'}>
                <Col xs={8} md={3} xl={2}>
                    <Form.Control
                        type={'search'}
                        placeholder={`Filter by Unit S/N`}
                        value={serialFilter}
                        onChange={(e) => {
                            setKitFilter('');
                            setSerialFilter(
                                _.toUpper(e?.target?.value),
                            );
                        }}
                    />
                </Col>
                <Col
                    xs={12}
                    md={1}
                    className={'text-center my-auto fw-bold'}
                >
                    {'OR'}
                </Col>
                <Col xs={8} md={3} xl={2}>
                    <Form.Control
                        type={'search'}
                        placeholder={`Filter by Kit Label`}
                        value={kitFilter}
                        onChange={(e) => {
                            setSerialFilter('');
                            setKitFilter(e?.target?.value);
                        }}
                    />
                </Col>
            </Row>
        </Container>
        <Container fluid={true} className={'my-2'}>
            <SectionHeader>{'Kits'}</SectionHeader>
            {_.map(kits, (kit) => <KitGroup
                key={kit.kit_id}
                kit={kit}
                allUnits={units}
                showAssetDetails={showAssetDetails}
                workTicketId={workTicketId}
                serialFilter={serialFilter}
            />)}
            <SectionHeader>{'Assemblies (Not in Kits)'}</SectionHeader>
            {_.map(assemblyParents, (parent) => <AssemblyGroup
                key={parent.unit_id}
                parent={parent}
                allUnits={units}
                showAssetDetails={showAssetDetails}
                workTicketId={workTicketId}
                serialFilter={serialFilter}
            />)}
            <SectionHeader>{'Standalone Units'}</SectionHeader>
            {_.map(standaloneUnits, (unit, idx) => <UnitRow
                key={unit.unit_id}
                first={idx === 0}
                unit={unit}
                showAssetDetails={showAssetDetails}
                workTicketId={workTicketId}
            />)}
        </Container>
    </>;
};

export default WorkTicketResult;
