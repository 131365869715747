import React from 'react';
import {useLocalStorage} from 'usehooks-ts';
import {PageHeader} from '^common/pageHeader';
import HomeWidget from './homeWidget';
import MyAssignments from './myAssignments/myAssignments';
import MaterialUnitsDone from '^pages/home/statusIntervals/materialUnitsDone';
import ServiceSkusDone from './statusIntervals/serviceSkusDone';
import WorkDone from './statusIntervals/workDone';

const HomeContainer = () => {
    const [userFacilityLabel] = useLocalStorage('facility_label', '');

    return <>
        <PageHeader title={'Home'}/>
        <HomeWidget
            name={'my_assignments'}
            title={`My Assignments (${userFacilityLabel})`}
        >
            <MyAssignments/>
        </HomeWidget>
        <HomeWidget
            name={'work_done'}
            title={`Work Done (${userFacilityLabel})`}
        >
            <WorkDone/>
        </HomeWidget>
        <HomeWidget
            name={'service_skus_done'}
            title={`Service SKUs Done (${userFacilityLabel})`}
        >
            <ServiceSkusDone/>
        </HomeWidget>
        <HomeWidget
            name={'material_units_done'}
            title={`Material Units Done (${userFacilityLabel})`}
        >
            <MaterialUnitsDone/>
        </HomeWidget>
    </>;
};

export default HomeContainer;
