import styled from 'styled-components';
import backIcon from '^assets/images/back.svg';

const AsideBackIcon = styled.img.attrs(() => ({
    className: 'me-2 mb-1',
    role: 'button',
    src: backIcon,
}))`
  height: 2rem;
  width: 2rem;
`;

export default AsideBackIcon;
