import React, {useCallback, useState} from 'react';
import _ from 'lodash';
import CustomCard from '^common/customCard';
import {Button, Card} from 'react-bootstrap';
import {TableIcon, TableIconText} from '^common/tableIcon';
import unitIcon from '^assets/images/unit.svg';
import {countDescendants, renderDescendants, getAssemblyUnits}
    from '^pages/workTicket/utilities/kittingAssemblyHelper';
import {usePagination} from '^utilities/usePagination';

const AddToCard = ({
    api,
    assemble,
    unit,
    kit,
    originalUnitId,
    descendantTrees,
    setDescendantUnits,
    unitDescendantNames,
    handleSubmit,
}) => {
    const [submitted, setSubmitted] = useState(false);

    const [loaded, setLoaded] = useState(false);

    const fetchAssemblyForUnit = useCallback(() => {
        if (!loaded && api && !kit && unit) {
            setLoaded(true);
            getAssemblyUnits(unit, api, true)
                .then((result) => {
                    setDescendantUnits((prevState) => ({
                        ...prevState,
                        available: {...prevState.available, ...result},
                    }));
                })
                .catch((e) => console.error(e));
        }
    }, [loaded, unit, api, setDescendantUnits, kit]);

    const elementRef = usePagination(null, fetchAssemblyForUnit);

    const cardBody = unit
        ? <>
            <div>
                <b>{`Serial Number: ${unit.serial_number}`}</b>
            </div>
            <div>
                {`Desc: ${unit?.config?.part?.description}`}
            </div>
            <div>
                {`${unit?.stage} | ${unit?.resource?.label ?? 'N/A'}`}
            </div>
            {!_.isEmpty(descendantTrees?.available[unit.unit_id]?.children)
                && <div>
                    {`${countDescendants(
                        descendantTrees?.available[unit.unit_id]?.children,
                        true)
                    } Subcomponents`}
                </div>
            }
            {descendantTrees?.available && renderDescendants(
                descendantTrees?.available[unit.unit_id]?.children,
                1,
                originalUnitId,
                unitDescendantNames,
            )}
        </>
        : kit
            ? <>
                <div>
                    <b>{`Label: ${kit?.label}`}</b>
                </div>
                <div>
                    <TableIcon
                        src={unitIcon}
                    />
                    <TableIconText>
                        {`Contains ${kit?.unit_count} Units`}
                    </TableIconText>
                </div>
            </> : null;

    return <CustomCard className={'mt-1'} ref={elementRef}>
        <Card.Body>
            {cardBody}
            <Button
                disabled={submitted}
                className={
                    'position-absolute top-50 end-0 translate-middle-y me-3'
                }
                size={'sm'}
                variant={assemble ? 'success' : 'danger'}
                onClick={async () => {
                    setSubmitted(true);
                    const submitted = await handleSubmit(
                        assemble, unit ? unit.unit_id : kit?.kit_id);
                    if (submitted) {
                        setSubmitted(false);
                    }
                }}
            >{assemble
                    ? `Add to This ${unit ? 'Unit' : kit ? 'Kit' : ''}`
                    : `Remove From This ${unit ? 'Unit' : kit ? 'Kit' : ''}`
                }</Button>
        </Card.Body>
    </CustomCard>;
};

export default AddToCard;
