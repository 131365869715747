import styled from 'styled-components';
import warningIcon from '^assets/images/warning.svg';

const MetricsWarningIcon = styled.img.attrs({
    className: 'd-block mx-auto',
    src: warningIcon,
    alt: 'Warning',
})`
    height: 1.5rem;
    width: 1.5rem;
`;

export default MetricsWarningIcon;
