import styled from 'styled-components';

export const FixedWidthTd = styled.td.attrs(() => ({
    className: 'border-0 align-middle text-primary',
}))`
    min-width: 20ch;
`;

export const RowNumTd = styled.td.attrs(() => ({
    className: 'border-0 align-middle',
}))`
    min-width: 3ch;
    max-width: 3ch;
`;
