import React, {useCallback, useContext, useEffect, useState} from 'react';
import _ from 'lodash';
import {toast} from 'react-toastify';
import {Form} from 'react-bootstrap';
import {isResponseError} from '^utilities/isResponseError';
import {AsyncFormSelect, FormSelect} from '^common/formSelect';
import {ApiContext} from '^contexts/api';
import BaseCol from '^pages/packing/common/baseCol';
import BaseRow from '^pages/packing/common/baseRow';
import {UnitIcon} from '^pages/packing/common/icon';
import {buildAssembly} from '^pages/packing/common/util';
import UnitRow from './unitRow';

const resourceToOption = (resource) => ({
    label: resource?.label,
    value: resource?.resource_id,
});

const KitGroup = ({
    kit,
    allUnits,
    workTicketId,
    updateUnit,
    isStageEditable,
    isResourceEditable,
    showAssetDetails,
    stages,
    defaultResources,
    searchResources,
}) => {
    const api = useContext(ApiContext);

    const [processing, setProcessing] = useState(false);
    const [stage, setStage] = useState('');
    const [resource, setResource] = useState('');

    const kitUnits = _.filter(
        allUnits,
        (unit) => unit.kit?.kit_id === kit.kit_id,
    );

    useEffect(() => {
        if (kit) {
            setStage(kit?.stage);
            setResource(kit?.resource);
        }
    }, [kit]);

    const patchStage = useCallback(async (oldStage, newStage) => {
        setProcessing(true);

        const response = await api.patch(`/kits/${kit?.kit_id}`, {
            stage: newStage,
        });

        setProcessing(false);

        if (isResponseError(response)) {
            toast.error(response?.data?.error);
            setStage(oldStage);
            return;
        }

        _.forEach(kitUnits, (unit) => updateUnit({
            ...unit,
            stage: response?.data?.stage,
        }));
    }, [api, kit?.kit_id, kitUnits, updateUnit]);

    const patchResource = useCallback(async (oldResource, newResource) => {
        setProcessing(true);

        const response = await api.patch(`/kits/${kit?.kit_id}`, {
            resource: {resource_id: newResource},
        });

        setProcessing(false);

        if (isResponseError(response)) {
            toast.error(response?.data?.error);
            setResource(oldResource);
            return;
        }

        _.forEach(kitUnits, (unit) => updateUnit({
            ...unit,
            resource: response?.data?.resource,
        }));
    }, [api, kit?.kit_id, kitUnits, updateUnit]);

    const assemblyParents = _.chain(kitUnits)
        .filter((unit) => unit.has_assembly_components)
        .filter((unit) => !unit.assembled_into_unit_id)
        .value();

    const kitAssemblies = _.map(
        assemblyParents,
        (parent) => buildAssembly(parent, allUnits),
    );

    const standaloneUnits = _.differenceBy(
        kitUnits,
        _.flatten(kitAssemblies),
        'unit_id',
    );

    const stageOptions = _.map(stages, (stage) => ({
        label: stage,
        value: stage,
    }));

    return <div className={'mb-2'}>
        <BaseRow $first={true} $bg={'secondary'}>
            <BaseCol xs={1}/>
            <BaseCol className={'text-primary'}>
                {`Label: ${kit.label}`}
            </BaseCol>
            {isStageEditable && <BaseCol
                className={'text-primary'}
                sm={6}
                lg={2}
            >
                <Form.Group>
                    <Form.Text>{'Stage'}</Form.Text>
                    <FormSelect
                        isDisabled={processing}
                        value={_.find(stageOptions, {value: stage})}
                        options={stageOptions}
                        onChange={(e) => {
                            setStage(e.value);
                            patchStage(stage, e.value);
                        }}
                    />
                </Form.Group>
            </BaseCol>}
            {isResourceEditable && <BaseCol
                className={'text-primary'}
                sm={6}
                lg={2}
            >
                <Form.Group>
                    <Form.Text>{'Resource'}</Form.Text>
                    <AsyncFormSelect
                        defaultOptions={defaultResources}
                        isDisabled={processing}
                        isClearable={true}
                        backspaceRemovesValue={true}
                        loadOptions={searchResources}
                        value={resource
                            ? resourceToOption(resource)
                            : null}
                        onChange={(e) => {
                            setResource(e);
                            patchResource(resource, e?.value);
                        }}
                    />
                </Form.Group>
            </BaseCol>}
            <BaseCol xs={1}>
                <UnitIcon/>
                <span>{_.size(kitUnits)}</span>
            </BaseCol>
        </BaseRow>
        {_.map(kitAssemblies, (assembly) =>
            _.map(assembly, (unit) => <UnitRow
                key={unit.unit_id}
                unit={unit}
                workTicketId={workTicketId}
                updateUnit={updateUnit}
                isStageEditable={false}
                isResourceEditable={false}
                showAssetDetails={showAssetDetails}
                stages={stages}
                defaultResources={defaultResources}
                searchResources={searchResources}
            />),
        )}
        {_.map(standaloneUnits, (unit) => <UnitRow
            key={unit.unit_id}
            unit={unit}
            workTicketId={workTicketId}
            updateUnit={updateUnit}
            isStageEditable={false}
            isResourceEditable={false}
            showAssetDetails={showAssetDetails}
            stages={stages}
            defaultResources={defaultResources}
            searchResources={searchResources}
        />)}
    </div>;
};

export default KitGroup;
