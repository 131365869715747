import React, {useEffect} from 'react';
import jsbarcode from 'jsbarcode';

const Barcode = ({
    className,
    value,
    format = 'CODE128',
}) => {
    const ref = React.useRef(null);

    useEffect(() => {
        jsbarcode(ref.current, value, {
            format: format,
            displayValue: false,
            margin: 0,
            background: 'transparent',
        });
    }, [ref, value, format]);

    return <svg
        ref={ref}
        className={className}
        preserveAspectRatio={'none'}
    />;
};

export default Barcode;
