import React, {useEffect} from 'react';
import {authHost, authClientId} from '^utilities/dataSet';

const Logout = () => {
    useEffect(() => {
        localStorage.clear();
        const logoutUrl = new URL(`${authHost}/logout`);
        const searchParams = logoutUrl.searchParams;
        searchParams.set('client_id', authClientId);
        searchParams.set('redirect_uri', `${window.location.origin}/login`);
        searchParams.set('response_type', 'code');
        window.location.href = logoutUrl.toString();
    }, []);

    return <>
        {'Redirecting to logout...'}
    </>;
};

export default Logout;
