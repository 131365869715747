import React, {useCallback, useContext, useEffect, useState} from 'react';
import _ from 'lodash';
import {isResponseError} from '^utilities/isResponseError';
import {ApiContext} from '^contexts/api';
import {AppContext} from '^contexts/app';
import AsideHeader from '^common/asideHeader';
import {FormSelect} from '^common/formSelect';
import {Button, Form} from 'react-bootstrap';
import {toast} from 'react-toastify';

const timeZoneOptions = _.map(
    Intl.supportedValuesOf('timeZone'),
    (timeZone) => ({
        label: timeZone,
        value: timeZone,
    }),
);

const FacilityCreateEdit = ({
    facility,
    updateFacility,
}) => {
    const api = useContext(ApiContext);
    const {setAsideChildren} = useContext(AppContext);
    const [processing, setProcessing] = useState(false);
    const [active, setActive] = useState(facility?.active ?? true);
    const [timeZone, setTimeZone] = useState(facility?.time_zone ?? '');
    const [code, setCode] = useState('');
    const [label, setLabel] = useState('');

    const facilityId = facility?.facility_id;

    useEffect(() => {
        setCode(facility?.code ?? '');
        setLabel(facility?.label ?? '');
        setTimeZone(facility?.time_zone ?? '');
    }, [facility?.code, facility?.label, facility?.time_zone]);

    const createEditFacility = useCallback(async () => {
        setProcessing(true);

        const body = {
            code,
            active,
            label,
            time_zone: timeZone,
        };

        const response = facilityId
            ? await api.patch(`/facilities/${facilityId}`, body)
            : await api.post('/facilities', body);

        setProcessing(false);

        if (isResponseError(response)) {
            toast.error(response?.data?.error);
            return;
        }

        updateFacility(response?.data);
        setAsideChildren(null);
    }, [
        active,
        api,
        code,
        facilityId,
        label,
        setAsideChildren,
        timeZone,
        updateFacility,
    ]);

    return <>
        <AsideHeader>
            {facilityId
                ? `Edit Facility "${facility?.label ?? ''}"`
                : 'Create New Facility'}
        </AsideHeader>
        <Form onSubmit={(event) => {
            event.preventDefault();
            createEditFacility();
        }}>
            <Form.Group className={'mb-3'}>
                <Form.Check
                    disabled={processing}
                    type={'checkbox'}
                    label={'Active'}
                    checked={active}
                    onChange={(e) => setActive(e.target.checked)}
                ></Form.Check>
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Facility Code'}</Form.Text>
                <Form.Control
                    disabled={facility?.has_work_ticket || processing}
                    type={'text'}
                    required={true}
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                />
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Label'}</Form.Text>
                <Form.Control
                    disabled={processing}
                    type={'text'}
                    required={true}
                    value={label}
                    onChange={(e) => setLabel(e.target.value)}
                />
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Time Zone'}</Form.Text>
                <FormSelect
                    isDisabled={processing}
                    value={_.find(timeZoneOptions, {
                        value: timeZone,
                    }) ?? null}
                    options={timeZoneOptions}
                    onChange={(e) => setTimeZone(e?.value)}
                />
            </Form.Group>
            <Button
                className={'w-100 my-3'}
                variant={'success'}
                disabled={processing || !_.every([
                    code,
                    label,
                ])}
                type={'submit'}
            >
                {'Submit'}
            </Button>
        </Form>
    </>;
};

export default FacilityCreateEdit;
