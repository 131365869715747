import React, {useCallback, useContext} from 'react';
import _ from 'lodash';
import {AppContext} from '^contexts/app';
import history from '^assets/images/history.svg';
import AsideGroupHeader from '^common/asideGroupHeader';
import AsideHeader from '^common/asideHeader';
import HistoryAside from '^common/historyAside';
import {TableIcon} from '^common/tableIcon';
import {Container, Row, Col} from 'react-bootstrap';

const PartAside = ({
    part,
}) => {
    const {setAsideChildren} = useContext(AppContext);

    const details = {
        'Part Number': part?.part_number,
        'Manufacturer Part Number': part?.manufacturer_part_number ?? 'N/A',
        'Type': part?.type ?? 'N/A',
        'Description': part?.description ?? 'N/A',
        'Service': part?.is_service
            ? 'Yes'
            : 'No',
        'Lab Service': part?.is_lab_service
            ? 'Yes'
            : 'No',
    };

    const openSelf = useCallback(() => {
        setAsideChildren(<PartAside part={part}/>);
    }, [part, setAsideChildren]);

    const historyAsideProps = {
        label: part?.part_number ?? 'N/A',
        baseEndpoint: `/parts/${part?.part_id}`,
        onReturn: openSelf,
    };

    const historyOnClicks = {
        'Manufacturer Part Number': () => {
            setAsideChildren(<HistoryAside
                {...historyAsideProps}
                field={'manufacturer_part_number'}
            />);
        },
        'Type': () => {
            setAsideChildren(<HistoryAside
                {...historyAsideProps}
                field={'type'}
            />);
        },
        'Description': () => {
            setAsideChildren(<HistoryAside
                {...historyAsideProps}
                field={'description'}
            />);
        },
        'Service': () => {
            setAsideChildren(<HistoryAside
                {...historyAsideProps}
                field={'is_service'}
            />);
        },
        'Lab Service': () => {
            setAsideChildren(<HistoryAside
                {...historyAsideProps}
                field={'is_lab_service'}
            />);
        },
    };

    return <>
        <AsideHeader className={'d-flex justify-content-between'}>
            <b>{part?.part_number}</b>
        </AsideHeader>
        <AsideGroupHeader>
            {'Standard Fields'}
        </AsideGroupHeader>
        <Container>
            {_.map(details, (value, key) => <Row
                key={key}
            >
                <Col>
                    {`${key}: `}
                    {value}
                </Col>
                {_.has(historyOnClicks, key)
                    ? <Col xs={1} className={'text-end'}>
                        <TableIcon
                            src={history}
                            onClick={historyOnClicks[key]}
                        />
                    </Col> : null}
            </Row>)}
        </Container>
    </>;
};

export default PartAside;
