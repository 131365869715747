import {createSearchParams} from 'react-router-dom';
import _ from 'lodash';

export const defaultSearchParamsObj = Object.freeze({
    facility: {
        active: '1',
    },
    resource: {
        active: '1',
    },
    storageLocation: {
        active: '1',
    },
    stagedWorkTicket: {
        is_expired: '0',
    },
    workTicket: {
        status: [
            'Activated',
            'In-Lab',
            'Work-Complete',
            'Project-Staging',
            'In-Project',
            'On-Hold',
        ],
    },
});

export const defaultSearchParams = Object.freeze(
    _.chain(defaultSearchParamsObj)
        .mapValues((params) => _.mapValues(
            params,
            (param) => _.isArray(param)
                ? _.join(param, ',')
                : param,
        ))
        .mapValues((params) => `?${createSearchParams(params)}`)
        .value(),
);
