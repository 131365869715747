import React, {useContext, useState, useEffect, useCallback} from 'react';
import {createSearchParams, useSearchParams} from 'react-router-dom';
import _ from 'lodash';
import {ApiContext} from '^contexts/api';
import {isResponseError} from '^utilities/isResponseError';
import AsideHeader from '^common/asideHeader';
import {AsyncFormSelect, FormSelect} from '^common/formSelect';
import {Button, Form} from 'react-bootstrap';
import {
    INTERVAL_STATUS_OPTIONS,
} from '^pages/home/statusIntervals/common/filter';

const TIME_OPTIONS = [
    {label: 'Today', value: 'today'},
    {label: '1 Day', value: '1_day'},
    {label: '3 Days', value: '3_day'},
    {label: '7 Days', value: '7_day'},
    {label: '30 Days', value: '30_day'},
    {label: '90 Days', value: '90_day'},
];

const customerToOption = (customer) => ({
    label: customer?.label,
    value: customer?.customer_id,
});

const WorkTicketStatus = ({
    onSubmit,
}) => {
    const api = useContext(ApiContext);
    const [searchParams, setSearchParams] = useSearchParams();

    const [
        facilityId,
        setFacilityId,
    ] = useState(searchParams.get('facility_id'));

    const [
        time,
        setTime,
    ] = useState(searchParams.get('time'));

    const [
        status,
        setStatus,
    ] = useState(_.compact(_.split(searchParams.get('status'), ',')));

    const [
        pool,
        setPool,
    ] = useState((() => {
        const poolParam = searchParams.get('pool');

        return _.isNil(poolParam)
            ? null
            : _.map(
                _.split(poolParam, ','),
                (val) => val === '' ? null : val,
            );
    })());

    const [
        customerId,
        setCustomerId,
    ] = useState(searchParams.get('customer_id'));

    const [facilities, setFacilities] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [pools, setPools] = useState([]);

    useEffect(() => {
        const fetchFacilities = async () => {
            const response = await api.get(
                '/facilities',
                {params: {active: 1}},
            );

            if (isResponseError(response)) {
                return;
            }

            setFacilities(response?.data?.results);
        };

        if (!api) {
            return;
        }

        fetchFacilities();
    }, [api]);

    const searchCustomers = useCallback(async (label) => {
        if (!api) {
            return [];
        }

        const response = await api.get('/customers', {
            params: label ? {label} : {},
        });

        if (isResponseError(response)) {
            return [];
        }

        const customers = response?.data?.results ?? [];

        setCustomers((prevState) => _.uniqBy(
            [...prevState, ...customers],
            'customer_id',
        ));

        return _.map(customers, customerToOption);
    }, [api]);

    useEffect(() => {
        const addCustomer = async (customerId) => {
            if (!api) {
                return;
            }

            if (!customerId) {
                return;
            }

            const response = await api.get(`/customers/${customerId}`);

            if (isResponseError(response)) {
                return;
            }

            setCustomers((prevState) => _.uniqBy(
                [...prevState, response?.data],
                'customer_id',
            ));
        };

        const customerIds = _.map(customers, 'customer_id');

        if (!_.includes(customerIds, customerId)) {
            addCustomer(customerId);
        }
    }, [api, customerId, customers]);

    useEffect(() => {
        const fetchPools = async () => {
            const response = await api.get('/types/pool');

            if (isResponseError(response)) {
                return;
            }

            setPools(response?.data);
        };

        if (api) {
            fetchPools();
        }
    }, [api]);

    const resetFields = () => {
        setFacilityId(null);
        setTime(null);
        setStatus(null);
        setPool(null);
        setCustomerId(null);
    };

    const formSubmit = () => {
        const newSearchParams = {
            ...facilityId ? {facility_id: facilityId} : {},
            ...time ? {time: time} : {},
            ..._.size(status) ? {status: _.join(status, ',')} : {},
            ..._.size(pool) ? {pool: _.join(pool, ',')} : {},
            ...customerId ? {customer_id: customerId} : {},
        };

        setSearchParams(_.size(newSearchParams)
            ? `?${createSearchParams(newSearchParams)}`
            : '');

        onSubmit({
            facility_id: facilityId,
            time: time,
            status: status,
            pool: pool,
            customer_id: customerId,
        });
    };

    const facilityOptions = _.map(facilities, (facility) => ({
        label: facility?.label,
        value: facility?.facility_id,
    }));

    const poolOptions = _.map(pools, (pool) => ({
        label: pool ?? 'N/A',
        value: pool,
    }));

    const customerOptions = _.chain(customers)
        .uniqBy('customer_id')
        .compact()
        .map(customerToOption)
        .value();

    return <>
        <AsideHeader>
            <b>{'Report Options'}</b>
        </AsideHeader>
        <Form onSubmit={(event) => {
            event.preventDefault();
            formSubmit();
        }}>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Facility'}</Form.Text>
                <FormSelect
                    isClearable={true}
                    backspaceRemovesValue={true}
                    options={facilityOptions}
                    value={_.find(facilityOptions, {value: facilityId}) ?? null}
                    onChange={(e) => setFacilityId(e?.value)}
                />
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Time*'}</Form.Text>
                <FormSelect
                    isClearable={true}
                    backspaceRemovesValue={true}
                    options={TIME_OPTIONS}
                    value={_.find(TIME_OPTIONS, {value: time}) ?? null}
                    onChange={(e) => setTime(e?.value)}
                />
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Status*'}</Form.Text>
                <FormSelect
                    isMulti={true}
                    isClearable={true}
                    backspaceRemovesValue={true}
                    value={_.filter(
                        INTERVAL_STATUS_OPTIONS,
                        ({value}) => _.includes(status, value),
                    )}
                    options={INTERVAL_STATUS_OPTIONS}
                    onChange={(e) => setStatus(_.map(e, 'value'))}
                />
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Pool'}</Form.Text>
                <FormSelect
                    isMulti={true}
                    isClearable={true}
                    backspaceRemovesValue={true}
                    value={_.filter(
                        poolOptions,
                        ({value}) => _.includes(pool, value),
                    )}
                    options={poolOptions}
                    onChange={(e) => setPool(_.map(e, 'value'))}
                />
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Customer'}</Form.Text>
                <AsyncFormSelect
                    isClearable={true}
                    backspaceRemovesValue={true}
                    loadOptions={searchCustomers}
                    value={_.find(customerOptions, {value: customerId}) ?? null}
                    onChange={(e) => setCustomerId(e?.value)}
                />
            </Form.Group>
            <Button
                className={'w-100 mt-3'}
                type={'button'}
                onClick={() => resetFields()}
            >{'Reset Options'}</Button>
            <Button
                className={'w-100 my-3'}
                variant={'success'}
                type={'submit'}
                disabled={!_.every([
                    time,
                    _.size(status),
                ])}
            >{'Run Report'}</Button>
        </Form>
    </>;
};

export default WorkTicketStatus;
