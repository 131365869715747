import React, {useContext, useState} from 'react';
import _ from 'lodash';
import AsideHeader from '^common/asideHeader';
import {createSearchParams, useSearchParams} from 'react-router-dom';
import {Button, Form} from 'react-bootstrap';
import {AppContext} from '^contexts/app';
import {FormSelect} from '^common/formSelect';

const PartFilterAside = () => {
    const {setAsideChildren} = useContext(AppContext);
    const [searchParams, setSearchParams] = useSearchParams();

    const [
        partNumber,
        setPartNumber,
    ] = useState(searchParams.get('part_number'));

    const [
        isService,
        setIsService,
    ] = useState(searchParams.get('is_service'));

    const [
        hasAllDurations,
        setHasAllDurations,
    ] = useState(searchParams.get('has_all_durations'));

    const servicePartOptions = [
        {label: 'Service Parts Only', value: '1'},
        {label: 'Materials Parts Only', value: '0'},
    ];

    const hasAllDurationsOptions = [
        {label: 'All Metrics Provided', value: '1'},
        {label: 'Some Metrics Missing', value: '0'},
    ];

    const resetFields = () => {
        setIsService('');
        setPartNumber('');
        setHasAllDurations('');
    };

    const formSubmit = () => {
        const newSearchParams = {
            ...isService ? {is_service: isService} : {},
            ...partNumber ? {part_number: partNumber} : {},
            ...hasAllDurations ? {has_all_durations: hasAllDurations} : {},
        };

        setSearchParams(_.size(newSearchParams)
            ? `?${createSearchParams(newSearchParams)}`
            : '');
        setAsideChildren(null);
    };

    return <>
        <AsideHeader>
            <b>{'Filter Parts'}</b>
        </AsideHeader>
        <Form onSubmit={(event) => {
            event.preventDefault();
            formSubmit();
        }}>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Part #'}</Form.Text>
                <Form.Control
                    type={'search'}
                    value={partNumber}
                    onChange={(e) => setPartNumber(e?.target?.value)}
                />
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Service Parts'}</Form.Text>
                <FormSelect
                    isClearable={true}
                    backspaceRemovesValue={true}
                    isDisabled={!!hasAllDurations}
                    value={_.find(servicePartOptions, {
                        value: isService,
                    }) ?? null}
                    options={servicePartOptions}
                    onChange={(e) => {
                        setIsService(e?.value);
                    }}
                />
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Metrics'}</Form.Text>
                <FormSelect
                    isClearable={true}
                    backspaceRemovesValue={true}
                    value={_.find(hasAllDurationsOptions, {
                        value: hasAllDurations,
                    }) ?? null}
                    options={hasAllDurationsOptions}
                    onChange={(e) => {
                        const val = e?.value;
                        if (val) setIsService('1');
                        setHasAllDurations(val);
                    }}
                />
            </Form.Group>
            <Button
                className={'w-100 mt-3'}
                type={'button'}
                onClick={() => resetFields()}
            >{'Reset Filter'}</Button>
            <Button
                className={'w-100 my-3'}
                variant={'success'}
                type={'submit'}
            >{'Submit'}</Button>
        </Form>
    </>;
};

export default PartFilterAside;
