import styled from 'styled-components';
import _ from 'lodash';
import {Col} from 'react-bootstrap';

export const TableCol = styled(Col).attrs(({className}) => ({
    className: _.join([
        className,
        'align-self-center',
        'text-primary',
        'text-truncate',
    ], ' '),
}))`
  text-overflow: ellipsis;
`;

export const TableColActions = styled(TableCol).attrs(({className}) => ({
    className: _.join([
        className,
        'd-flex',
        'justify-content-end',
        'align-items-center',
        'overflow-visible',
    ], ' '),
}))``;
