import styled from 'styled-components';
import {Accordion} from 'react-bootstrap';

const CustomAccordion = styled(Accordion)`
  .accordion-item {
    border: none;
  }

  .accordion-button {
    background-color: var(--bs-white);
    color: var(--bs-black);

    &:focus {
      box-shadow: none !important;
    }
  }

  .accordion-header {
    border-bottom: 3px solid var(--bs-gray-300);
  }

  .accordion-body {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
    padding-right: 0 !important;
  }
`;

export default CustomAccordion;
