export default Object.freeze({
    interval_status: [
        'Lab-Done',
        'Project-Done',
    ],
    total_status: [
        'Activated',
        'In-Lab',
    ],
});
