import React, {useCallback, useContext, useEffect, useState} from 'react';
import _ from 'lodash';
import {ApiContext} from '^contexts/api';
import {AppContext} from '^contexts/app';
import {isResponseError} from '^utilities/isResponseError';
import history from '^assets/images/history.svg';
import verticalEllipsis from '^assets/images/verticalEllipsis.svg';
import AsideGroupHeader from '^common/asideGroupHeader';
import AsideHeader from '^common/asideHeader';
import HistoryAside from '^common/historyAside';
import {TableIcon} from '^common/tableIcon';
import {Container, Row, Col, Dropdown} from 'react-bootstrap';

const WorkTicketKitAside = ({
    kit,
    dropdownItems,
}) => {
    const api = useContext(ApiContext);
    const {setAsideChildren} = useContext(AppContext);

    const [units, setUnits] = useState({
        serialized: {},
        non_serialized: {},
    });

    useEffect(() => {
        const getUnits = async () => {
            const response = await api.get(
                '/units', {
                    params: {
                        kit_id: kit?.kit_id,
                    },
                },
            );

            if (isResponseError(response)) {
                return;
            }

            for (const kittedUnit of response?.data?.results || []) {
                if (_.isNil(kittedUnit?.assembled_into_unit_id)) {
                    if (kittedUnit?.serial_number) {
                        setUnits((prevState) => ({
                            ...prevState,
                            serialized: {
                                ...prevState.serialized,
                                [kittedUnit?.serial_number]: kittedUnit,
                            },
                        }));
                    } else {
                        setUnits((prevState) => ({
                            ...prevState,
                            non_serialized: {
                                ...prevState.non_serialized,
                                [kittedUnit?.sku ?? '']:
                                    // eslint-disable-next-line max-len
                                    [...(prevState.non_serialized[kittedUnit?.sku] || []),
                                        kittedUnit],
                            },
                        }));
                    }
                }
            }
        };

        if (kit?.kit_id) {
            getUnits();
        }
    }, [api, kit?.kit_id]);

    const historyAsideProps = {
        label: kit?.label ?? 'N/A',
        baseEndpoint: `/kits/${kit.kit_id}`,
        onReturn: useCallback(() => {
            setAsideChildren(<WorkTicketKitAside
                kit={kit}
                dropdownItems={dropdownItems}
            />);
        }, [dropdownItems, kit, setAsideChildren]),
    };

    const historyOnClicks = {
        'Stage': () => {
            setAsideChildren(<HistoryAside
                {...historyAsideProps}
                field={'stage'}
            />);
        },
        'Resource': () => {
            setAsideChildren(<HistoryAside
                {...historyAsideProps}
                field={'resource.label'}
            />);
        },
    };

    const getComponentDetails = (unitsMap) => {
        const unitDetails = [];

        for (const serialNum in unitsMap.serialized) {
            if (_.has(unitsMap.serialized, serialNum)) {
                unitDetails.push(
                    <div key={serialNum}>
                        {`${serialNum}`
                            + `(${unitsMap.serialized[serialNum]?.sku})`}
                    </div>,
                );
            }
        }

        for (const sku in unitsMap.non_serialized) {
            if (_.has(unitsMap.non_serialized, sku)) {
                unitDetails.push(
                    <div key={sku}>
                        {`${sku}: ${unitsMap.non_serialized[sku]?.length}`
                            + ' unit(s)'}
                    </div>,
                );
            }
        }

        return (
            <div>
                {unitDetails}
            </div>
        );
    };

    const details = {
        standard_fields: {
            'Stage': kit?.stage,
            'Resource': kit?.resource?.label,
        },
        components: getComponentDetails(units),
    };

    return <>
        <AsideHeader
            className={'d-flex justify-content-between'}
        >
            <b>{kit?.label ?? 'N/A'}</b>
            <Dropdown className={'mt-n1 pe-4'}>
                <Dropdown.Toggle
                    as={'img'}
                    role={'button'}
                    src={verticalEllipsis}
                    alt={'Dropdown Toggle'}
                    height={'20'}
                    width={'20'}
                />
                <Dropdown.Menu>
                    {dropdownItems(true, kit)}
                </Dropdown.Menu>
            </Dropdown>
        </AsideHeader>
        <AsideGroupHeader>
            {'Standard Fields'}
        </AsideGroupHeader>
        <Container>
            {_.map(details.standard_fields, (value, key) => (
                <Row>
                    <Col>
                        {`${key}: `}
                        {value}
                    </Col>
                    {_.has(historyOnClicks, key)
                        ? <Col xs={1} className={'text-end'}>
                            <TableIcon
                                src={history}
                                onClick={historyOnClicks[key]}
                            />
                        </Col> : null}
                </Row>
            ))}
        </Container>
        <AsideGroupHeader>
            {`Components: ${kit?.unit_count}`}
        </AsideGroupHeader>
        <Container>
            <Row>
                <Col>
                    {details.components}
                </Col>
            </Row>
        </Container>
    </>;
};

export default WorkTicketKitAside;
