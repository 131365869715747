import React from 'react';
import StatusIntervals from './common/statusIntervals';

const MaterialUnitsDone = () => {
    return <StatusIntervals
        metricName={'unit_status_intervals'}
        exportPrefix={'Material Units Done'}
        countLabel={'Material Units'}
    />;
};

export default MaterialUnitsDone;
