import React, {useCallback, useContext, useEffect, useState} from 'react';
import _ from 'lodash';
import {isResponseError} from '^utilities/isResponseError';
import {ApiContext} from '^contexts/api';
import {Col, Form, Row, Spinner} from 'react-bootstrap';
import AsideHeader from '^common/asideHeader';
import {AsyncFormSelect} from '^common/formSelect';
import {toast} from 'react-toastify';

const WorkTicketGroupAside = ({
    workTicket,
    updateWorkTicket,
}) => {
    const api = useContext(ApiContext);
    const [groupedWorkTickets, setGroupedWorkTickets] = useState([]);
    const [loading, setLoading] = useState(false);

    const workTicketId = workTicket?.work_ticket_id;
    const workTicketGroup = workTicket?.work_ticket_group;
    const workTicketGroupId = workTicketGroup?.work_ticket_group_id;
    const facilityId = workTicket?.work_ticket_group?.facility?.facility_id;

    const getGroupedWorkTickets = useCallback(async () => {
        setLoading(true);

        const response = await api.get('/work-tickets', {
            params: {work_ticket_group_id: workTicketGroupId},
            headers: {'X-Consistent-Read': true},
        });

        if (isResponseError(response)) {
            toast.error(response?.data?.error);
            setLoading(false);
            return;
        }

        setGroupedWorkTickets(_.filter(
            response?.data?.results,
            (workTicket) => workTicket?.work_ticket_id !== workTicketId,
        ));

        _.forEach(response?.data?.results, updateWorkTicket);
        setLoading(false);
    }, [api, updateWorkTicket, workTicketGroupId, workTicketId]);

    useEffect(() => {
        if (!api) {
            return;
        }

        getGroupedWorkTickets();
    }, [api, getGroupedWorkTickets]);

    const searchWorkTickets = useCallback(async (workTicketNumber) => {
        if (!api) {
            return [];
        }

        const response = await api.get(`/work-tickets`, {
            params: {
                facility_id: facilityId,
                ...workTicketNumber
                    ? {work_ticket_number: workTicketNumber}
                    : {},
            },
        });

        if (isResponseError(response)) {
            return [];
        }

        return _.map(
            response?.data?.results,
            (workTicket) => {
                const number = workTicket.work_ticket_number;
                const count = workTicket.work_ticket_group.member_count;

                return {
                    label: count > 1
                        ? `${number} (group of ${count})`
                        : number,
                    value: workTicket.work_ticket_id,
                };
            },
        );
    }, [api, facilityId]);

    const groupWorkTicket = useCallback(async (workTicketToAddId) => {
        setLoading(true);

        const response = await api.patch(
            `/work-tickets/${workTicketToAddId}`, {
                work_ticket_group: {work_ticket_group_id: workTicketGroupId},
            });

        if (isResponseError(response)) {
            toast.error(response?.data?.error);
            setLoading(false);
            return;
        }

        updateWorkTicket(response.data);
        await getGroupedWorkTickets();
        setLoading(false);
    }, [api, getGroupedWorkTickets, updateWorkTicket, workTicketGroupId]);

    const ungroupWorkTicket = useCallback(async (workTicketToRemoveId) => {
        setLoading(true);

        const response = await api.patch(
            `/work-tickets/${workTicketToRemoveId}`,
            {work_ticket_group: null},
        );

        if (isResponseError(response)) {
            toast.error(response?.data?.error);
            setLoading(false);
            return;
        }

        updateWorkTicket(response.data);
        await getGroupedWorkTickets();
        setLoading(false);
    }, [api, getGroupedWorkTickets, updateWorkTicket]);

    const onChange = useCallback(async (event, action) => {
        switch (action.action) {
            case 'select-option':
                await groupWorkTicket(action.option?.value);
                break;
            case 'remove-value':
                await ungroupWorkTicket(action.removedValue?.value);
                break;
            default:
            // no-op
        }
    }, [groupWorkTicket, ungroupWorkTicket]);

    return <>
        <AsideHeader>
            <div>
                <div>{'Group Work'}</div>
                <h6>{workTicket.work_ticket_number}</h6>
            </div>
        </AsideHeader>
        <Form.Group>
            <Form.Text>{'Work Number'}</Form.Text>
            <Row className={'align-items-center'}>
                <Col>
                    <AsyncFormSelect
                        cacheOptions={false}
                        defaultOptions={false}
                        isClearable={false}
                        isMulti={true}
                        filterOption={({value}) => value !== workTicketId}
                        isDisabled={loading}
                        value={_.map(groupedWorkTickets, (workTicket) => ({
                            label: workTicket.work_ticket_number,
                            value: workTicket.work_ticket_id,
                        }))}
                        loadOptions={searchWorkTickets}
                        onChange={onChange}
                        noOptionsMessage={({inputValue}) => inputValue
                            ? `Work number "${inputValue}" not found`
                            : 'Enter Work number'}
                    />
                </Col>
                <Col xs={1} className={'text-end me-3'}>
                    {loading && <Spinner size={'sm'}/>}
                </Col>
            </Row>
        </Form.Group>
    </>;
};

export default WorkTicketGroupAside;
