import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import backIcon from '^assets/images/back.svg';
import {useNavigate} from 'react-router-dom';

export const PageHeader = ({
    title,
    actionButtons,
    children,
}) => {
    const navigate = useNavigate();
    return <Container fluid className={'mt-3'}>
        <Row>
            <Col className={'d-flex align-items-center'}>
                {title !== 'Home' && <img
                    className={'me-2'}
                    role={'button'}
                    src={backIcon}
                    width={'30px'}
                    height={'30px'}
                    alt={'Back'}
                    onClick={() => navigate(-1)}
                />}
                <h1 className={'text-primary m-0'}>{title}</h1>
            </Col>
            {actionButtons && <Col
                xs={4}
                className={'text-end align-self-center'}
            >
                {actionButtons}
            </Col>}
        </Row>
        <Row>
            <Col>
                {children}
            </Col>
        </Row>
    </Container>;
};
