import React, {useMemo} from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import {Popover, OverlayTrigger} from 'react-bootstrap';

const IndicatorCircle = styled.div.attrs(() => ({
    className: _.join([
        'align-middle',
        'd-inline-flex',
        'align-items-center',
        'justify-content-center',
        'text-light',
        'fw-bold',
    ], ' '),
}))`
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 50%;
    background-color: ${({color}) => color};
    font-size: 0.8rem;
`;

const Indicator = React.forwardRef(({
    status,
    ...rest
}, ref) => {
    const [color, text] = useMemo(() => {
        switch (status) {
            case 'ERROR':
                return ['var(--bs-danger)', null];
            case 'WARNING':
                return ['var(--bs-warning)', null];
            case 'NORMAL':
                return ['var(--bs-green)', null];
            default:
                return ['var(--bs-gray-500)', 'NA'];
        }
    }, [status]);

    return <IndicatorCircle
        ref={ref}
        color={color}
        {...rest}
    >
        {text}
    </IndicatorCircle>;
});

const WorkTicketDurationIndicator = ({
    workTicket,
}) => {
    const targetMetrics = workTicket?.target_metrics;
    const durationLimitStatus = targetMetrics?.duration_limit_status;
    const shipDateStatus = targetMetrics?.ship_date_status;
    const overallStatus = targetMetrics?.overall_status;

    return <OverlayTrigger
        trigger={'click'}
        rootClose={true}
        overlay={<Popover>
            <Popover.Body className={'text-primary'}>
                <div className={'mb-2'}>
                    <Indicator status={durationLimitStatus}/>
                    <span className={'ms-2'}>
                        {'Work Duration'}
                    </span>
                </div>
                <div>
                    <Indicator status={shipDateStatus}/>
                    <span className={'ms-2'}>
                        {'Ship Date'}
                    </span>
                </div>
            </Popover.Body>
        </Popover>}
    >
        <Indicator role={'button'} status={overallStatus}/>
    </OverlayTrigger>;
};

export default WorkTicketDurationIndicator;
