import React from 'react';
import _ from 'lodash';
import {Col, Form, Row} from 'react-bootstrap';
import {FormSelect} from '^common/formSelect';

const currencyOptions = _.map(
    Intl.supportedValuesOf('currency'),
    (currencyCode) => ({
        label: currencyCode,
        value: currencyCode,
    }),
);

const CostInput = ({
    value,
    onChange,
    disabled,
}) => {
    return <Row>
        <Col>
            <Form.Control
                disabled={disabled}
                type={'number'}
                min={0}
                step={'any'}
                value={value?.value ?? ''}
                onChange={(e) => {
                    onChange({
                        ...value,
                        value: e.target?.value
                            ? _.toFinite(e.target?.value)
                            : null,
                    });
                }}
            />
        </Col>
        <Col>
            <FormSelect
                isDisabled={disabled}
                value={_.find(currencyOptions, {
                    value: value?.currency,
                }) ?? null}
                options={currencyOptions}
                onChange={(e) => {
                    onChange({
                        ...value,
                        currency: e?.value ?? null,
                    });
                }}
            />
        </Col>
    </Row>;
};

export default CostInput;
