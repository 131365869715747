import CustomCard from '^common/customCard';
import {Card} from 'react-bootstrap';
import React from 'react';

const EmptyCard = ({description}) => {
    return <CustomCard>
        <Card.Body>
            <div>
                {description}
            </div>
        </Card.Body>
    </CustomCard>;
};

export default EmptyCard;
