import React, {useCallback, useState} from 'react';
import styled from 'styled-components';
import checkMarkGreenIcon from '^assets/images/checkMarkGreen.svg';
import clipboardIcon from '^assets/images/clipboard.svg';
import crossRedIcon from '^assets/images/crossRed.svg';

const ClipboardIcon = styled.img.attrs(({className}) => ({
    className: `${className} position-absolute`,
}))`
  top: 0.25rem;

  height: 0.8rem;
  width: 0.8rem;

  transition: opacity 0.5s ease-out;

  &.hidden {
    opacity: 0;
  }
`;

export const ClipboardText = ({
    value,
}) => {
    const [icon, setIcon] = useState('Copy');

    const copyToClipboard = useCallback(async () => {
        try {
            await navigator.clipboard.writeText(value);
            setIcon('Success');
        } catch (e) {
            setIcon('Error');
        } finally {
            setTimeout(() => {
                setIcon('Copy');
            }, 3000);
        }
    }, [value]);

    return <div className={'d-inline position-relative ms-1'}>
        <ClipboardIcon
            className={icon === 'Success' ? '' : 'hidden'}
            alt={'Success'}
            src={checkMarkGreenIcon}
        />
        <ClipboardIcon
            className={icon === 'Error' ? '' : 'hidden'}
            alt={'Error'}
            src={crossRedIcon}
        />
        <ClipboardIcon
            className={icon === 'Copy' ? '' : 'hidden'}
            alt={'Copy'}
            src={clipboardIcon}
            role={icon === 'Copy' ? 'button' : ''}
            onClick={icon === 'Copy' ? copyToClipboard : null}
        />
    </div>;
};
