import React, {useState} from 'react';
import _ from 'lodash';
import CustomCard from '^common/customCard';
import {Button, Card} from 'react-bootstrap';
import {handleSelectChange}
    from '^pages/workTicket/utilities/kittingAssemblyHelper';

const NonSerializedCard = ({
    combined,
    label,
    partConfig,
    formData,
    setFormData,
    units,
    handleSubmit,
}) => {
    const [submitted, setSubmitted] = useState(false);

    const type = combined ? 'combined' : 'available';
    return <>
        {/* eslint-disable max-len */}
        {units?.length > 0 && (
            <CustomCard className={'mt-1'}>
                <Card.Body>
                    <div>
                        <b>{label}</b>
                        {`: ${units.length} unit(s)`}
                    </div>
                    <div>
                        {`Desc: ${units[0]?.config?.part?.description}`}
                    </div>
                    <div>
                        {`${units[0]?.stage}`
                            + ` | ${units[0]?.resource?.label ?? 'N/A'}`}
                    </div>
                    <select
                        className={'form-select'}
                        value={formData[type]?.non_serialized[partConfig]?.false[label]?.quantity}
                        onChange={(e) =>
                            handleSelectChange(
                                combined,
                                partConfig,
                                label,
                                e.target.value,
                                setFormData,
                            )}
                    >
                        <option
                            key={'none'}
                            value={'none'}
                            select disabled hidden
                        >Select a Quantity</option>
                        {_.range(units.length + 1).map((index) => (
                            <option key={index} value={index}>{index}</option>
                        ))}
                    </select>
                    <Button
                        className={'position-absolute top-50 end-0 translate-middle-y me-3'}
                        size={'sm'}
                        variant={combined ? 'danger' : 'success'}
                        disabled={formData[type]?.non_serialized[partConfig]?.false[label]?.quantity === '0'
                            || submitted}
                        onClick={() => {
                            setSubmitted(true);
                            const unitIds = _.take(
                                formData[type]?.non_serialized[partConfig]?.false[label]?.unitIds,
                                formData[type]?.non_serialized[partConfig]?.false[label]?.quantity,
                            );
                            handleSubmit(!combined, unitIds, true);
                        }}
                    >{combined ? 'Remove' : 'Add'}</Button>
                </Card.Body>
            </CustomCard>
        )}
    </>;
};

export default NonSerializedCard;
