import React, {useContext, useEffect, useState} from 'react';
import _ from 'lodash';
import {toast} from 'react-toastify';
import {ApiContext} from '^contexts/api';
import {isResponseError} from '^utilities/isResponseError';
import {Row, Spinner} from 'react-bootstrap';
import {CSVLink} from 'react-csv';
import exportIcon from '^assets/images/export.svg';
import {PageActionIcon} from '^common/pageAction';
import AssetBarcode from '../common/assetBarcode';
import BaseCol from '../common/baseCol';
import BaseRow from '../common/baseRow';
import {UnitIcon} from '../common/icon';
import {buildAssembly, buildUnitsCsv} from '../common/util';
import UnitRow from './unitRow';

const KitResult = ({
    kit,
    setActionButtons,
    showAssetDetails,
}) => {
    const api = useContext(ApiContext);
    const [units, setUnits] = useState([]);
    const [loading, setLoading] = useState(true);

    const kitId = kit?.kit_id;

    useEffect(() => {
        const getUnits = async () => {
            setLoading(true);
            let nextOffset = null;
            setUnits([]);

            do {
                const response = await api.get('/units', {
                    params: {
                        kit_id: kitId,
                        offset: nextOffset,
                    },
                });

                if (isResponseError(response)) {
                    toast.error(response?.data?.error);
                    nextOffset = null;
                    break;
                }

                setUnits((prevState) => _.uniqBy(
                    [...prevState, ...response.data.results],
                    'unit_id',
                ));

                nextOffset = response.data?.next_offset;
            } while (nextOffset);

            setLoading(false);
        };

        if (!api) {
            return;
        }

        if (!kitId) {
            return;
        }

        getUnits();
    }, [api, kitId]);

    const kitUnits = _.filter(
        units,
        (unit) => unit.kit?.kit_id === kit.kit_id,
    );

    const assemblyParents = _.chain(kitUnits)
        .filter((unit) => unit.has_assembly_components)
        .filter((unit) => !unit.assembled_into_unit_id)
        .value();

    const kitAssemblies = _.map(
        assemblyParents,
        (parent) => buildAssembly(parent, units),
    );

    const standaloneUnits = _.differenceBy(
        kitUnits,
        _.flatten(kitAssemblies),
        'unit_id',
    );

    useEffect(() => {
        const csvExport = buildUnitsCsv(units);

        setActionButtons(<CSVLink
            data={csvExport}
            filename={`${kit?.label}.csv`}>
            <PageActionIcon
                src={exportIcon}
                alt={'Export'}
                onClick={loading ? null : _.noop}
            />
        </CSVLink>);
    }, [
        kit?.label,
        loading,
        setActionButtons,
        units,
    ]);

    if (loading) {
        return <Row className={'justify-content-center'}>
            <Spinner/>
        </Row>;
    }

    return <Row>
        <BaseRow $first={true} $bg={'secondary'}>
            <BaseCol xs={1}/>
            <BaseCol className={'text-primary'}>
                {`Label: ${kit.label}`}
            </BaseCol>
            <BaseCol>
                <AssetBarcode value={kit.label}/>
            </BaseCol>
            <BaseCol xs={1}>
                <UnitIcon/>
                <span>{_.size(kitUnits)}</span>
            </BaseCol>
        </BaseRow>
        {_.map(kitAssemblies, (assembly) =>
            _.map(assembly, (unit) => <UnitRow
                key={unit.unit_id}
                unit={unit}
                showAssetDetails={showAssetDetails}
            />),
        )}
        {_.map(standaloneUnits, (unit) => <UnitRow
            key={unit.unit_id}
            unit={unit}
            showAssetDetails={showAssetDetails}
        />)}
    </Row>;
};

export default KitResult;
