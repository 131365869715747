import _ from 'lodash';

export const buildAssembly = (parent, allUnits) => {
    const parentClone = _.cloneDeep(parent);

    if (!parentClone?.assembly_level) {
        _.set(parentClone, 'assembly_level', 1);
    }

    let retVal = [parentClone];

    const directChildren = _.filter(
        allUnits,
        (unit) => parentClone?.unit_id === unit.assembled_into_unit_id,
    );

    _.forEach(directChildren, (child) => {
        _.set(child, 'assembly_level', parentClone?.assembly_level + 1);
        retVal = retVal.concat(buildAssembly(child, allUnits));
    });

    return retVal;
};

export const buildUnitsCsv = (units) => _.map(units, (unit) => ({
    'P/N': unit?.sku ?? '',
    'S/N': unit.serial_number ?? '',
    ..._.pickBy(unit.asset_capture),
}));
