import React,
{useCallback,
    useContext,
    useState} from 'react';
import {ApiContext} from '^contexts/api';
import {isResponseError} from '^utilities/isResponseError';
import {Button,
    Modal} from 'react-bootstrap';
import {toast} from 'react-toastify';

const DeleteStagedWorkTicketModal = ({
    deleteStagedWorkTicket,
    setDeleteStagedWorkTicket,
    onDelete,
}) => {
    const api = useContext(ApiContext);
    const [loading,
        setLoading] = useState(false);

    const stagedWorkTicketId = deleteStagedWorkTicket?.staged_work_ticket_id;
    const stagedWorkTicketNumber = deleteStagedWorkTicket?.work_ticket_number;

    const doDelete = useCallback(async () => {
        setLoading(true);

        const response = await api.delete(
            `/staged-work-tickets/${stagedWorkTicketId}`,
        );

        setLoading(false);

        if (isResponseError(response)) {
            toast.error(response?.data?.error);
            return;
        }

        onDelete(deleteStagedWorkTicket);
        setDeleteStagedWorkTicket(null);
    },
    [
        api,
        deleteStagedWorkTicket,
        onDelete,
        setDeleteStagedWorkTicket,
        stagedWorkTicketId,
    ]);

    return <Modal
        show={!!deleteStagedWorkTicket}
        onHide={() => setDeleteStagedWorkTicket(null)}
    >
        <Modal.Header closeButton={true}>
            <Modal.Title>{'Delete Staged Work'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className={'mb-3'}>
                {`Are you sure you want to delete "${stagedWorkTicketNumber}"?`}
                <br/>
                {`This cannot be undone.`}
            </div>
            <div>
                <Button
                    disabled={loading}
                    className={'w-100'}
                    variant={'danger'}
                    type={'submit'}
                    onClick={() => doDelete()}
                >
                    {'Delete'}
                </Button>
            </div>
        </Modal.Body>
    </Modal>;
};

export default DeleteStagedWorkTicketModal;
