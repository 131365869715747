import React, {useContext, useState, useEffect} from 'react';
import _ from 'lodash';
import {ApiContext} from '^contexts/api';
import {AppContext} from '^contexts/app';
import {isResponseError} from '^utilities/isResponseError';
import AsideHeader from '^common/asideHeader';
import {FormSelect} from '^common/formSelect';
import {Button, Form} from 'react-bootstrap';
import defaultFilters from './defaultFilters';

const statusToOption = (status) => ({
    label: status,
    value: status,
});

export const INTERVAL_STATUS_OPTIONS = _.map([
    ...defaultFilters.interval_status,
    'Work-Complete',
], statusToOption);

const TOTAL_STATUS_OPTIONS = _.map([
    ...defaultFilters.total_status,
    'On-Hold',
], statusToOption);

const Filter = ({
    filterParams,
    setFilterParams,
}) => {
    const api = useContext(ApiContext);
    const {setAsideChildren} = useContext(AppContext);

    const [
        intervalStatus,
        setIntervalStatus,
    ] = useState(filterParams?.interval_status ?? null);

    const [
        totalStatus,
        setTotalStatus,
    ] = useState(filterParams?.total_status ?? null);

    const [
        pool,
        setPool,
    ] = useState(filterParams?.pool ?? null);

    const [pools, setPools] = useState([]);

    useEffect(() => {
        const fetchPools = async () => {
            const response = await api.get('/types/pool');

            if (isResponseError(response)) {
                return;
            }

            setPools(response?.data);
        };

        if (api) {
            fetchPools();
        }
    }, [api]);

    const resetFields = () => {
        setIntervalStatus(defaultFilters?.interval_status ?? null);
        setTotalStatus(defaultFilters?.total_status ?? null);
        setPool(null);
    };

    const formSubmit = () => {
        setFilterParams({
            ..._.size(intervalStatus) ? {interval_status: intervalStatus} : {},
            ..._.size(totalStatus) ? {total_status: totalStatus} : {},
            ..._.size(pool) ? {pool} : {},
        });

        setAsideChildren(null);
    };

    const poolOptions = _.map(pools, (pool) => ({
        label: pool ?? 'N/A',
        value: pool,
    }));

    return <>
        <AsideHeader>
            <b>{'Filter Work'}</b>
        </AsideHeader>
        <Form onSubmit={(event) => {
            event.preventDefault();
            formSubmit();
        }}>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Done Status'}</Form.Text>
                <FormSelect
                    isMulti={true}
                    isClearable={true}
                    backspaceRemovesValue={true}
                    value={_.filter(
                        INTERVAL_STATUS_OPTIONS,
                        ({value}) => _.includes(intervalStatus, value),
                    )}
                    options={INTERVAL_STATUS_OPTIONS}
                    onChange={(e) => setIntervalStatus(_.map(e, 'value'))}
                />
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'To-Do Status'}</Form.Text>
                <FormSelect
                    isMulti={true}
                    isClearable={true}
                    backspaceRemovesValue={true}
                    value={_.filter(
                        TOTAL_STATUS_OPTIONS,
                        ({value}) => _.includes(totalStatus, value),
                    )}
                    options={TOTAL_STATUS_OPTIONS}
                    onChange={(e) => setTotalStatus(_.map(e, 'value'))}
                />
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Pool'}</Form.Text>
                <FormSelect
                    isMulti={true}
                    isClearable={true}
                    backspaceRemovesValue={true}
                    value={_.filter(
                        poolOptions,
                        ({value}) => _.includes(pool, value),
                    )}
                    options={poolOptions}
                    onChange={(e) => setPool(_.map(e, 'value'))}
                />
            </Form.Group>
            <Button
                className={'w-100 mt-3'}
                type={'button'}
                onClick={() => resetFields()}
            >{'Reset Filter'}</Button>
            <Button
                className={'w-100 my-3'}
                variant={'success'}
                type={'submit'}
            >{'Submit'}</Button>
        </Form>
    </>;
};

export default Filter;
