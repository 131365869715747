import React, {useContext, useEffect, useState} from 'react';
import _ from 'lodash';
import {toast} from 'react-toastify';
import {CSVLink} from 'react-csv';
import {ApiContext} from '^contexts/api';
import {isResponseError} from '^utilities/isResponseError';
import {Row, Spinner} from 'react-bootstrap';
import {PageActionIcon} from '^common/pageAction';
import {buildAssembly, buildUnitsCsv} from '../common/util';
import UnitRow from './unitRow';
import exportIcon from '^assets/images/export.svg';

const UnitResult = ({
    unit,
    setActionButtons,
    showAssetDetails,
}) => {
    const api = useContext(ApiContext);
    const [units, setUnits] = useState([]);
    const [loading, setLoading] = useState(true);

    const unitId = unit?.unit_id;

    const isAssembly = unit?.assembled_into_unit_id
        || unit?.has_assembly_components;

    const assemblyParent = _.find(units, {assembled_into_unit_id: null});
    const assembly = buildAssembly(assemblyParent, units);

    useEffect(() => {
        const getAssembly = async () => {
            setLoading(true);
            let nextParent = unit?.assembled_into_unit_id;
            let parentUnitId = unitId;
            setUnits([]);

            while (nextParent) {
                const response = await api.get(`/units/${nextParent}`);

                if (isResponseError(response)) {
                    toast.error(response?.data?.error);
                    nextParent = null;
                    break;
                }

                parentUnitId = response?.data?.unit_id;
                nextParent = response?.data?.assembled_into_unit_id;
            }

            const response = await api.get(`/units/${parentUnitId}/assembly`);

            if (isResponseError(response)) {
                toast.error(response?.data?.error);
                return;
            }

            setUnits(response.data);
            setLoading(false);
        };

        if (!api) {
            return;
        }

        if (!unitId) {
            return;
        }

        if (!isAssembly) {
            return;
        }

        getAssembly();
    }, [api, isAssembly, unit?.assembled_into_unit_id, unitId]);

    useEffect(() => {
        if (!isAssembly) {
            setLoading(false);
            setUnits([unit]);
        }
    }, [isAssembly, unit]);

    useEffect(() => {
        const csvExport = buildUnitsCsv(units);

        const serialNumber = assemblyParent?.serial_number
            ?? unit.serial_number;

        setActionButtons(<CSVLink
            data={csvExport}
            filename={`${serialNumber}.csv`}>
            <PageActionIcon
                src={exportIcon}
                alt={'Export'}
                onClick={loading ? null : _.noop}
            />
        </CSVLink>);
    }, [
        assemblyParent?.serial_number,
        loading,
        setActionButtons,
        unit.serial_number,
        units,
    ]);

    if (loading) {
        return <Row className={'justify-content-center'}>
            <Spinner/>
        </Row>;
    }

    if (!isAssembly) {
        return <UnitRow
            first={true}
            unit={unit}
            showAssetDetails={showAssetDetails}
        />;
    }

    return <Row className={'justify-content-center'}>
        {_.map(assembly, (unit, idx) => <UnitRow
            key={unit.unit_id}
            first={idx === 0}
            bg={idx === 0 ? 'secondary' : 'white'}
            unit={unit}
            count={idx === 0 ? _.size(assembly) : 0}
            showAssetDetails={showAssetDetails}
        />)}
    </Row>;
};

export default UnitResult;
