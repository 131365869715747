import React, {useCallback, useContext, useEffect, useState} from 'react';
import {isResponseError} from '^utilities/isResponseError';
import {ApiContext} from '^contexts/api';
import {AppContext} from '^contexts/app';
import AsideHeader from '^common/asideHeader';
import CostInput from '^common/costInput';
import DurationInput from '^common/durationInput';
import {Button, Form} from 'react-bootstrap';
import {toast} from 'react-toastify';

const ConfigEdit = ({
    config,
    updateConfig,
}) => {
    const api = useContext(ApiContext);
    const {setAsideChildren} = useContext(AppContext);
    const [processing, setProcessing] = useState(false);

    const [barcode, setBarcode] = useState('');
    const [workDurationLimit, setWorkDurationLimit] = useState(null);
    const [unitDurationLimit, setUnitDurationLimit] = useState(null);
    const [unitDurationCost, setUnitDurationCost] = useState(null);

    const configId = config?.config_id;

    useEffect(() => {
        setBarcode(config?.barcode ?? '');
        setWorkDurationLimit(config?.work_ticket_duration_limit ?? null);
        setUnitDurationLimit(config?.unit_duration_limit ?? null);
        setUnitDurationCost(config?.unit_duration_cost ?? null);
    }, [
        config?.barcode,
        config?.unit_duration_cost,
        config?.unit_duration_limit,
        config?.work_ticket_duration_limit,
    ]);

    const editConfig = useCallback(async () => {
        setProcessing(true);

        const response = await api.patch(`/configs/${configId}`, {
            barcode: barcode,
            work_ticket_duration_limit: workDurationLimit,
            unit_duration_limit: unitDurationLimit,
            unit_duration_cost: unitDurationCost,
        });

        setProcessing(false);

        if (isResponseError(response)) {
            toast.error(response?.data?.error);
            return;
        }

        updateConfig(response?.data);
        setAsideChildren(null);
    }, [
        api,
        barcode,
        configId,
        setAsideChildren,
        unitDurationCost,
        unitDurationLimit,
        updateConfig,
        workDurationLimit,
    ]);

    return <>
        <AsideHeader>
            {`Edit Config "${config?.label ?? ''}"`}
        </AsideHeader>
        <Form onSubmit={(event) => {
            event.preventDefault();
            editConfig();
        }}>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Barcode (if not SKU)'}</Form.Text>
                <Form.Control
                    disabled={processing}
                    type={'text'}
                    value={barcode}
                    onChange={(e) => setBarcode(e.target.value)}
                />
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Work Duration Limit'}</Form.Text>
                <DurationInput
                    disabled={processing}
                    value={workDurationLimit}
                    onChange={setWorkDurationLimit}
                />
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Unit Duration Limit'}</Form.Text>
                <DurationInput
                    disabled={processing}
                    value={unitDurationLimit}
                    onChange={setUnitDurationLimit}
                />
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Unit Duration Cost'}</Form.Text>
                <CostInput
                    disabled={processing}
                    value={unitDurationCost}
                    onChange={setUnitDurationCost}
                />
            </Form.Group>
            <Button
                className={'w-100 my-3'}
                variant={'success'}
                disabled={processing}
                type={'submit'}
            >
                {'Submit'}
            </Button>
        </Form>
    </>;
};

export default ConfigEdit;
