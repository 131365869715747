import React, {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import styled from 'styled-components';
import {useLocalStorage} from 'usehooks-ts';
import _ from 'lodash';
import {toast} from 'react-toastify';
import {captureFields} from '^config/captureFields';
import {ApiContext} from '^contexts/api';
import {isResponseError} from '^utilities/isResponseError';
import {
    Col,
    Container,
    Form,
    Row,
    Spinner,
    Table,
} from 'react-bootstrap';
import {FormSelect} from '^common/formSelect';
import {FixedWidthTd, RowNumTd} from './common/td';
import WorkTicketResultRow from './workTicketResultRow';

const TransparentTable = styled(Table).attrs(() => ({
    responsive: true,
}))`
    --bs-table-bg: transparent;
`;

const ALLOWED_STATUSES = ['In-Lab'];

const WorkTicketResult = ({
    workTicket,
    stages,
    statuses,
}) => {
    const api = useContext(ApiContext);

    const [fieldOrder] = useLocalStorage(
        'asset_capture_preference',
        _.keys(captureFields),
    );

    const tableBodyRef = useRef(null);

    const [status, setStatus] = useState(null);
    const [statusSpinner, setStatusSpinner] = useState(false);
    const [bom, setBom] = useState({});
    const [rowData, setRowData] = useState([{}]);

    const workTicketId = workTicket?.work_ticket_id;

    useEffect(() => {
        setStatus(workTicket?.status);
    }, [workTicket?.status]);

    useEffect(() => {
        // focus first input on initial render
        tableBodyRef
            ?.current
            ?.querySelector('input')
            ?.focus?.();
    }, []);

    const updateStatus = useCallback(async (e) => {
        setStatusSpinner(true);

        const response = await api.patch(
            `/work-tickets/${workTicketId}`,
            {status: e?.value},
        );

        setStatusSpinner(false);

        if (isResponseError(response)) {
            toast.error(response?.data?.error);
            return;
        }

        setStatus(response.data?.status);
    }, [api, workTicketId]);

    useEffect(() => {
        const fetchBom = async () => {
            const response = await api.get(`/work-tickets/${workTicketId}/bom`);

            if (isResponseError(response)) {
                return;
            }

            setBom(response?.data);
        };

        if (api) {
            fetchBom();
        }
    }, [api, workTicketId]);

    const findExistingRow = useCallback((rowIdx, sku, serialNumber) => {
        const existingIndex = _.findIndex(rowData, (row, idx) => {
            if (rowIdx === idx) {
                return false;
            }

            const caseMatch = (a, b) => _.upperCase(a) === _.upperCase(b);
            const skuMatch = caseMatch(row?.sku, sku);
            const serialMatch = caseMatch(row?.serial_number, serialNumber);
            return skuMatch && serialMatch;
        });

        setRowData((prevState) => _.set(
            prevState,
            `[${existingIndex}].flash`,
            true,
        ));

        return existingIndex > -1;
    }, [rowData]);

    // Append empty row
    useEffect(() => {
        const lastRow = _.last(rowData);

        if (lastRow?.asset_capture) {
            setRowData((prevState) => [...prevState, {}]);
        }
    }, [rowData]);

    // Remove empty row if not last row
    useEffect(() => {
        const lastIdx = _.size(rowData) - 1;

        _.forEach(rowData, (row, idx) => {
            const allFalsy = _.every(row, (val) => !val);
            const isLast = idx === lastIdx;

            if (allFalsy && !isLast) {
                setRowData((prevState) => prevState.toSpliced(idx, 1));
                return false;
            }
        });
    }, [rowData]);

    const statusOptions = _.chain(statuses)
        .intersection([workTicket?.status, ...ALLOWED_STATUSES])
        .map((status) => ({
            label: status,
            value: status,
        }))
        .value();

    return <>
        <Container fluid={true} className={'my-3'}>
            <Row className={'justify-content-center'}>
                <Col xs={11} md={3}>
                    <Form.Group>
                        <Form.Text>{'Work Status'}</Form.Text>
                        <FormSelect
                            isDisabled={statusSpinner}
                            value={_.find(statusOptions, {value: status})}
                            options={statusOptions}
                            onChange={updateStatus}
                        />
                    </Form.Group>
                </Col>
                {statusSpinner && <Col xs={1}>
                    <Spinner className={'mt-4'}/>
                </Col>}
            </Row>
        </Container>
        <TransparentTable>
            <thead>
                <tr className={'fw-bold border-bottom border-dark'}>
                    <RowNumTd/>
                    <FixedWidthTd>
                        {'SKU'}
                    </FixedWidthTd>
                    <FixedWidthTd>
                        {'S/N'}
                    </FixedWidthTd>
                    {_.map(fieldOrder, (field) =>
                        <FixedWidthTd key={field}>
                            {captureFields[field]}
                        </FixedWidthTd>)}
                </tr>
            </thead>
            <tbody ref={tableBodyRef}>
                {_.map(rowData, (data, idx) => {
                    return <WorkTicketResultRow
                        key={idx}
                        rowIdx={idx}
                        workTicketId={workTicketId}
                        stages={stages}
                        bom={bom}
                        findExistingRow={findExistingRow}
                        data={data}
                        setData={(key, val) => setRowData((prevState) => [
                            ..._.set(
                                prevState,
                                `[${idx}].${key}`,
                                val,
                            ),
                        ])}
                    />;
                })}
            </tbody>
        </TransparentTable>
    </>;
};

export default WorkTicketResult;
