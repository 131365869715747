import React, {
    createRef,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import styled from 'styled-components';
import {useLocalStorage} from 'usehooks-ts';
import _ from 'lodash';
import {captureFields} from '^config/captureFields';
import arrowUp from '^assets/images/arrowUp.svg';
import arrowDown from '^assets/images/arrowDown.svg';
import {Col, Form, Row} from 'react-bootstrap';

const SmallTextButton = styled.div.attrs(() => ({
    className: 'text-primary',
    role: 'button',
}))`
  margin-top: 0.5rem;
  font-size: 0.8rem;
`;

const ReorderButton = styled.img.attrs(() => ({
    role: 'button',
}))`
  width: 1.5rem;
  cursor: ${({$disabled}) => $disabled && 'not-allowed'};
  filter: ${({$disabled}) => $disabled && 'grayscale(1) brightness(2)'};
`;

export const UnitAssetCapture = React.forwardRef(function UnitAssetCapture({
    assetCapture,
    setAssetCaptureField,
    onLastBlur,
}, ref) {
    const inputRefs = useRef({});
    const disabled = _.isEmpty(assetCapture);

    const [
        assetCaptureData,
        setAssetCaptureData,
    ] = useState(assetCapture ?? {});

    const [fieldOrder, setFieldOrder] = useLocalStorage(
        'asset_capture_preference',
        _.keys(captureFields),
    );

    useEffect(() => {
        setAssetCaptureData(assetCapture ?? {});
    }, [assetCapture]);

    useEffect(() => {
        if (disabled) {
            return;
        }

        ref?.current?.querySelector?.('input')?.focus?.();
    }, [disabled, ref]);

    const handleOnChange = useCallback((field, value) => {
        setAssetCaptureData((assetCaptureData) => ({
            ...assetCaptureData,
            [field]: value,
        }));
    }, []);

    const handleKeyDown = useCallback((event, field) => {
        if (event.key !== 'Enter') {
            return;
        }

        event.preventDefault();
        const index = _.indexOf(fieldOrder, field);
        const next = fieldOrder[index + 1];

        if (next) {
            inputRefs?.current?.[next]?.current?.focus?.();
            return;
        }

        event.target?.blur?.();
    }, [fieldOrder, inputRefs]);

    const handleOnBlur = useCallback((event, field) => {
        const oldVal = assetCapture?.[field];
        const newVal = assetCaptureData?.[field];

        if (newVal !== oldVal) {
            setAssetCaptureField(field, newVal);
        }

        if (field === _.last(fieldOrder)) {
            onLastBlur(event);
        }
    }, [
        assetCapture,
        assetCaptureData,
        fieldOrder,
        onLastBlur,
        setAssetCaptureField,
    ]);

    const reorderField = useCallback((field, up) => {
        const fieldIndex = _.indexOf(fieldOrder, field);
        const reorderIdx = up ? fieldIndex - 1 : fieldIndex + 1;

        setFieldOrder(_.chain(fieldOrder)
            .without(field)
            .thru((fields) => {
                fields.splice(reorderIdx, 0, field);
                return fields;
            })
            .value());
    }, [fieldOrder, setFieldOrder]);

    const visibleAssetCaptureData = _.chain(fieldOrder)
        .map((field) => [field, assetCaptureData[field]])
        .fromPairs()
        .value();

    return <div ref={ref}>
        <Row className={'text-primary border-bottom'}>
            <Col className={'lead'}>
                {'Asset Data Fields'}
            </Col>
            <Col className={'d-flex justify-content-end'}>
                <SmallTextButton onClick={() => setFieldOrder([])}>
                    {'Hide All'}
                </SmallTextButton>
                <div className={'mx-1 mt-1'}>{'|'}</div>
                <SmallTextButton
                    onClick={() => setFieldOrder(_.keys(captureFields))}
                >
                    {'Reset'}
                </SmallTextButton>
            </Col>
        </Row>
        {_.map(visibleAssetCaptureData, (val, field) => {
            const inputRef = createRef();
            inputRefs.current[field] = inputRef;

            return <Row key={field}>
                <Col xs={9} className={'pe-1'}>
                    <Form.Group className={'mb-2'}>
                        <Form.Text>{captureFields[field]}</Form.Text>
                        <Form.Control
                            ref={inputRef}
                            type={'text'}
                            disabled={disabled}
                            value={assetCaptureData[field] ?? ''}
                            onChange={(e) => handleOnChange(
                                field,
                                e.target.value,
                            )}
                            onKeyDown={(e) => handleKeyDown(e, field)}
                            onBlur={(e) => handleOnBlur(e, field)}
                        />
                    </Form.Group>
                </Col>
                <Col xs={1} className={'mt-3 p-0 text-center lh-sm'}>
                    <div>
                        <ReorderButton
                            src={arrowUp}
                            $disabled={field === _.head(fieldOrder)}
                            onClick={() => reorderField(field, true)}
                        />
                    </div>
                    <div>
                        <ReorderButton
                            src={arrowDown}
                            $disabled={field === _.last(fieldOrder)}
                            onClick={() => reorderField(field, false)}
                        />
                    </div>
                </Col>
                <Col xs={2} className={'mt-4 ps-1 h-100'}>
                    <SmallTextButton onClick={() => setFieldOrder(
                        _.without(fieldOrder, field),
                    )}>
                        {'Hide'}
                    </SmallTextButton>
                </Col>
            </Row>;
        })}
    </div>;
});

export default UnitAssetCapture;
