import React, {createContext, useCallback, useState} from 'react';

export const AppContext = createContext(null);

export const AppContextProvider = ({children}) => {
    const [leftNavOpen, setLeftNavOpen] = useState(false);
    const [asideChildren, setAsideChildren] = useState(null);
    const [showScrollButtons, setShowScrollButtons] = useState(false);

    const setLeftNavOpenCallback = useCallback((val) => {
        setLeftNavOpen(val);

        if (val) {
            // Close the aside when opening the nav
            setAsideChildren(null);
        }
    }, []);

    const setAsideChildrenCallback = useCallback((val) => {
        setAsideChildren(val);

        if (val) {
            // Close the left nave when opening the aside
            setLeftNavOpen(false);
        }
    }, []);

    return <AppContext.Provider value={{
        leftNavOpen,
        setLeftNavOpen: setLeftNavOpenCallback,
        asideChildren,
        setAsideChildren: setAsideChildrenCallback,
        showScrollButtons,
        setShowScrollButtons,
    }}>
        {children}
    </AppContext.Provider>;
};
