import React, {useCallback, useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import _ from 'lodash';
import {toast} from 'react-toastify';
import {Form, OverlayTrigger, Popover} from 'react-bootstrap';
import {isResponseError} from '^utilities/isResponseError';
import {ApiContext} from '^contexts/api';
import {AsyncFormSelect, FormSelect} from '^common/formSelect';
import PackingBaseCol from '^pages/packing/common/baseCol';
import PackingBaseRow from '^pages/packing/common/baseRow';
import {
    ArrowRight,
    ExternalLinkIcon,
    UnitIcon,
    WarningIcon,
} from '^pages/packing/common/icon';
import UnitAssetDetails from './unitAssetDetails';

const BaseCol = styled(PackingBaseCol)``;

const BaseRow = styled(PackingBaseRow)`
    row-gap: revert;
`;

const resourceToOption = (resource) => ({
    label: resource?.label,
    value: resource?.resource_id,
});

const UnitRow = ({
    unit,
    workTicketId,
    assembly,
    updateUnit,
    isStageEditable,
    isResourceEditable,
    showAssetDetails,
    stages,
    defaultResources,
    searchResources,
    count = 0,
    bg = 'white',
    first = false,
}) => {
    const api = useContext(ApiContext);

    const [processing, setProcessing] = useState(false);
    const [stage, setStage] = useState('');
    const [resource, setResource] = useState('');

    const serialized = unit?.serialized ?? true;

    const serial = serialized
        ? unit?.serial_number ?? 'NOT-CAPTURED'
        : null;

    const sku = unit?.sku ?? '';
    const desc = unit?.config?.part?.description;

    useEffect(() => {
        if (unit) {
            setStage(unit?.stage);
            setResource(unit?.resource);
        }
    }, [unit]);

    const patchStage = useCallback(async (oldStage, newStage) => {
        setProcessing(true);

        const response = await api.patch(`/units/${unit?.unit_id}`, {
            stage: newStage,
        });

        setProcessing(false);

        if (isResponseError(response)) {
            toast.error(response?.data?.error);
            setStage(oldStage);
            return;
        }

        _.forEach(assembly ?? [unit], (unit) => updateUnit({
            ...unit,
            stage: response?.data?.stage,
        }));
    }, [api, assembly, unit, updateUnit]);

    const patchResource = useCallback(async (oldResource, newResource) => {
        setProcessing(true);

        const response = await api.patch(`/units/${unit?.unit_id}`, {
            resource: {resource_id: newResource},
        });

        setProcessing(false);

        if (isResponseError(response)) {
            toast.error(response?.data?.error);
            setResource(oldResource);
            return;
        }

        _.forEach(assembly ?? [unit], (unit) => updateUnit({
            ...unit,
            resource: response?.data?.resource,
        }));
    }, [api, assembly, unit, updateUnit]);

    const stageOptions = _.map(stages, (stage) => ({
        label: stage,
        value: stage,
    }));

    return <BaseRow $first={first} $bg={bg}>
        <BaseCol xs={2} sm={1} className={'pe-0'}>
            {_.times(
                unit.assembly_level ?? 0,
                (idx) => <ArrowRight key={idx}/>,
            )}
            {unit.work_ticket.work_ticket_id !== workTicketId
                && <OverlayTrigger
                    trigger={'click'}
                    rootClose={true}
                    overlay={<Popover>
                        <Popover.Body>
                            <Link
                                className={'text-decoration-none text-primary'}
                                to={'/work-tickets/'
                                    + unit.work_ticket.work_ticket_id
                                    + '/unit-overview'}
                            >
                                {unit.work_ticket.work_ticket_number}
                                <ExternalLinkIcon/>
                            </Link>
                        </Popover.Body>
                    </Popover>}
                >
                    <WarningIcon/>
                </OverlayTrigger>}
        </BaseCol>
        <BaseCol sm={5} lg={2}>
            <Link
                className={'text-decoration-none text-primary'}
                to={'/work-tickets/'
                    + unit.work_ticket.work_ticket_id
                    + `/${unit.config.config_id}/units`}
            >
                {`P/N: ${sku}`}
                <ExternalLinkIcon/>
            </Link>
        </BaseCol>
        <BaseCol className={'text-primary'} sm={6} lg={4}>
            {desc}
        </BaseCol>
        <BaseCol className={'text-primary'} sm={6} lg={2}>
            {serial
                ? `S/N: ${serial}`
                : null}
        </BaseCol>
        {isStageEditable && <BaseCol
            className={'text-primary'}
            sm={6}
            lg={2}
        >
            <Form.Group>
                <Form.Text>{'Stage'}</Form.Text>
                <FormSelect
                    isDisabled={processing}
                    value={_.find(stageOptions, {value: stage})}
                    options={stageOptions}
                    onChange={(e) => {
                        setStage(e.value);
                        patchStage(stage, e.value);
                    }}
                />
            </Form.Group>
        </BaseCol>}
        {isResourceEditable && <BaseCol
            className={'text-primary'}
            sm={6}
            lg={2}
        >
            <Form.Group>
                <Form.Text>{'Resource'}</Form.Text>
                <AsyncFormSelect
                    defaultOptions={defaultResources}
                    isDisabled={processing}
                    isClearable={true}
                    backspaceRemovesValue={true}
                    loadOptions={searchResources}
                    value={resource
                        ? resourceToOption(resource)
                        : null}
                    onChange={(e) => {
                        setResource(e);
                        patchResource(resource, e?.value);
                    }}
                />
            </Form.Group>
        </BaseCol>}
        <BaseCol xs={2} sm={1}>
            {count > 0 && <>
                <UnitIcon/>
                <span>{count}</span>
            </>}
        </BaseCol>
        {showAssetDetails && <UnitAssetDetails unit={unit}/>}
    </BaseRow>;
};

export default UnitRow;
