import React, {useContext} from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import hamburgerMenu from '^assets/images/hamburgerMenu.svg';
import home from '^assets/images/home.svg';
import {AppContext} from '^contexts/app';
import {Container, Navbar} from 'react-bootstrap';

const BottomNavbar = styled(Navbar).attrs(() => ({
    fixed: 'bottom',
    className: 'bg-secondary d-print-none',
}))`
  box-shadow: 0 -3px 6px #00000029;
`;

const NavImg = styled.img.attrs(() => ({
    className: 'mx-2',
    role: 'button',
}))`
  width: 2rem;
  height: 2rem;
`;

const BottomNav = () => {
    const {setLeftNavOpen} = useContext(AppContext);

    return <BottomNavbar>
        <Container className={'justify-content-center'}>
            <Link to={'/'}>
                <NavImg src={home} alt={'Home'}/>
            </Link>
            <NavImg
                src={hamburgerMenu}
                alt={'Menu'}
                onClick={() => setLeftNavOpen((prevState) => !prevState)}
            />
        </Container>
    </BottomNavbar>;
};

export default BottomNav;
