import React from 'react';
import StatusIntervals from './common/statusIntervals';

const WorkDone = () => {
    return <StatusIntervals
        metricName={'work_ticket_status_intervals'}
        exportPrefix={'Work Done'}
        countLabel={'Work Count'}
    />;
};

export default WorkDone;
