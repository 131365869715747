import React, {useContext} from 'react';
import {AppContext} from '^contexts/app';
import {CloseButton} from 'react-bootstrap';

const Aside = () => {
    const {asideChildren, setAsideChildren} = useContext(AppContext);

    return <aside className={'h-100 p-2'}>
        <CloseButton
            className={'float-end mt-2'}
            onClick={() => setAsideChildren(null)}
        />
        {asideChildren}
    </aside>;
};

export default Aside;
