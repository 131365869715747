import React, {useState, useEffect, useCallback, useContext} from 'react';
import _ from 'lodash';
import {ApiContext} from '^contexts/api';
import {AppContext} from '^contexts/app';
import {isResponseError} from '^utilities/isResponseError';
import AsideHeader from '^common/asideHeader';
import {AsyncFormSelect, FormSelect} from '^common/formSelect';
import {Button, Form} from 'react-bootstrap';
import {toast} from 'react-toastify';

const resourceToOption = (resource) => ({
    label: resource?.label,
    value: resource?.resource_id,
});

const WorkTicketKitCreateEdit = ({
    kit,
    workTicket,
    updateKit,
}) => {
    const api = useContext(ApiContext);
    const {setAsideChildren} = useContext(AppContext);
    const kitId = kit?.kit_id;
    const [processing, setProcessing] = useState(false);
    const [label, setLabel] = useState('');
    const [stage, setStage] = useState('');
    const [resource, setResource] = useState('');
    const [stages, setStages] = useState([]);
    const [resources, setResources] = useState([]);

    useEffect(() => {
        if (!_.isNil(kit)) {
            setLabel(kit?.label);
            setStage(kit?.stage);
            setResource(kit?.resource?.resource_id);
        }
    }, [kit]);

    useEffect(() => {
        const fetchStages = async () => {
            const response = await api.get('/types/stage');

            if (isResponseError(response)) {
                return;
            }

            setStages(response?.data);
        };

        if (api) {
            fetchStages();
        }
    }, [api]);

    const searchResources = useCallback(async (label) => {
        if (!api) {
            return [];
        }

        const response = await api.get('/resources', {
            params: {
                active: 1,
                facility_id:
                    kit?.work_ticket?.work_ticket_group?.facility?.facility_id
                    ?? workTicket?.work_ticket_group?.facility?.facility_id,
                ...label ? {label} : {},
            },
        });

        if (isResponseError(response)) {
            return [];
        }

        const resources = response?.data?.results ?? [];

        setResources((prevState) => _.uniqBy(
            [...prevState, ...resources],
            'resource_id',
        ));

        return _.map(resources, resourceToOption);
    }, [
        api,
        kit?.work_ticket?.work_ticket_group?.facility?.facility_id,
        workTicket?.work_ticket_group?.facility?.facility_id,
    ]);

    const createEditKit = useCallback(async () => {
        setProcessing(true);

        const body = {
            label: label,
            stage: stage,
            work_ticket_group: {
                work_ticket_group_id: workTicket
                    ?.work_ticket_group?.work_ticket_group_id,
            },
            resource: {
                resource_id: resource,
            },
        };

        const response = kitId
            ? await api.patch(`/kits/${kitId}`, body)
            : await api.post('/kits', body);

        setProcessing(false);

        if (isResponseError(response)) {
            toast.error(response?.data?.error);
            return;
        }

        updateKit(response?.data);
        setAsideChildren(null);
    }, [
        api,
        workTicket,
        kitId,
        label,
        stage,
        resource,
        updateKit,
        setAsideChildren,
    ]);

    const stageOptions = _.map(stages, (stage) => ({
        label: stage,
        value: stage,
    }));

    const resourceOptions = _.chain(resources)
        .concat(kit?.resource)
        .uniqBy('resource_id')
        .compact()
        .map(resourceToOption)
        .value();

    return <>
        <AsideHeader>
            {kitId
                ? `Edit Kit: ${kit?.label ?? ''}`
                : 'Create New Kit'}
        </AsideHeader>
        <Form onSubmit={(e) => {
            e.preventDefault();
            createEditKit();
        }}>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Label'}</Form.Text>
                <Form.Control
                    disabled={processing}
                    type={'text'}
                    required={true}
                    value={label}
                    onChange={(e) => setLabel(e.target.value)}
                />
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Stage'}</Form.Text>
                <FormSelect
                    isDisabled={processing}
                    value={_.find(stageOptions, {value: stage})}
                    options={stageOptions}
                    onChange={(e) => setStage(e.value)}
                />
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Resource'}</Form.Text>
                <AsyncFormSelect
                    isDisabled={processing}
                    isClearable={true}
                    backspaceRemovesValue={true}
                    loadOptions={searchResources}
                    value={_.find(resourceOptions, {value: resource})}
                    onChange={(e) => setResource(e?.value)}
                />
            </Form.Group>
            <Button
                className={'w-100 my-3'}
                variant={'success'}
                type={'submit'}
                disabled={processing || !_.every([
                    label,
                    stage,
                ])}
            >
                {'Submit'}
            </Button>
        </Form>
    </>;
};

export default WorkTicketKitCreateEdit;
