import React from 'react';
import {useNavigate} from 'react-router-dom';
import _ from 'lodash';
import {PageHeader} from '^common/pageHeader';
import {TableCol, TableColActions} from '^common/tableCol';
import {TableContainer} from '^common/tableContainer';
import {DropdownToggle} from '^common/dropdownToggle';
import {TableRow} from '^common/tableRow';
import {Dropdown} from 'react-bootstrap';
import reportConfig from './reportConfig';

const ReportList = () => {
    const navigate = useNavigate();

    return <>
        <PageHeader title={'Reports'}/>
        <TableContainer>
            <TableRow $header={true}>
                <TableCol xs={10} md={11}>
                    {'Type'}
                </TableCol>
            </TableRow>
            {_.map(reportConfig, (val, key) =>
                <TableRow key={key}>
                    <TableCol xs={10} md={11}>
                        {`${val.label}`}
                    </TableCol>
                    <TableColActions xs={2} md={1}>
                        <Dropdown>
                            <Dropdown.Toggle as={DropdownToggle}/>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => navigate(
                                    `/reports/${key}`,
                                )}>
                                    {'Run Report'}
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </TableColActions>
                </TableRow>)}
        </TableContainer>
    </>;
};

export default ReportList;
