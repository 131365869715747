import React, {useCallback, useEffect, useMemo, useState} from 'react';
import _ from 'lodash';
import Plot from 'react-plotly.js';

const Chart = ({
    container,
    metrics,
    loading,
    selectedMetric,
}) => {
    const [height, setHeight] = useState(0);
    const [width, setWidth] = useState(0);

    const updateChartSize = useCallback(() => {
        const containerHeight = container?.current?.clientHeight ?? 0;
        const containerWidth = container?.current?.clientWidth ?? 0;

        setHeight(_.max([containerHeight - 50, 200]));
        setWidth(_.max([containerWidth - 25, 500]));
    }, [container]);

    useEffect(() => {
        const resizeObserver = new ResizeObserver(updateChartSize);
        resizeObserver.observe(container?.current);

        return () => {
            resizeObserver.disconnect();
        };
    }, [container, updateChartSize]);

    const selectedMetrics = useMemo(
        () => metrics?.by_customer?.[selectedMetric],
        [metrics, selectedMetric],
    );

    return <>
        {!loading && selectedMetric && <Plot
            className={'pt-4'}
            data={[{
                values: _.values(selectedMetrics),
                labels: _.keys(selectedMetrics),
                type: 'pie',
                textinfo: 'value',
                textposition: 'inside',
            }]}
            layout={{
                font: {family: 'var(--bs-body-font-family)'},
                height: height,
                width: width,
                legend: {itemdoubleclick: false},
                margin: {b: 10, l: 10, r: 10, t: 10},
                paper_bgcolor: 'transparent',
            }}
            config={{displayModeBar: false}}
        />}
    </>;
};

export default Chart;
