import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useLocalStorage} from 'usehooks-ts';
import _ from 'lodash';
import {isResponseError} from '^utilities/isResponseError';
import {ApiContext} from '^contexts/api';
import {AppContext} from '^contexts/app';
import AsideHeader from '^common/asideHeader';
import {FormSelect} from '^common/formSelect';
import {Button, Form} from 'react-bootstrap';
import {toast} from 'react-toastify';

const StorageLocationCreateEdit = ({
    storageLocation,
    updateStorageLocation,
}) => {
    const api = useContext(ApiContext);
    const {setAsideChildren} = useContext(AppContext);
    const [processing, setProcessing] = useState(false);
    const [label, setLabel] = useState('');
    const [active, setActive] = useState(storageLocation?.active ?? true);
    const [userFacility] = useLocalStorage('facility_id', '');
    const [userFacilityLabel] = useLocalStorage('facility_label', '');

    const storageId = storageLocation?.storage_location_id;

    useEffect(() => {
        setLabel(storageLocation?.label ?? '');
    }, [storageLocation?.facility?.facility_id, storageLocation?.label]);

    const createEditStorageLocation = useCallback(async () => {
        setProcessing(true);

        const body = {
            label,
            active,
            facility: {
                facility_id: userFacility,
            },
        };

        const response = storageId
            ? await api.patch(`/storage-locations/${storageId}`, body)
            : await api.post(`/storage-locations`, body);

        setProcessing(false);

        if (isResponseError(response)) {
            toast.error(response?.data?.error);
            return;
        }

        updateStorageLocation(response?.data);
        setAsideChildren(null);
    }, [
        api,
        active,
        label,
        setAsideChildren,
        storageId,
        updateStorageLocation,
        userFacility,
    ]);

    return <>
        <AsideHeader>
            {storageId
                ? `Edit Storage Location "${storageLocation?.label ?? ''}"`
                : 'Create New Storage Location'}
        </AsideHeader>
        <Form onSubmit={(event) => {
            event.preventDefault();
            createEditStorageLocation();
        }}>
            <Form.Group className={'mb-3'}>
                <Form.Check
                    disabled={processing}
                    type={'checkbox'}
                    label={'Active'}
                    checked={active}
                    onChange={(e) => setActive(e.target.checked)}
                ></Form.Check>
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Facility'}</Form.Text>
                <FormSelect
                    isDisabled={true}
                    value={{
                        value: userFacility,
                        label: userFacilityLabel,
                    }}
                />
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Label'}</Form.Text>
                <Form.Control
                    disabled={processing}
                    type={'text'}
                    required={true}
                    value={label}
                    onChange={(e) => setLabel(e.target.value)}
                />
            </Form.Group>
            <Button
                className={'w-100 my-3'}
                variant={'success'}
                disabled={processing || !_.every([
                    userFacility,
                    label,
                ])}
                type={'submit'}
            >
                {'Submit'}
            </Button>
        </Form>
    </>;
};

export default StorageLocationCreateEdit;
