export const targetMetricsStatusOptions = Object.freeze([
    {
        label: 'Error',
        value: 'ERROR',
    },
    {
        label: 'Warning',
        value: 'WARNING',
    },
    {
        label: 'Normal',
        value: 'NORMAL',
    },
    {
        label: 'NA',
        value: 'UNKNOWN',
    },
]);
