import React, {useContext, useState} from 'react';
import {createSearchParams, useSearchParams} from 'react-router-dom';
import _ from 'lodash';
import {defaultSearchParamsObj} from '^config/defaultSearchParams';
import {AppContext} from '^contexts/app';
import AsideHeader from '^common/asideHeader';
import {FormSelect} from '^common/formSelect';
import {Button, Form} from 'react-bootstrap';

const StagedWorkTicketFilterAside = () => {
    const {setAsideChildren} = useContext(AppContext);
    const [searchParams, setSearchParams] = useSearchParams();

    const [
        workTicketNumber,
        setWorkTicketNumber,
    ] = useState(searchParams.get('work_ticket_number'));

    const [
        priority,
        setPriority,
    ] = useState(searchParams.get('is_priority'));

    const [
        project,
        setProject,
    ] = useState(searchParams.get('is_project'));

    const [
        shipDate,
        setShipDate,
    ] = useState(searchParams.get('ship_date'));

    const [
        expired,
        setExpired,
    ] = useState(searchParams.get('is_expired'));

    const resetFields = () => {
        const defaultParams = defaultSearchParamsObj?.stagedWorkTicket;
        setWorkTicketNumber(null);
        setPriority(defaultParams?.is_priority ?? null);
        setProject(defaultParams?.is_project ?? null);
        setShipDate('');
        setExpired(defaultParams?.is_expired ?? null);
    };

    const formSubmit = () => {
        const newSearchParams = {
            ...workTicketNumber ? {work_ticket_number: workTicketNumber} : {},
            ...priority ? {is_priority: priority} : {},
            ...project ? {is_project: project} : {},
            ...shipDate ? {ship_date: shipDate} : {},
            ...expired ? {is_expired: expired} : {},
        };

        setSearchParams(_.size(newSearchParams)
            ? `?${createSearchParams(newSearchParams)}`
            : '');
        setAsideChildren(null);
    };

    const boolOptions = [
        {
            label: 'Yes',
            value: '1',
        },
        {
            label: 'No',
            value: '0',
        },
    ];

    return <>
        <AsideHeader>
            <b>{'Filter Work'}</b>
        </AsideHeader>
        <Form onSubmit={(event) => {
            event.preventDefault();
            formSubmit();
        }}>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Work Number'}</Form.Text>
                <Form.Control
                    type={'search'}
                    value={workTicketNumber}
                    onChange={(e) => setWorkTicketNumber(e.target.value)}
                />
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Priority'}</Form.Text>
                <FormSelect
                    isClearable={true}
                    backspaceRemovesValue={true}
                    value={_.find(boolOptions, {value: priority}) ?? null}
                    options={boolOptions}
                    onChange={(e) => setPriority(e?.value)}
                />
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Project'}</Form.Text>
                <FormSelect
                    isClearable={true}
                    backspaceRemovesValue={true}
                    value={_.find(boolOptions, {value: project}) ?? null}
                    options={boolOptions}
                    onChange={(e) => setProject(e?.value)}
                />
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Ship Date'}</Form.Text>
                <Form.Control
                    type={'date'}
                    value={shipDate}
                    onChange={(e) => setShipDate(e?.target?.value)}
                />
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Expired'}</Form.Text>
                <FormSelect
                    isClearable={true}
                    backspaceRemovesValue={true}
                    value={_.find(boolOptions, {value: expired}) ?? null}
                    options={boolOptions}
                    onChange={(e) => setExpired(e?.value)}
                />
            </Form.Group>
            <Button
                className={'w-100 mt-3'}
                type={'button'}
                onClick={() => resetFields()}
            >{'Reset Filter'}</Button>
            <Button
                className={'w-100 my-3'}
                variant={'success'}
                type={'submit'}
            >{'Submit'}</Button>
        </Form>
    </>;
};

export default StagedWorkTicketFilterAside;
