import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {Link, useSearchParams} from 'react-router-dom';
import {useLocalStorage} from 'usehooks-ts';
import _ from 'lodash';
import {defaultSearchParamsObj} from '^config/defaultSearchParams';
import {ApiContext} from '^contexts/api';
import {AppContext} from '^contexts/app';
import {isResponseError} from '^utilities/isResponseError';
import addIcon from '^assets/images/add.svg';
import editIcon from '^assets/images/editWhite.svg';
import {PageActionFilter, PageActionIcon} from '^common/pageAction';
import {PageHeader} from '^common/pageHeader';
import {TableCol, TableColActions} from '^common/tableCol';
import {TableContainer} from '^common/tableContainer';
import {TableIcon} from '^common/tableIcon';
import {TableRow} from '^common/tableRow';
import ResourceCreateEdit from '^pages/resource/resourceCreateEdit';
import {Breadcrumb, Spinner} from 'react-bootstrap';
import ActiveFilter from '^common/activeFilterAside';
import {limit} from '^config/pagination';
import {usePagination} from '^utilities/usePagination';

const ResourceList = () => {
    const api = useContext(ApiContext);
    const {setAsideChildren} = useContext(AppContext);
    const [resources, setResources] = useState([]);
    const [loading, setLoading] = useState(true);
    const [userFacility] = useLocalStorage('facility_id', '');
    const [userFacilityLabel] = useLocalStorage('facility_label', '');

    const [searchParams] = useSearchParams();
    const active = searchParams.get('active');

    const paramObject = useMemo(
        () => Object.fromEntries(searchParams),
        [searchParams],
    );

    const [offset, setOffset] = useState(null);

    const getResources = useCallback(async (offset) => {
        setLoading(true);

        const response = await api.get('/resources', {
            params: {
                active,
                offset,
                limit,
                facility_id: userFacility,
            },
        });

        setLoading(false);

        if (isResponseError(response)) {
            return;
        }

        const results = response?.data?.results;

        setResources((prevState) => _.uniqBy(
            [...prevState, ...results],
            'resource_id',
        ));
        setOffset(response?.data?.next_offset);
    }, [api, active, userFacility]);

    useEffect(() => {
        if (api && userFacility) {
            setResources([]);
            setOffset(null);
            getResources(0);
        }
    }, [api, getResources, searchParams, userFacility]);

    const lastRowRef = usePagination(offset, getResources);

    const updateResource = useCallback((resource) => {
        setResources((prevState) => {
            const resourceId = resource?.resource_id;

            const existing = _.find(
                prevState,
                {resource_id: resourceId},
            );

            const newResources = existing
                ? _.map(prevState, (prev) =>
                    resourceId === prev?.resource_id
                        ? resource
                        : prev,
                )
                : [resource, ...prevState];

            return _.filter(newResources, (resource) => {
                switch (active) {
                    case '0':
                        return resource?.active === false;
                    case '1':
                        return resource?.active === true;
                    default:
                        return true;
                }
            });
        });
    }, [active]);

    const openFilter = useCallback(() => {
        setAsideChildren(
            <ActiveFilter
                entityName={'Resources'}
            />,
        );
    }, [setAsideChildren]);

    const openCreateEditResource = useCallback((resource) => {
        setAsideChildren(<ResourceCreateEdit
            resource={resource}
            updateResource={updateResource}
        />);
    }, [setAsideChildren, updateResource]);

    return <>
        <PageHeader
            title={'Resources'}
            actionButtons={<>
                <PageActionFilter
                    filterParams={paramObject}
                    onClick={openFilter}
                    defaultFilterParams={defaultSearchParamsObj.resource}
                />
                <PageActionIcon
                    src={addIcon}
                    alt={'Create'}
                    disabled={!userFacility}
                    onClick={() => openCreateEditResource(null)}
                />
            </>}
        >
            <Breadcrumb>
                <Breadcrumb.Item
                    linkAs={Link}
                    linkProps={{to: '/'}}
                >
                    {userFacilityLabel}
                </Breadcrumb.Item>
            </Breadcrumb>
        </PageHeader>
        <TableContainer>
            <TableRow $header={true}>
                <TableCol xs={4} xl={2}>
                    {'Label'}
                </TableCol>
                <TableCol xs={4} xl={2}>
                    {'Facility'}
                </TableCol>
            </TableRow>
            {loading && !offset && <Spinner/>}
            {resources?.length === 0 && !loading
                ? <TableRow>
                    <TableCol>
                        {'No Resources found'}
                    </TableCol>
                </TableRow>
                : _.map(resources, (resource) =>
                    <TableRow key={resource.resource_id}>
                        <TableCol xs={4} xl={2}>
                            {`${resource.label}`}
                        </TableCol>
                        <TableCol xs={4} xl={2}>
                            {`${resource.facility.label}`}
                        </TableCol>
                        <TableColActions xs={4} xl={8}>
                            <TableIcon
                                src={editIcon}
                                alt={'Edit'}
                                onClick={() =>
                                    openCreateEditResource(resource)}
                                $background={'primary'}
                            />
                        </TableColActions>
                    </TableRow>)}
            {!_.isNil(offset) && <TableRow>
                <Spinner ref={lastRowRef}/>
            </TableRow>}
        </TableContainer>
    </>;
};

export default ResourceList;
