import _ from 'lodash';

const nullIdFieldIgnore = [
    'assembled_into_unit_id',
    'thread_id',
];

const transformToNull = (transformVal) => (val) => {
    switch (true) {
        case _.isPlainObject(val):
            return _.mapValues(val, transformToNull(transformVal));
        case _.isArray(val):
            return _.map(val, transformToNull(transformVal));
        case val === transformVal:
            return null;
        default:
            return val;
    }
};

const nullIdsRemoved = (val) => {
    const hasNullId = (obj) => _.some(
        obj,
        (val, key) => {
            if (!_.isNull(val)) {
                return false;
            }

            if (_.includes(nullIdFieldIgnore, key)) {
                return false;
            }

            return /_id$/.test(_.toString(key));
        },
    );

    switch (true) {
        case _.isPlainObject(val):
            return hasNullId(val)
                ? null
                : _.mapValues(val, nullIdsRemoved);
        case _.isArray(val):
            return _.map(val, nullIdsRemoved);
        default:
            return val;
    }
};

export const normalizeRequestBody = _.flow([
    transformToNull(undefined),
    transformToNull(''),
    nullIdsRemoved,
]);
