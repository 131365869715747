import React, {useCallback, useContext, useState} from 'react';
import _ from 'lodash';
import {isResponseError} from '^utilities/isResponseError';
import {ApiContext} from '^contexts/api';
import {AppContext} from '^contexts/app';
import AsideHeader from '^common/asideHeader';
import CustomCard from '^common/customCard';
import {
    Button,
    Card,
    Col,
    Container,
    Form,
    Row,
} from 'react-bootstrap';
import {toast} from 'react-toastify';

const WorkTicketBOMCreateAside = ({
    workTicket,
    setBom,
}) => {
    const api = useContext(ApiContext);
    const workTicketId = workTicket?.work_ticket_id;
    const {setAsideChildren} = useContext(AppContext);
    const [processing, setProcessing] = useState(false);
    const [parts, setParts] = useState([]);
    const [partNumber, setPartNumber] = useState('');
    const [partConfig, setPartConfig] = useState('');
    const [partConfigBarcode, setPartConfigBarcode] = useState('');
    const [partMfrPartNumber, setPartMfrPartNumber] = useState('');
    const [partType, setPartType] = useState('');
    const [partDescription, setPartDescription] = useState('');
    const [partIsService, setPartIsService] = useState(false);
    const [partSerialized, setPartSerialized] = useState(false);
    const [partQuantity, setPartQuantity] = useState(1);

    const addPart = useCallback(() => {
        setParts((parts) => [
            ...parts,
            {
                part_number: partNumber,
                config_label: partConfig,
                config_barcode: partConfigBarcode,
                manufacturer_part_number: partMfrPartNumber,
                type: partType,
                description: partDescription,
                is_service: partIsService,
                serialized: partSerialized,
                quantity: partQuantity,
            },
        ]);

        setPartNumber('');
        setPartConfig('');
        setPartConfigBarcode('');
        setPartMfrPartNumber('');
        setPartType('');
        setPartDescription('');
        setPartIsService(false);
        setPartSerialized(false);
        setPartQuantity(1);
    }, [
        partConfig,
        partConfigBarcode,
        partDescription,
        partIsService,
        partMfrPartNumber,
        partNumber,
        partQuantity,
        partSerialized,
        partType,
    ]);

    const createBom = useCallback(async () => {
        setProcessing(true);

        const response = await api.post(
            `/work-tickets/${workTicketId}/bom`,
            parts,
        );

        setProcessing(false);

        if (isResponseError(response)) {
            toast.error(response?.data?.error);
            return;
        }

        setBom(response?.data);
        setAsideChildren(null);
    }, [api, parts, setAsideChildren, setBom, workTicketId]);

    return <>
        <AsideHeader>
            {`Create Bill of Materials: ${workTicket.work_ticket_number}`}
        </AsideHeader>
        <Form onSubmit={(event) => {
            event.preventDefault();
        }}>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Part Number'}</Form.Text>
                <Form.Control
                    disabled={processing}
                    type={'text'}
                    required={true}
                    value={partNumber}
                    onChange={(e) => setPartNumber(e.target.value)}
                />
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Part Config'}</Form.Text>
                <Form.Control
                    disabled={processing}
                    type={'text'}
                    required={true}
                    value={partConfig}
                    onChange={(e) => setPartConfig(e.target.value)}
                />
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Part Config Barcode (if not SKU)'}</Form.Text>
                <Form.Control
                    disabled={processing}
                    type={'text'}
                    required={true}
                    value={partConfigBarcode}
                    onChange={(e) => setPartConfigBarcode(e.target.value)}
                />
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Manufacturer Part Number'}</Form.Text>
                <Form.Control
                    disabled={processing}
                    type={'text'}
                    value={partMfrPartNumber}
                    onChange={(e) => setPartMfrPartNumber(e.target.value)}
                />
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Part Description'}</Form.Text>
                <Form.Control
                    disabled={processing}
                    type={'text'}
                    required={true}
                    value={partDescription}
                    onChange={(e) => setPartDescription(e.target.value)}
                />
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Part Type'}</Form.Text>
                <Form.Control
                    disabled={processing}
                    type={'text'}
                    required={true}
                    value={partType}
                    onChange={(e) => setPartType(e.target.value)}
                />
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Check
                    disabled={processing}
                    label={'Service'}
                    checked={partIsService}
                    onChange={(e) => setPartIsService(e.target.checked)}
                />
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Check
                    disabled={processing}
                    label={'Serialized'}
                    checked={partSerialized}
                    onChange={(e) => setPartSerialized(e.target.checked)}
                />
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Quantity'}</Form.Text>
                <Form.Control
                    disabled={processing}
                    type={'number'}
                    required={true}
                    min={1}
                    max={99999}
                    value={partQuantity}
                    onChange={(e) => {
                        setPartQuantity(_.toInteger(e.target.value));
                    }}
                />
            </Form.Group>
            <Button
                className={'w-100 my-3'}
                onClick={addPart}
                size={'sm'}
                disabled={processing || !_.every([
                    partNumber,
                    partConfig,
                    partDescription,
                    partType,
                ])}
            >
                {'Add'}
            </Button>
            {_.map(parts, (part, idx) => <CustomCard
                key={idx}
                className={'mb-3'}
            >
                <Card.Body>
                    <Container>
                        <Row>
                            <Col>
                                <b>
                                    {`${part.part_number}-${part.config_label}`}
                                    {` (x${part.quantity})`}
                                </b>
                                <div>
                                    {'Part Config Barcode: '
                                        + (part.config_barcode || 'N/A')}
                                </div>
                                <div>
                                    {'Manufacturer Part Number: '
                                        + (part.manufacturer_part_number
                                            || 'N/A')}
                                </div>
                                <div>
                                    {`Description: ${part.description}`}
                                </div>
                                <div>
                                    {`Type: ${part.type}`}
                                </div>
                                <div>
                                    {`Service: ${part.is_service
                                        ? 'Yes'
                                        : 'No'}`}
                                </div>
                                <div>
                                    {`Serialized: ${part.serialized
                                        ? 'Yes'
                                        : 'No'}`}
                                </div>
                            </Col>
                            <Col xs={3} className={'text-end pe-0 my-auto'}>
                                <Button
                                    disabled={processing}
                                    size={'sm'}
                                    variant={'danger'}
                                    onClick={() => setParts((parts) => _.filter(
                                        parts,
                                        (val) => !_.isEqual(val, part),
                                    ))}
                                >
                                    {'Remove'}
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </Card.Body>
            </CustomCard>)}
            <Button
                className={'w-100 mb-3'}
                variant={'success'}
                disabled={processing || _.size(parts) < 1}
                onClick={() => createBom()}
            >
                {'Submit'}
            </Button>
        </Form>
    </>;
};

export default WorkTicketBOMCreateAside;
