export const captureFields = Object.freeze({
    asset_tag: 'Asset Tag',
    mac_address_1: 'MAC Address 1',
    mac_address_2: 'MAC Address 2',
    mac_address_3: 'MAC Address 3',
    imei_1: 'IMEI 1',
    imei_2: 'IMEI 2',
    iccid_1: 'ICCID 1',
    iccid_2: 'ICCID 2',
    phone_number_1: 'Phone Number 1',
    phone_number_2: 'Phone Number 2',
    service_tag: 'Service Tag',
});
