import styled from 'styled-components';
import Barcode from '^common/barcode';

const AssetBarcode = styled(Barcode).attrs(() => ({
    className: 'p-1 mw-100',
}))`
  height: 2.5em;
  width: 15em;
  background-color: white;
`;

export default AssetBarcode;
