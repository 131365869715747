import React from 'react';
import _ from 'lodash';
import {captureFields} from '^config/captureFields';
import {Row} from 'react-bootstrap';
import AssetBarcode from './assetBarcode';
import BaseCol from './baseCol';

const UnitAssetDetails = ({
    unit,
}) => {
    const assetCapture = _.pickBy(unit.asset_capture);

    if (_.size(assetCapture) === 0) {
        return null;
    }

    return <Row>
        {_.map(assetCapture, (val, field) => <BaseCol
            key={field}
            className={'text-center'}
            lg={4}
            md={6}
            xs={12}
        >
            <div className={'text-primary'}>
                {captureFields[field]}
            </div>
            <div>
                <AssetBarcode value={val}/>
            </div>
            <div style={{fontSize: '10pt'}}>
                {val}
            </div>
        </BaseCol>)}
    </Row>;
};

export default UnitAssetDetails;
