import React, {useCallback, useContext, useEffect, useState} from 'react';
import {createSearchParams, useSearchParams} from 'react-router-dom';
import {useLocalStorage} from 'usehooks-ts';
import {ApiContext} from '^contexts/api';
import {isResponseError} from '^utilities/isResponseError';
import exportIcon from '^assets/images/export.svg';
import {PageActionIcon} from '^common/pageAction';
import {
    Button,
    Col,
    Container,
    Form,
    OverlayTrigger,
    Popover,
    Row,
    Spinner,
} from 'react-bootstrap';
import {InfoIcon} from '../common/icon';
import {toast} from 'react-toastify';
import WorkTicketResult from '^pages/packing/byWorkTicket/workTicketResult';

const ByWorkTicketTab = ({
    setActionButtons,
    setActionText,
    filterStages,
}) => {
    const api = useContext(ApiContext);
    const [searchParams, setSearchParams] = useSearchParams();
    const [userFacility] = useLocalStorage('facility_id', '');

    const [searchWorkTicketNumber, setSearchWorkTicketNumber] = useState('');
    const [resultsSummary, setResultsSummary] = useState('');
    const [workTicket, setWorkTicket] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showAssetDetails, setShowAssetDetails] = useState(true);
    const [showGroupUnits, setShowGroupUnits] = useState(false);

    const searchForWorkTicket = useCallback(async (workTicketNumber) => {
        setLoading(true);
        setResultsSummary('');

        const response = await api.get('/work-tickets', {
            params: {
                work_ticket_number: workTicketNumber,
                facility_id: userFacility,
            },
        });

        setLoading(false);

        if (isResponseError(response)) {
            toast.error(response?.data?.error);
            return;
        }

        if (response?.data?.total_count === 0) {
            setResultsSummary('No results found for'
                + ` work "${workTicketNumber}".`);
            return;
        }

        setResultsSummary(`Found work "${workTicketNumber}".`);
        setWorkTicket(response?.data?.results?.[0]);
    }, [api, userFacility]);

    const searchSubmit = useCallback(() => {
        setSearchWorkTicketNumber('');

        if (searchWorkTicketNumber) {
            setSearchParams(createSearchParams({
                work_ticket_number: searchWorkTicketNumber,
            }));
        }
    }, [searchWorkTicketNumber, setSearchParams]);

    useEffect(() => {
        const workTicketNumber = searchParams.get('work_ticket_number');

        if (workTicketNumber) {
            setWorkTicket(null);
            searchForWorkTicket(workTicketNumber);
        }
    }, [searchForWorkTicket, searchParams]);

    useEffect(() => {
        setActionButtons(<PageActionIcon
            src={exportIcon}
            alt={'Export'}
            onClick={null}
        />);
    }, [setActionButtons]);

    useEffect(() => {
        setActionText(<>
            <div>
                <div
                    className={'d-inline text-primary'}
                    role={'button'}
                    onClick={() => setShowAssetDetails(!showAssetDetails)}
                >
                    {`${showAssetDetails ? 'Hide' : 'Show'} Asset Details`}
                </div>
                <OverlayTrigger
                    overlay={<Popover>
                        <Popover.Body>
                            {`${showAssetDetails ? 'Hide' : 'Show'}`
                                + ' asset capture values for all Units'}
                        </Popover.Body>
                    </Popover>}
                >
                    <InfoIcon/>
                </OverlayTrigger>
            </div>
            <div>
                <div
                    className={'d-inline text-primary'}
                    role={'button'}
                    onClick={() => setShowGroupUnits(!showGroupUnits)}
                >
                    {`${showGroupUnits ? 'Hide' : 'Show'} All Work Group Units`}
                </div>
                <OverlayTrigger
                    overlay={<Popover>
                        <Popover.Body>
                            {`${showGroupUnits
                                ? 'Hide Units from related Work unless'
                                    + ' they belong to a Kit or'
                                    + ' Assembly in this Work'
                                : 'Show Units from related Work'}`}
                        </Popover.Body>
                    </Popover>}
                >
                    <InfoIcon/>
                </OverlayTrigger>
            </div>
        </>);
    }, [setActionText, showAssetDetails, showGroupUnits]);

    return <>
        <Container fluid={true} className={'my-2'}>
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                    searchSubmit();
                }}
            >
                <Row className={'justify-content-center'}>
                    <Col xs={8} md={3} xl={2}>
                        <Form.Control
                            type={'search'}
                            placeholder={`Search Work Number`}
                            value={searchWorkTicketNumber}
                            onChange={(e) => {
                                setSearchWorkTicketNumber(e?.target?.value);
                            }}
                        />
                    </Col>
                    <Col xs={4} md={1}>
                        <Button
                            className={'w-100'}
                            type={'submit'}
                            variant={'success'}
                        >
                            {'Go'}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Container>
        <Container fluid={true} className={'my-3'}>
            <Row className={'my-3 justify-content-center fw-bold'}>
                {loading && <Spinner/>}
                {resultsSummary}
            </Row>
            {workTicket && <WorkTicketResult
                workTicket={workTicket}
                setActionButtons={setActionButtons}
                showAssetDetails={showAssetDetails}
                showGroupUnits={showGroupUnits}
                filterStages={filterStages}
            />}
        </Container>
    </>;
};

export default ByWorkTicketTab;
