import React, {useCallback, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {useLocalStorage} from 'usehooks-ts';
import _ from 'lodash';
import {Card} from 'react-bootstrap';
import resizeVerticalIcon from '^assets/images/resizeVertical.svg';

const INITIAL_HEIGHT = 400;
const MIN_HEIGHT = 200;
const MAX_HEIGHT = 800;

const AUTOSCROLL_TRIGGER = 50;
const AUTOSCROLL_TIME = 500;
const AUTOSCROLL_SIZE = 100;

const ResizeIcon = styled.img.attrs(() => ({
    className: _.join([
        'position-absolute',
        'bottom-0',
        'end-0',
        'mb-1',
        'me-1',
    ], ' '),
    src: resizeVerticalIcon,
    alt: 'Resize',
}))`
    cursor: row-resize;
    height: 1rem;
    width: 1rem;
    -webkit-touch-callout: none;
`;

const HomeWidget = ({
    name,
    title,
    children,
}) => {
    const [
        savedWidgetHeight,
        setSavedWidgetHeight,
    ] = useLocalStorage(`widget_height_${name}`, `${INITIAL_HEIGHT}`);

    const [height, setHeight] = useState(_.toSafeInteger(savedWidgetHeight));

    const dragTarget = useRef(null);
    const autoScrollInterval = useRef(null);
    const autoScrollCount = useRef(1);

    useEffect(() => {
        setSavedWidgetHeight(`${height}`);
        dragTarget.current?.scrollIntoView?.({block: 'nearest'});
    }, [setSavedWidgetHeight, height]);

    const resetAutoScroll = useCallback(() => {
        clearInterval(autoScrollInterval.current);
        autoScrollInterval.current = null;
        autoScrollCount.current = 1;
    }, []);

    const onPointerDown = (pointerDownEvent) => {
        dragTarget.current = pointerDownEvent.target;
        const startHeight = height;
        const pointerDownHeight = pointerDownEvent.pageY;

        const onPointerMove = (pointerMoveEvent) => {
            const pointerMoveHeight = pointerMoveEvent.pageY;

            const newHeight = startHeight
                - pointerDownHeight
                + pointerMoveHeight;

            if (pointerMoveHeight < window.innerHeight - AUTOSCROLL_TRIGGER) {
                resetAutoScroll();
                setHeight(_.clamp(newHeight, MIN_HEIGHT, MAX_HEIGHT));
                return;
            }

            if (autoScrollInterval.current) {
                return;
            }

            autoScrollInterval.current = setInterval(() => {
                setHeight(_.clamp(
                    newHeight + (AUTOSCROLL_SIZE * autoScrollCount.current),
                    MIN_HEIGHT,
                    MAX_HEIGHT,
                ));
                autoScrollCount.current++;
            }, AUTOSCROLL_TIME);
        };


        const onTouchMove = (touchMoveEvent) => {
            touchMoveEvent.preventDefault();
        };

        const onPointerUp = () => {
            window.removeEventListener('pointermove', onPointerMove);
            window.removeEventListener('touchmove', onTouchMove);
            dragTarget.current = null;
            resetAutoScroll();
        };

        window.addEventListener('pointermove', onPointerMove);
        window.addEventListener('pointerup', onPointerUp, {once: true});
        window.addEventListener('touchmove', onTouchMove, {passive: false});
    };

    return <Card className={'my-2 pb-2 bg-secondary'}>
        <Card.Header className={'bg-primary fw-bold text-light'}>
            {title}
        </Card.Header>
        <Card.Body
            style={{height: `${height}px`}}
            className={'px-0 overflow-y-auto'}
        >
            {children}
            <ResizeIcon
                onDragStart={(e) => e.preventDefault()}
                onPointerDown={onPointerDown}
            />
        </Card.Body>
    </Card>;
};

export default HomeWidget;
