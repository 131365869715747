import React, {useCallback, useContext, useEffect, useState} from 'react';
import _ from 'lodash';
import {isResponseError} from '^utilities/isResponseError';
import {ApiContext} from '^contexts/api';
import AsideHeader from '^common/asideHeader';
import {FormSelect} from '^common/formSelect';
import {Button, Col, Form, Row, Spinner} from 'react-bootstrap';
import {toast} from 'react-toastify';

const FacilityLi = ({
    facilityLabel,
    buttonLabel,
    onClick,
}) => {
    const [processing, setProcessing] = useState(false);

    return <li className={'my-2'}>
        {`${facilityLabel} `}
        {processing
            ? <Spinner size={'sm'}/>
            : <Button
                size={'sm'}
                onClick={async () => {
                    setProcessing(true);
                    await onClick();
                    setProcessing(false);
                }}>
                {buttonLabel}
            </Button>}
    </li>;
};

const roleOptions = [
    {
        label: 'Admin',
        value: 'admin',
    },
    {
        label: 'Read Only',
        value: 'read_only',
    },
    {
        label: 'Standard',
        value: 'standard',
    },
];

const UserEdit = ({
    user,
    updateUser,
}) => {
    const api = useContext(ApiContext);
    const [roleSpinner, setRoleSpinner] = useState(false);
    const [admin, setAdmin] = useState(false);
    const [readOnly, setReadOnly] = useState(false);
    const [defaultFacility, setDefaultFacility] = useState(null);
    const [clearDefaultSpinner, setClearDefaultSpinner] = useState(false);
    const [facilityAccess, setFacilityAccess] = useState([]);
    const [facilities, setFacilities] = useState([]);

    const userId = user?.user_id;

    useEffect(() => {
        setAdmin(user?.admin);
        setReadOnly(user?.read_only);
        setDefaultFacility(user?.default_facility?.label);
    }, [
        user?.admin,
        user?.read_only,
        user?.default_facility?.label,
    ]);

    useEffect(() => {
        const fetchFacilityAccess = async () => {
            const response = await api.get(`/users/${userId}/facilities`);

            if (isResponseError(response)) {
                return;
            }

            setFacilityAccess(_.map(response?.data?.results, 'facility_id'));
        };

        if (api && userId) {
            fetchFacilityAccess();
        }
    }, [api, userId]);

    useEffect(() => {
        const fetchFacilities = async () => {
            const response = await api.get('/facilities',
                {
                    params: {
                        active: 1,
                    },
                });

            if (isResponseError(response)) {
                return;
            }

            setFacilities(response?.data?.results);
        };

        if (api && user) {
            fetchFacilities();
        }
    }, [api, user]);

    const updateRole = useCallback(async (e) => {
        setRoleSpinner(true);

        const response = await api.patch(
            `/users/${userId}`,
            (() => {
                switch (e?.value) {
                    case 'admin':
                        return {
                            admin: true,
                            read_only: false,
                        };
                    case 'read_only':
                        return {
                            admin: false,
                            read_only: true,
                        };
                    default:
                        return {
                            admin: false,
                            read_only: false,
                        };
                }
            })(),
        );

        setRoleSpinner(false);

        if (isResponseError(response)) {
            toast.error(response?.data?.error);
            return;
        }

        setAdmin(response.data?.admin);
        setReadOnly(response.data?.read_only);
        updateUser(response.data);
    }, [api, updateUser, userId]);

    const clearDefaultFacility = useCallback(async () => {
        setClearDefaultSpinner(true);

        const response = await api.patch(`/users/${userId}`, {
            default_facility: null,
        });

        setClearDefaultSpinner(false);

        if (isResponseError(response)) {
            toast.error(response?.data?.error);
            return;
        }

        setDefaultFacility(null);
        updateUser(response.data);
    }, [api, updateUser, userId]);

    const addFacilityAccess = useCallback(async (facilityId) => {
        const response = await api.post(`/users/${userId}/facilities`, {
            facility_id: facilityId,
        });

        if (isResponseError(response)) {
            toast.error(response?.data?.error);
            return;
        }

        setFacilityAccess((prevState) => [...prevState, facilityId]);
    }, [api, userId]);

    const removeFacilityAccess = useCallback(async (facilityId) => {
        const response = await api.delete(
            `/users/${userId}/facilities/${facilityId}`,
        );

        if (isResponseError(response)) {
            toast.error(response?.data?.error);
            return;
        }

        setFacilityAccess((prevState) => _.without(prevState, facilityId));
    }, [api, userId]);

    const accessibleFacilities = _.filter(
        facilities,
        (facility) => _.includes(facilityAccess, facility.facility_id),
    );

    const inaccessibleFacilities = _.difference(
        facilities,
        accessibleFacilities,
    );

    return <>
        <AsideHeader>
            {`Edit User "${user?.name ?? ''}"`}
        </AsideHeader>
        <Form.Group className={'mb-3'}>
            <Form.Text>{'Email'}</Form.Text>
            <Form.Control
                disabled={true}
                type={'text'}
                value={user?.email ?? ''}
            />
        </Form.Group>
        <Form.Group className={'mb-3'}>
            <Form.Text>{'Role'}</Form.Text>
            <Row>
                <Col>
                    <FormSelect
                        isDisabled={roleSpinner}
                        value={(() => {
                            switch (true) {
                                case !!admin:
                                    return _.find(
                                        roleOptions,
                                        {value: 'admin'},
                                    );
                                case !!readOnly:
                                    return _.find(
                                        roleOptions,
                                        {value: 'read_only'},
                                    );
                                default:
                                    return _.find(
                                        roleOptions,
                                        {value: 'standard'},
                                    );
                            }
                        })()}
                        options={roleOptions}
                        onChange={updateRole}
                    />
                </Col>
                {roleSpinner && <Col xs={2}>
                    <Spinner/>
                </Col>}
            </Row>
        </Form.Group>
        <Form.Group className={'mb-3'}>
            <Form.Text>{'Default Facility'}</Form.Text>
            <div>
                {`${defaultFacility ?? 'N/A'} `}
                {clearDefaultSpinner
                    ? <Spinner/>
                    : <Button
                        size={'sm'}
                        onClick={clearDefaultFacility}
                        disabled={!defaultFacility}
                    >{'Clear'}</Button>}
            </div>
        </Form.Group>
        {admin && <Form.Group className={'mb-3 fw-semibold'}>
            {'Since this user is an admin, they will have implicit access to'
                + ' all facilities (regardless of explicit access below)'}
        </Form.Group>}
        <Form.Group className={'mb-3'}>
            <Form.Text>{'Accessible Facilities'}</Form.Text>
            <ul>
                {_.map(accessibleFacilities, (facility) => {
                    const facilityId = facility.facility_id;
                    return <FacilityLi
                        key={facilityId}
                        facilityLabel={facility.label}
                        buttonLabel={'Remove Access'}
                        onClick={() => removeFacilityAccess(facilityId)}
                    />;
                })}
            </ul>
        </Form.Group>
        <Form.Group className={'mb-3'}>
            <Form.Text>{'Inaccessible Facilities'}</Form.Text>
            <ul>
                {_.map(inaccessibleFacilities, (facility) => {
                    const facilityId = facility.facility_id;
                    return <FacilityLi
                        key={facilityId}
                        facilityLabel={facility.label}
                        buttonLabel={'Add Access'}
                        onClick={() => addFacilityAccess(facilityId)}
                    />;
                })}
            </ul>
        </Form.Group>
    </>;
};

export default UserEdit;
