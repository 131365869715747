import _ from 'lodash';

export const getKits = (units) => _.chain(units)
    .map('kit')
    .compact()
    .uniqBy('kit_id')
    .value();

export const getAssemblyParents = (units) => _.chain(units)
    .filter((unit) => _.isNil(unit.kit))
    .filter((unit) => unit.has_assembly_components)
    .filter((unit) => !unit.assembled_into_unit_id)
    .value();

export const getStandaloneUnits = (units) => _.chain(units)
    .filter((unit) => _.isNil(unit.kit))
    .filter((unit) => !unit.has_assembly_components)
    .filter((unit) => !unit.assembled_into_unit_id)
    .value();
