import _ from 'lodash';
import {isResponseError} from '^utilities/isResponseError';
import {toast} from 'react-toastify';

export const filterUnits = (
    filterType,
    combined,
    grouped,
    id,
    parentId=null,
) => {
    const result = {};
    let filteredUnits;

    _.forOwn(grouped, (units, partConfig) => {
        if (filterType === 'kit') {
            if (_.includes(partConfig, 'Non-Serialized')) {
                filteredUnits = _.chain(units)
                    .filter((unit) => (combined
                        ? unit.kit?.kit_id === id
                        : unit.kit === null)
                        && unit.assembled_into_unit_id === null,
                    )
                    .groupBy((unit) => combined && unit.serial_number
                        ? unit.sku ?? ''
                        : `${unit.config.part.description}`
                        + `-${unit.resource?.label ?? 'N/A'}`
                        + `-${unit.stage}`,
                    ).value();
            } else {
                filteredUnits = _.filter(units, (unit) =>
                    (combined
                        ? unit.kit?.kit_id === id
                        : unit.kit === null)
                    && unit.assembled_into_unit_id === null,
                );
            }
        } else if (filterType === 'assembly') {
            if (_.includes(partConfig, 'Non-Serialized')) {
                filteredUnits = _.chain(units)
                    .filter((unit) => combined
                        ? unit.assembled_into_unit_id === id
                        : unit.assembled_into_unit_id === null
                        && unit.kit === null
                        && unit.unit_id !== id)
                    .groupBy((unit) => combined && unit.serial_number
                        ? unit.sku ?? ''
                        : `${unit.config.part.description}`
                        + `-${unit.resource?.label ?? 'N/A'}`
                        + `-${unit.stage}`,
                    ).value();
            } else {
                filteredUnits = _.filter(units, (unit) =>
                    combined
                        ? unit.assembled_into_unit_id === id
                        : unit.assembled_into_unit_id === null
                        && unit.kit === null
                        && unit.unit_id !== id
                        && unit.unit_id !== parentId,
                );
            }
        }

        if (filteredUnits?.length > 0 || !_.isEmpty(filteredUnits)) {
            result[partConfig] = filteredUnits;
            filteredUnits = null;
        }
    });

    return result;
};

export const getBarcodeUnits = (unitsMap) => {
    const unitsCloned = _.cloneDeep(unitsMap);
    const barcodedUnits = {};
    _.forOwn(unitsCloned, (groupedUnits, partConfig) => {
        if (_.includes(partConfig, 'Non-Serialized')) {
            const subKey = _.keys(groupedUnits)[0] ?? null;
            if (subKey) {
                _.forOwn(groupedUnits[subKey], (unit) => {
                    if (unit.barcode) {
                        if (!(unit.barcode in barcodedUnits)) {
                            barcodedUnits[unit.barcode] = {};
                        }
                        if (!(subKey in barcodedUnits[unit.barcode])) {
                            barcodedUnits[unit.barcode][subKey] = [];
                        }
                        barcodedUnits[unit.barcode][subKey].push(unit);
                    }
                });
            }
        } else {
            _.forOwn(groupedUnits, (unit) => {
                if (unit.barcode) {
                    if (!(unit.barcode in barcodedUnits)) {
                        barcodedUnits[unit.barcode] = [];
                    }
                    barcodedUnits[unit.barcode].push(unit);
                }
            });
        }
    });

    return barcodedUnits;
};

export const getAssemblyUnits = async (unitOrMap, api, isSingleUnit=false) => {
    const descendants = {};

    const fetchAssembly = async (unit) => {
        let data;
        const hasDescendants = unit.has_assembly_components;
        if (hasDescendants) {
            const response = await api.get(
                `/units/${unit.unit_id}/assembly`);
            if (isResponseError(response)) {
                toast.error('Failed to fetch Unit assembly');
                return descendants;
            }
            data = _.cloneDeep(response?.data);
        }
        return data;
    };

    if (isSingleUnit) {
        const result = await fetchAssembly(unitOrMap);
        if (result) {
            descendants[unitOrMap.unit_id] = result;
        }
    } else {
        for (const [partConfig, units] of Object.entries(unitOrMap)) {
            if (_.isNil(units) || _.includes(partConfig, 'Non-Serialized')) {
                continue;
            }
            for (const unit of units) {
                const result = await fetchAssembly(unit);
                if (result) {
                    descendants[unit.unit_id] = result;
                }
            }
        }
    }
    return descendants;
};

export const getDescendants = (units, id) => {
    const descendants = {};

    const children = _.filter(units,
        (unit) => unit.assembled_into_unit_id === id);
    if (children.length === 0) {
        return descendants;
    }
    const unserialized = {};

    for (const child of children) {
        const {sku, serial_number: serialNum} = child;

        if (_.isNil(serialNum)) {
            if (!unserialized[sku]) {
                unserialized[sku] = {
                    quantity: 1,
                    unit_id: child.unit_id,
                };
            } else {
                unserialized[sku].quantity++;
            }
        } else {
            descendants[child.unit_id] = {
                children: getDescendants(units, child.unit_id),
                name: serialNum,
            };
        }
    }

    for (const key in unserialized) {
        if (_.has(unserialized, key)) {
            const {quantity, unit_id: unitId} = unserialized[key];
            descendants[key] = {
                children: getDescendants(units, unitId),
                name: `${key}: ${quantity} unit(s)`,
            };
        }
    }

    return descendants;
};

export const renderDescendants = (
    descendants,
    level = 1,
    highlightId = null,
    names = null,
    isNestedChild = false,
) => {
    const hyphens = '-'.repeat(level);

    return (
        <div>
            {descendants
                && Object.entries(descendants).map((
                    [unitId, unitData]) => {
                    const isHighlighted
                        = (isNestedChild && _.includes(names, unitData.name))
                            || highlightId === unitId;
                    return (
                        <div key={unitId}>
                            {isHighlighted
                                ? (<u>{hyphens} {unitData.name}</u>)
                                : (`${hyphens} ${unitData.name}`)
                            }
                            {renderDescendants(
                                unitData.children,
                                level + 1,
                                highlightId,
                                names,
                                isHighlighted,
                            )}
                        </div>
                    );
                })}
        </div>
    );
};

export const getDescendantNames = (descendants) => {
    if (!descendants) {
        return;
    }

    const names = [];

    const extractNames = (descendants) => {
        for (const [, unitData] of Object.entries(descendants)) {
            names.push(unitData.name);
            if (unitData.children) {
                extractNames(unitData.children);
            }
        }
    };

    extractNames(descendants);
    return names;
};

export const getNonSerializedMap = (assembled, units) => {
    const result = {};
    for (const partNumber in units) {
        if (_.isArray(units[partNumber]) || !_.has(units, partNumber)) {
            continue;
        }

        const falseUnits = units[partNumber];
        if (_.isNil(falseUnits)) {
            continue;
        }

        result[partNumber] = {
            false: {},
        };

        for (const [key] of Object.entries(falseUnits)) {
            const unitsArray
                = units?.[partNumber][key];
            result[partNumber]['false'][key] = {
                quantity: assembled
                    ? _.toString(unitsArray.length)
                    : '0',
                unitIds: _.map(falseUnits[key], 'unit_id'),
            };
        }
    }
    return result;
};

export const handleSelectChange = (combined, part, key, value, setFormData) => {
    combined
        ? setFormData((prevState) => ({
            ...prevState,
            combined: {
                ...prevState?.combined,
                non_serialized: {
                    ...prevState?.combined?.non_serialized,
                    [part]: {
                        ...prevState?.combined?.non_serialized[part],
                        false: {
                            // eslint-disable-next-line max-len
                            ...prevState?.combined?.non_serialized[part]['false'],
                            [key]: {
                                // eslint-disable-next-line max-len
                                ...prevState?.combined?.non_serialized[part]['false'][key],
                                quantity: value,
                            },
                        },
                    },
                },
            },
        }))
        : setFormData((prevState) => ({
            ...prevState,
            available: {
                ...prevState?.available,
                non_serialized: {
                    ...prevState?.available?.non_serialized,
                    [part]: {
                        ...prevState?.available?.non_serialized[part],
                        false: {
                            // eslint-disable-next-line max-len
                            ...prevState?.available?.non_serialized[part]['false'],
                            [key]: {
                                // eslint-disable-next-line max-len
                                ...prevState?.available?.non_serialized[part]['false'][key],
                                quantity: value,
                            },
                        },
                    },
                },
            },
        }));
};

export const getGroupedUnits = (assembly, assemblyId, units) => {
    const unitsByPart = _.chain(units)
        .filter(assembly ? (unit) => unit.unit_id !== assemblyId : () => true)
        .groupBy((unit) =>
            `${unit.sku ?? ''} (${unit.serialized
                ? 'Serialized' : 'Non-Serialized'})`)
        .value();
    return unitsByPart;
};

export const countDescendants = (tree, keyed = false) => {
    if (!tree) return;

    let count = 0;

    const traverse = (obj) => {
        if (_.includes(obj.name, 'unit(s)')) {
            const match = obj.name.match(/(\d+) unit\(s\)/);
            const num = parseInt(match[1], 10);
            count += num;
        } else {
            count++;
        }

        for (const key in obj.children) {
            if (_.has(obj.children, key)) {
                traverse(obj.children[key]);
            }
        }
    };

    if (keyed) {
        for (const key in tree) {
            if (_.has(tree, key)) {
                traverse(tree[key]);
            }
        }
    } else {
        traverse(tree);
    }

    // Subtract parent serial number from being counted
    if (!keyed && count > 0) {
        count--;
    }
    return count;
};

export const toggleAccordion = (setState, eventKey) => {
    setState((prevKey) => (prevKey === eventKey ? null : eventKey));
};
