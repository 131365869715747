import React from 'react';
import StatusIntervals from './common/statusIntervals';

const ServiceSkusDone = () => {
    return <StatusIntervals
        metricName={'service_sku_status_intervals'}
        exportPrefix={'Service SKUs Done'}
        countLabel={'Service SKUs'}
    />;
};

export default ServiceSkusDone;
