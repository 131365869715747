import React, {useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {useLocalStorage} from 'usehooks-ts';
import axios from 'axios';
import {authHost, authClientId} from '^utilities/dataSet';
import {isResponseError} from '^utilities/isResponseError';

const Login = () => {
    const [searchParams] = useSearchParams();
    const code = searchParams.get('code');
    const [, setJwt] = useLocalStorage('jwt', null);
    const [, setRefreshToken] = useLocalStorage('refresh', null);
    const [message, setMessage] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const getToken = async () => {
            setMessage('Getting oauth token...');
            const response = await axios({
                method: 'POST',
                url: `${authHost}/oauth2/token`,
                headers: {
                    'content-type': 'application/x-www-form-urlencoded',
                },
                data: new URLSearchParams({
                    grant_type: 'authorization_code',
                    client_id: authClientId,
                    code: code,
                    redirect_uri: `${window.location.origin}/login`,
                }).toString(),
                validateStatus: null,
            });

            if (isResponseError(response)) {
                setMessage('Failed to exchange code for token');
                return;
            }

            setJwt(response?.data?.access_token);
            setRefreshToken(response?.data?.refresh_token);
            navigate('/');
        };

        if (code) {
            getToken();
        }
    }, [code, navigate, setJwt, setRefreshToken]);

    return <>
        {message}
    </>;
};

export default Login;
