import styled from 'styled-components';
import _ from 'lodash';
import {Row} from 'react-bootstrap';

export const TableRow = styled(Row).attrs(({
    $header,
}) => ({
    className: _.join([
        'm-0',
        'p-1',
        $header ? 'fw-bold' : '',
        $header ? '' : 'bg-white',
        'border-1',
        'border-dark',
        $header ? '' : 'border-start',
        $header ? '' : 'border-end',
        'border-bottom',
    ], ' '),
}))``;
