import {DateTime, Interval} from 'luxon';

const stringToDateTime = (string) => {
    const dateTime = DateTime.fromISO(string);

    if (!dateTime.isValid) {
        return null;
    }

    return dateTime;
};

const nowDateTimeString = () => DateTime.now().toISO();

const stringToReadableDateTime = (string) => {
    const dateTime = stringToDateTime(string);

    if (!dateTime.isValid) {
        return '';
    }

    return dateTime.toLocaleString({
        ...DateTime.DATETIME_SHORT,
        timeZoneName: 'short',
    });
};

const stringsToReadableDuration = (startDateTimeString, endDateTimeString) => {
    const startDateTime = stringToDateTime(startDateTimeString);
    const endDateTime = stringToDateTime(endDateTimeString);

    let duration = Interval
        .fromDateTimes(startDateTime, endDateTime)
        .toDuration()
        .rescale();

    if (!duration.isValid) {
        return '';
    }

    if (duration.as('seconds') > 1) {
        duration = duration.set({milliseconds: 0});
    }

    if (duration.as('minutes') > 1) {
        duration = duration.set({seconds: 0});
    }

    if (duration.as('hours') > 1) {
        duration = duration.set({seconds: 0});
    }

    if (duration.as('days') > 1) {
        duration = duration.set({seconds: 0});
    }

    if (duration.as('weeks') > 1) {
        duration = duration.set({minutes: 0});
    }

    if (duration.as('months') > 1) {
        duration = duration.set({hours: 0});
    }

    if (duration.as('years') > 1) {
        duration = duration.set({days: 0});
    }

    return duration.rescale().toHuman();
};

export {
    nowDateTimeString,
    stringToReadableDateTime,
    stringsToReadableDuration,
};
