import React, {createContext, useEffect} from 'react';
import {useLocalStorage} from 'usehooks-ts';
import axios from 'axios';
import {apiHost, authHost, authClientId} from '^utilities/dataSet';
import {normalizeRequestBody} from '^utilities/normalizeRequestBody';

export const ApiContext = createContext(null);

export const ApiContextProvider = ({children}) => {
    const [jwt, setJwt] = useLocalStorage('jwt', null);
    const [refreshToken] = useLocalStorage('refresh', null);

    useEffect(() => {
        const jwtRefreshWorker = new Worker(new URL(
            './jwtRefresh.worker.js',
            import.meta.url,
        ));

        jwtRefreshWorker.addEventListener('message', ({data}) => {
            setJwt(data);
        });

        const postWorkerMessage = () => {
            if (document.visibilityState === 'hidden') {
                return;
            }

            jwtRefreshWorker.postMessage({
                apiHost,
                authHost,
                authClientId,
                jwt,
                refreshToken,
            });
        };

        window.addEventListener('visibilitychange', postWorkerMessage);
        postWorkerMessage();

        return () => {
            window.removeEventListener('visibilitychange', postWorkerMessage);
            jwtRefreshWorker.terminate();
        };
    }, [jwt, refreshToken, setJwt]);

    const api = jwt
        ? axios.create({
            baseURL: apiHost,
            headers: {Authorization: `Bearer ${jwt}`},
            transformRequest: [
                normalizeRequestBody,
                ...axios.defaults.transformRequest,
            ],
            validateStatus: null,
        })
        : null;

    return <ApiContext.Provider value={api}>
        {children}
    </ApiContext.Provider>;
};
