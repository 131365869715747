import React from 'react';
import _ from 'lodash';
import CustomCard from '^common/customCard';
import {Button, Card} from 'react-bootstrap';
import {countDescendants, renderDescendants}
    from '^pages/workTicket/utilities/kittingAssemblyHelper';

const UnitCard = ({
    unit,
    originalUnitId,
    descendantTrees,
    unitDescendantNames,
    handleSubmit,
    subComponent=null,
    currentComponent=null,
}) => {
    const subComponentPath = (subComponent && originalUnitId === unit?.unit_id)
        ? `parent.${subComponent?.unit_id}.children.${originalUnitId}.children`
        : `parent.${subComponent?.unit_id}.children`;
    const cardBody
        = <>
            <div>
                <b>{`Serial Number: ${unit.serial_number}`}</b>
            </div>
            <div>
                {`Desc: ${unit?.config?.part?.description}`}
            </div>
            <div>
                {`${unit?.stage} | ${unit?.resource?.label ?? 'N/A'}`}
            </div>
            {currentComponent && !_.isEmpty(descendantTrees?.combined)
                && <div>
                    {`${countDescendants(
                        _.get(descendantTrees,
                            `combined.${originalUnitId}.children`),
                        true)
                    } Subcomponents`}
                </div>
            }
            {currentComponent && descendantTrees?.combined && renderDescendants(
                _.get(descendantTrees,
                    `combined.${originalUnitId}.children`),
                1,
                originalUnitId,
                unitDescendantNames,
                true,
            )}
            {subComponent && !_.isEmpty(descendantTrees?.parent)
                && <div>
                    {`${countDescendants(
                        _.get(descendantTrees, subComponentPath),
                        true)
                    } Subcomponents`}
                </div>
            }
            {subComponent && descendantTrees?.parent && renderDescendants(
                _.get(descendantTrees, subComponentPath),
                1,
                originalUnitId,
                unitDescendantNames,
                originalUnitId === unit.unit_id && subComponent,
            )}
        </>;

    return <CustomCard className={'mt-1'}>
        <Card.Body>
            {cardBody}
            <Button
                className={
                    'position-absolute top-50 end-0 translate-middle-y me-3'
                }
                size={'sm'}
                variant={'outline-primary'}
                onClick={() => handleSubmit()}
                disabled={currentComponent}
            >{currentComponent
                    ? 'Current Component'
                    : (originalUnitId === unit.unit_id && subComponent)
                        ? 'Show Assembly'
                        : 'Show Current Component'
                }</Button>
        </Card.Body>
    </CustomCard>;
};

export default UnitCard;
