import styled from 'styled-components';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';

const attrs = {
    className: 'form-control p-0',
    styles: {
        control: (base) => ({
            ...base,
            borderStyle: 'none',
            boxShadow: 'none',
        }),
        // react-select provides a "theme" prop that makes changing the
        //  default colors easier, but styled-components hijacks the "theme"
        //  prop so this workaround is used instead
        option: (base, state) => ({
            ...base,
            'backgroundColor': state.isSelected
                ? 'var(--bs-primary)'
                : 'inherit',
            '&:hover': {
                'backgroundColor': (() => {
                    switch (true) {
                        case state.isSelected:
                            return 'var(--bs-primary)';
                        case state.isDisabled:
                            return 'inherit';
                        default:
                            return 'var(--bs-secondary)';
                    }
                })(),
            },
        }),
    },
};

const AsyncFormSelect = styled(AsyncSelect).attrs(({
    cacheOptions,
    defaultOptions,
}) => ({
    ...attrs,
    cacheOptions: cacheOptions ?? true,
    defaultOptions: defaultOptions ?? true,
}))``;

const FormSelect = styled(Select).attrs(() => attrs)``;

export {AsyncFormSelect, FormSelect};
