import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import _ from 'lodash';
import {ApiContext} from '^contexts/api';
import {AppContext} from '^contexts/app';
import {isResponseError} from '^utilities/isResponseError';
import addIcon from '^assets/images/add.svg';
import editIcon from '^assets/images/editWhite.svg';
import externalLinkIcon from '^assets/images/externalLinkWhite.svg';
import {PageActionIcon} from '^common/pageAction';
import {PageHeader} from '^common/pageHeader';
import {TableCol, TableColActions} from '^common/tableCol';
import {TableContainer} from '^common/tableContainer';
import {TableIcon} from '^common/tableIcon';
import {TableRow} from '^common/tableRow';
import ExtensionCreateEdit from '^pages/extension/extensionCreateEdit';
import {Form, Spinner} from 'react-bootstrap';
import {limit} from '^config/pagination';
import {usePagination} from '^utilities/usePagination';

const ExtensionList = ({global}) => {
    const api = useContext(ApiContext);
    const {setAsideChildren} = useContext(AppContext);

    const [extensions, setExtensions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [offset, setOffset] = useState(null);

    const getExtensions = useCallback(async (offset) => {
        setLoading(true);

        const response = await api.get('/extensions', {
            params: {
                offset,
                limit,
                ...global
                    ? {}
                    : {active: 1},
            },
        });

        setLoading(false);

        if (isResponseError(response)) {
            return;
        }

        const results = response?.data?.results;

        setExtensions((prevState) => _.uniqBy(
            [...prevState, ...results],
            'extension_id',
        ));
        setOffset(response?.data?.next_offset);
    }, [api, global]);

    useEffect(() => {
        if (api) {
            setExtensions([]);
            setOffset(null);
            getExtensions(0);
        }
    }, [api, getExtensions]);

    const lastRowRef = usePagination(offset, getExtensions);

    const updateExtension = useCallback((extension) => {
        setExtensions((prevState) => {
            const extensionId = extension?.extension_id;

            const existing = _.find(
                prevState,
                {extension_id: extensionId},
            );

            return existing
                ? _.map(prevState, (prev) =>
                    extensionId === prev?.extension_id
                        ? extension
                        : prev,
                )
                : [extension, ...prevState];
        });
    }, []);

    const openCreateEditExtension = useCallback((extension) => {
        setAsideChildren(<ExtensionCreateEdit
            extension={extension}
            updateExtension={updateExtension}
        />);
    }, [setAsideChildren, updateExtension]);

    return <>
        <PageHeader
            title={`${global ? 'Manage ' : ''} Extensions`}
            actionButtons={global && <PageActionIcon
                src={addIcon}
                alt={'Create'}
                onClick={() => openCreateEditExtension(null)}
            />}
        />
        <TableContainer>
            <TableRow $header={true}>
                <TableCol>
                    {'Label'}
                </TableCol>
                {global && <>
                    <TableCol
                        xs={2}
                        className={'text-center'}
                    >
                        {'Active'}
                    </TableCol>
                    <TableCol xs={2}/>
                </>}
            </TableRow>
            {loading && !offset && <Spinner/>}
            {extensions?.length === 0 && !loading
                ? <TableRow>
                    <TableCol>
                        {'No Extensions found'}
                    </TableCol>
                </TableRow>
                : _.map(extensions, (extension) =>
                    <TableRow key={extension.extension_id}>
                        <TableCol>
                            {`${extension.label}`}
                        </TableCol>
                        {global && <TableCol
                            xs={2}
                            className={'text-center'}
                        >
                            <Form.Check>
                                <Form.Check.Input
                                    type={'checkbox'}
                                    readOnly={true}
                                    checked={extension?.active ?? true}
                                />
                            </Form.Check>
                        </TableCol>}
                        <TableColActions xs={2}>{global
                            ? <TableIcon
                                src={editIcon}
                                alt={'Edit'}
                                onClick={() =>
                                    openCreateEditExtension(extension)}
                                $background={'primary'}
                            />
                            : <Link
                                to={`/extensions/${extension.extension_id}`}
                            >
                                <TableIcon
                                    src={externalLinkIcon}
                                    alt={'Open'}
                                    $background={'primary'}
                                />
                            </Link>}
                        </TableColActions>
                    </TableRow>)}
            {!_.isNil(offset) && <TableRow>
                <Spinner ref={lastRowRef}/>
            </TableRow>}
        </TableContainer>
    </>;
};

export default ExtensionList;
