import {useCallback, useEffect, useRef} from 'react';

export const usePagination = (offset=null, fetchFunc) => {
    const lastRowRef = useRef(null);

    const handleIntersection = useCallback((entries) => {
        const [entry] = entries;

        if (entry.isIntersecting) {
            if (offset !== null) {
                fetchFunc(offset);
            } else {
                fetchFunc();
            }
        }
    }, [offset, fetchFunc]);

    useEffect(() => {
        const observer = new IntersectionObserver(handleIntersection, {
            root: null,
            rootMargin: '0px',
            threshold: 0.1,
        });

        const current = lastRowRef.current;

        if (current) {
            observer.observe(current);
        }

        return () => {
            if (current) {
                observer.unobserve(current);
            }
        };
    }, [handleIntersection]);

    return lastRowRef;
};
