import React, {
    createRef,
    useCallback,
    useContext, useEffect,
    useRef,
    useState,
} from 'react';
import styled, {keyframes} from 'styled-components';
import {useLocalStorage} from 'usehooks-ts';
import _ from 'lodash';
import {toast} from 'react-toastify';
import {captureFields} from '^config/captureFields';
import {ApiContext} from '^contexts/api';
import {isResponseError} from '^utilities/isResponseError';
import {Form} from 'react-bootstrap';
import {FixedWidthTd, RowNumTd} from './common/td';

const EXCLUDE_STAGES = ['Rolled-Back'];

const warningBackground = keyframes`
    from {
        background-color: var(--bs-warning);
    }
    to {
        background-color: transparent;
    }
`;

const FlashingTr = styled.tr`
    animation-name: ${({$flash}) => $flash && warningBackground};
    animation-duration: ${({$flash}) => $flash && '0.5s'};
    animation-iteration-count: ${({$flash}) => $flash && 2};
    animation-timing-function: ${({$flash}) => $flash && 'ease-in-out'};
`;

const WorkTicketResultRow = ({
    rowIdx,
    workTicketId,
    stages,
    bom,
    findExistingRow,
    data,
    setData,
}) => {
    const api = useContext(ApiContext);

    const [fieldOrder] = useLocalStorage(
        'asset_capture_preference',
        _.keys(captureFields),
    );

    const skuInputRef = useRef(null);
    const serialInputRef = useRef(null);
    const assetInputRefs = useRef({});

    const [configId, setConfigId] = useState(null);
    const [unit, setUnit] = useState(null);
    const [serialInputEnabled, setSerialInputEnabled] = useState(false);
    const [assetInputsEnabled, setAssetInputsEnabled] = useState(false);

    const focusSkuInput = useCallback(() => {
        if (skuInputRef?.current) {
            skuInputRef.current.focus();
        }
    }, []);

    const focusSerialInput = useCallback(() => {
        if (serialInputRef?.current) {
            serialInputRef.current.disabled = false;
            serialInputRef.current.focus();
        }
    }, []);

    const focusNextAssetInput = useCallback((idx = -1) => {
        const next = fieldOrder[idx + 1];

        const nextAssetInputRef = assetInputRefs
            ?.current?.[next];

        if (nextAssetInputRef?.current) {
            nextAssetInputRef.current.disabled = false;
            nextAssetInputRef.current.focus();
        } else {
            // next SKU input will not be rendered yet
            //  if all asset_capture fields are hidden
            setTimeout(() => {
                skuInputRef
                    ?.current // this sku input
                    ?.parentElement // this td
                    ?.parentElement // this tr
                    ?.nextSibling // next tr
                    ?.querySelector('input') // sku input
                    ?.focus?.();
            }, 100);
        }
    }, [fieldOrder]);

    useEffect(() => {
        if (data?.flash) {
            skuInputRef?.current?.scrollIntoView?.({
                behavior: 'smooth',
                block: 'nearest',
            });

            focusNextAssetInput();

            setTimeout(() => {
                setData('flash', null);
            }, 1000);
        }
    }, [data?.flash, focusNextAssetInput, setData]);

    const onSkuBlur = useCallback((e) => {
        const value = _.trim(e.target.value);
        setData('sku', value);

        if (!value) {
            return;
        }

        const bomMatch = _.find(bom, (bomLine) => {
            const lineSku = `${bomLine.part_number}-${bomLine.config_label}`;
            return value === lineSku || value === bomLine.config_barcode;
        });

        if (!bomMatch) {
            toast.error(`SKU "${value}" not found in BOM`);
            setData('sku', null);
            focusSkuInput();
            return;
        }

        if (!bomMatch?.serialized) {
            toast.error(`SKU "${value}" not flagged as serialized`);
            setData('sku', null);
            focusSkuInput();
            return;
        }

        setConfigId(bomMatch?.config_id);
        setSerialInputEnabled(true);

        if (_.isNil(e.relatedTarget)) {
            focusSerialInput();
        }
    }, [bom, focusSerialInput, focusSkuInput, setData]);

    const onSerialBlur = useCallback(async (e) => {
        const value = _.trim(e.target.value);
        const oldValue = unit?.serial_number;

        setData('serial_number', value);

        if (!value) {
            return;
        }

        if (value === oldValue) {
            if (_.isNil(e.relatedTarget)) {
                focusNextAssetInput();
            }
            return;
        }

        const existing = findExistingRow(rowIdx, data?.sku, value);

        if (existing) {
            setConfigId(null);
            setUnit(null);
            setSerialInputEnabled(false);
            setAssetInputsEnabled(false);
            setData('sku', null);
            setData('serial_number', null);
            setData('asset_capture', null);
            return;
        }

        setSerialInputEnabled(false);

        const assignSerialResponse = await api.post(
            `/work-tickets/${workTicketId}/unit-serial-number`,
            {
                serial_number: value,
                config_id: configId,
                filter_stage: _.difference(stages, EXCLUDE_STAGES),
            },
        );

        if (isResponseError(assignSerialResponse)) {
            toast.error(assignSerialResponse?.data?.error);
            setSerialInputEnabled(true);
            setData('serial_number', null);

            if (serialInputRef?.current) {
                serialInputRef.current.disabled = false;
                serialInputRef.current.focus();
            }

            return;
        }

        setData('serial_number', assignSerialResponse?.data?.serial_number);
        setData('asset_capture', assignSerialResponse?.data?.asset_capture);
        setUnit(_.cloneDeep(assignSerialResponse?.data));
        setAssetInputsEnabled(true);
        focusNextAssetInput();
    }, [
        api,
        configId,
        data?.sku,
        findExistingRow,
        focusNextAssetInput,
        rowIdx,
        setData,
        stages,
        unit?.serial_number,
        workTicketId,
    ]);

    const onAssetBlur = useCallback(async (field, e) => {
        const unitId = unit?.unit_id;
        const value = _.trim(e.target.value) || null;
        const oldValue = unit?.asset_capture?.[field];

        setData(`asset_capture.${field}`, value);

        if (value === oldValue) {
            return;
        }

        const response = await api.patch(`/units/${unitId}`, {
            asset_capture: {[field]: value},
        });

        if (isResponseError(response)) {
            toast.error(response?.data?.error);
            setData(`asset_capture.${field}`, oldValue);
            return;
        }

        setUnit((prevState) => ({
            ...prevState,
            asset_capture: {
                ...prevState?.asset_capture,
                [field]: value,
            },
        }));
    }, [api, setData, unit]);

    return <FlashingTr $flash={data?.flash}>
        <RowNumTd>
            {`${rowIdx + 1}`}
        </RowNumTd>
        <FixedWidthTd>
            <Form.Control
                ref={skuInputRef}
                type={'search'}
                value={data?.sku ?? ''}
                onChange={(e) => {
                    setConfigId(null);
                    setUnit(null);
                    setSerialInputEnabled(false);
                    setAssetInputsEnabled(false);
                    setData('sku', e?.target?.value);
                    setData('serial_number', null);
                    setData('asset_capture', null);
                }}
                onKeyDown={(e) => e.key === 'Enter' && e.target.blur()}
                onBlur={onSkuBlur}
            />
        </FixedWidthTd>
        <FixedWidthTd>
            <Form.Control
                ref={serialInputRef}
                disabled={!serialInputEnabled}
                type={'search'}
                value={data?.serial_number ?? ''}
                onChange={(e) => {
                    setUnit(null);
                    setAssetInputsEnabled(false);
                    setData('serial_number', e?.target?.value);
                    setData('asset_capture', null);
                }}
                onKeyDown={(e) => e.key === 'Enter' && e.target.blur()}
                onBlur={onSerialBlur}
            />
        </FixedWidthTd>
        {_.map(fieldOrder, (field, idx) => {
            const inputRef = createRef();
            assetInputRefs.current[field] = inputRef;

            return <FixedWidthTd key={idx}>
                <Form.Control
                    ref={inputRef}
                    disabled={!assetInputsEnabled}
                    type={'text'}
                    value={data?.asset_capture?.[field] ?? ''}
                    onChange={(e) => setData(
                        `asset_capture.${field}`,
                        e?.target?.value,
                    )}
                    onKeyDown={(e) => {
                        if (e.key !== 'Enter') {
                            return;
                        }

                        e.preventDefault();
                        focusNextAssetInput(idx);
                    }}
                    onBlur={(e) => onAssetBlur(field, e)}
                />
            </FixedWidthTd>;
        })}
    </FlashingTr>;
};

export default WorkTicketResultRow;
