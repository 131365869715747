import React, {useCallback, useContext} from 'react';
import _ from 'lodash';
import {Duration} from 'luxon';
import {AppContext} from '^contexts/app';
import history from '^assets/images/history.svg';
import AsideGroupHeader from '^common/asideGroupHeader';
import AsideHeader from '^common/asideHeader';
import HistoryAside from '^common/historyAside';
import {TableIcon} from '^common/tableIcon';
import {Container, Row, Col} from 'react-bootstrap';

const ConfigAside = ({
    config,
}) => {
    const {setAsideChildren} = useContext(AppContext);

    const details = {
        'Barcode (if not SKU)': config?.barcode ?? 'N/A',
        'Work Duration Limit': _.isEmpty(config?.work_ticket_duration_limit)
            ? 'None'
            : Duration
                .fromObject(config?.work_ticket_duration_limit)
                .shiftTo('days', 'hours', 'minutes')
                .toHuman({unitDisplay: 'short'}),
        'Unit Duration Limit': _.isEmpty(config?.unit_duration_limit)
            ? 'None'
            : Duration
                .fromObject(config?.unit_duration_limit)
                .shiftTo('days', 'hours', 'minutes')
                .toHuman({unitDisplay: 'short'}),
        'Unit Duration Cost': _.isNil(config?.unit_duration_cost)
            ? 'None'
            : new Intl.NumberFormat(
                undefined, {
                    style: 'currency',
                    currency: config?.unit_duration_cost?.currency,
                },
            ).format(config?.unit_duration_cost?.value),
    };

    const partDetails = {
        'Part Number': config?.part?.part_number,
        'Manufacturer Part Number': config?.part
            ?.manufacturer_part_number ?? 'N/A',
        'Type': config?.part?.type ?? 'N/A',
        'Description': config?.part?.description ?? 'N/A',
        'Service': config?.part?.is_service
            ? 'Yes'
            : 'No',
    };

    const openSelf = useCallback(() => {
        setAsideChildren(<ConfigAside config={config}/>);
    }, [config, setAsideChildren]);

    const historyAsideProps = {
        label: config?.label ?? 'N/A',
        baseEndpoint: `/configs/${config?.config_id}`,
        onReturn: openSelf,
    };

    const historyOnClicks = {
        'Work Duration Limit': () => {
            setAsideChildren(<HistoryAside
                {...historyAsideProps}
                field={'work_ticket_duration_limit'}
            />);
        },
        'Unit Duration Limit': () => {
            setAsideChildren(<HistoryAside
                {...historyAsideProps}
                field={'unit_duration_limit'}
            />);
        },
        'Unit Duration Cost': () => {
            setAsideChildren(<HistoryAside
                {...historyAsideProps}
                field={'unit_duration_cost'}
            />);
        },
    };

    return <>
        <AsideHeader className={'d-flex justify-content-between'}>
            <b>{config?.label}</b>
        </AsideHeader>
        <AsideGroupHeader>
            {'Standard Fields'}
        </AsideGroupHeader>
        <Container>
            {_.map(details, (value, key) => <Row
                key={key}
            >
                <Col>
                    {`${key}: `}
                    {value}
                </Col>
                {_.has(historyOnClicks, key)
                    ? <Col xs={1} className={'text-end'}>
                        <TableIcon
                            src={history}
                            onClick={historyOnClicks[key]}
                        />
                    </Col> : null}
            </Row>)}
        </Container>
        <AsideGroupHeader>
            {'Part Fields'}
        </AsideGroupHeader>
        <Container>
            {_.map(partDetails, (value, key) => <Row
                key={key}
            >
                <Col>{`${key}: ${value}`}</Col>
            </Row>)}
        </Container>
    </>;
};

export default ConfigAside;
