import React, {useContext, useState, useEffect, useCallback} from 'react';
import {createSearchParams, useSearchParams} from 'react-router-dom';
import {useLocalStorage} from 'usehooks-ts';
import _ from 'lodash';
import {DateTime} from 'luxon';
import {defaultSearchParamsObj} from '^config/defaultSearchParams';
import {targetMetricsStatusOptions} from '^config/targetMetrics';
import {ApiContext} from '^contexts/api';
import {AppContext} from '^contexts/app';
import {isResponseError} from '^utilities/isResponseError';
import AsideHeader from '^common/asideHeader';
import {AsyncFormSelect, FormSelect} from '^common/formSelect';
import {Button, Form} from 'react-bootstrap';

const customerToOption = (customer) => ({
    label: customer?.label,
    value: customer?.customer_id,
});

const storageLocationToOption = (storageLocation) => ({
    label: storageLocation?.label,
    value: storageLocation?.storage_location_id,
});

const WorkTicketFilter = () => {
    const api = useContext(ApiContext);
    const {setAsideChildren} = useContext(AppContext);
    const [searchParams, setSearchParams] = useSearchParams();
    const [userFacility] = useLocalStorage('facility_id', '');

    const [
        customerId,
        setCustomerId,
    ] = useState(searchParams.get('customer_id'));

    const [
        status,
        setStatus,
    ] = useState(_.compact(_.split(searchParams.get('status'), ',')));

    const [
        minStage,
        setMinStage,
    ] = useState(searchParams.get('min_stage'));

    const [
        maxStage,
        setMaxStage,
    ] = useState(searchParams.get('max_stage'));

    const [
        targetMetricsStatus,
        setTargetMetricsStatus,
    ] = useState(_.compact(_.split(
        searchParams.get('target_metrics_overall_status'),
        ',',
    )));

    const [
        inStorage,
        setInStorage,
    ] = useState(searchParams.get('inbound_storage_location_id'));

    const [
        outStorage,
        setOutStorage,
    ] = useState(searchParams.get('outbound_storage_location_id'));

    const [
        assigned,
        setAssigned,
    ] = useState(searchParams.get('is_assigned'));

    const [
        priority,
        setPriority,
    ] = useState(searchParams.get('is_priority'));

    const [
        project,
        setProject,
    ] = useState(searchParams.get('is_project'));

    const [
        pool,
        setPool,
    ] = useState((() => {
        const poolParam = searchParams.get('pool');

        return _.isNil(poolParam)
            ? null
            : _.map(
                _.split(poolParam, ','),
                (val) => val === '' ? null : val,
            );
    })());

    const [
        statusUpdatedAfter,
        setStatusUpdatedAfter,
    ] = useState(searchParams.get('status_updated_after'));

    const [
        shipDate,
        setShipDate,
    ] = useState(searchParams.get('ship_date'));

    const [customers, setCustomers] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [stages, setStages] = useState([]);
    const [storageLocations, setStorageLocations] = useState([]);
    const [pools, setPools] = useState([]);

    const searchCustomers = useCallback(async (label) => {
        if (!api) {
            return [];
        }

        const response = await api.get('/customers', {
            params: label ? {label} : {},
        });

        if (isResponseError(response)) {
            return [];
        }

        const customers = response?.data?.results ?? [];

        setCustomers((prevState) => _.uniqBy(
            [...prevState, ...customers],
            'customer_id',
        ));

        return _.map(customers, customerToOption);
    }, [api]);

    useEffect(() => {
        const addCustomer = async (customerId) => {
            if (!api) {
                return;
            }

            if (!customerId) {
                return;
            }

            const response = await api.get(`/customers/${customerId}`);

            if (isResponseError(response)) {
                return;
            }

            setCustomers((prevState) => _.uniqBy(
                [...prevState, response?.data],
                'customer_id',
            ));
        };

        const customerIds = _.map(customers, 'customer_id');

        if (!_.includes(customerIds, customerId)) {
            addCustomer(customerId);
        }
    }, [api, customerId, customers]);

    const searchStorageLocations = useCallback(async (label) => {
        if (!api) {
            return [];
        }

        if (!userFacility) {
            return [];
        }

        const response = await api.get('/storage-locations', {
            params: {
                active: 1,
                facility_id: userFacility,
                ...label ? {label} : {},
            },
        });

        if (isResponseError(response)) {
            return [];
        }

        const storageLocations = response?.data?.results ?? [];

        setStorageLocations((prevState) => _.uniqBy(
            [...prevState, ...storageLocations],
            'storage_location_id',
        ));

        return _.map(storageLocations, storageLocationToOption);
    }, [api, userFacility]);

    useEffect(() => {
        const addStorageLocation = async (storageLocationId) => {
            if (!api) {
                return;
            }

            if (!storageLocationId) {
                return;
            }

            const response = await api
                .get(`/storage-locations/${storageLocationId}`);

            if (isResponseError(response)) {
                return;
            }

            setStorageLocations((prevState) => _.uniqBy(
                [...prevState, response?.data],
                'storage_location_id',
            ));
        };

        const storageLocationIds = _.map(
            storageLocations,
            'storage_location_id',
        );

        if (!_.includes(storageLocationIds, inStorage)) {
            addStorageLocation(inStorage);
        }

        if (!_.includes(storageLocationIds, outStorage)) {
            addStorageLocation(outStorage);
        }
    }, [api, inStorage, outStorage, storageLocations]);

    useEffect(() => {
        const fetchStatuses = async () => {
            const response = await api.get('/types/status');

            if (isResponseError(response)) {
                return;
            }

            setStatuses(response?.data);
        };

        if (api) {
            fetchStatuses();
        }
    }, [api]);

    useEffect(() => {
        const fetchStages = async () => {
            const response = await api.get('/types/stage');

            if (isResponseError(response)) {
                return;
            }

            setStages(response?.data);
        };

        if (api) {
            fetchStages();
        }
    }, [api]);

    useEffect(() => {
        const fetchPools = async () => {
            const response = await api.get('/types/pool');

            if (isResponseError(response)) {
                return;
            }

            setPools(response?.data);
        };

        if (api) {
            fetchPools();
        }
    }, [api]);

    const resetFields = () => {
        const defaultWorkTicketParams = defaultSearchParamsObj?.workTicket;
        setCustomerId(null);
        setStatus(defaultWorkTicketParams?.status ?? null);
        setMinStage(defaultWorkTicketParams?.min_stage ?? null);
        setMaxStage(defaultWorkTicketParams?.max_stage ?? null);
        setTargetMetricsStatus(null);
        setInStorage(null);
        setOutStorage(null);
        setAssigned(null);
        setPriority(null);
        setProject(null);
        setPool(null);
        setStatusUpdatedAfter('');
        setShipDate('');
    };

    const formSubmit = () => {
        const newSearchParams = {
            ...customerId ? {customer_id: customerId} : {},
            ..._.size(status) ? {status: _.join(status, ',')} : {},
            ...minStage ? {min_stage: minStage} : {},
            ...maxStage ? {max_stage: maxStage} : {},
            ..._.size(targetMetricsStatus)
                ? {target_metrics_overall_status: _.join(
                    targetMetricsStatus,
                    ',',
                )}
                : {},
            ...inStorage ? {inbound_storage_location_id: inStorage} : {},
            ...outStorage ? {outbound_storage_location_id: outStorage} : {},
            ...assigned ? {is_assigned: assigned} : {},
            ...priority ? {is_priority: priority} : {},
            ...project ? {is_project: project} : {},
            ..._.size(pool) ? {pool: _.join(pool, ',')} : {},
            ...shipDate ? {ship_date: shipDate} : {},
            ...statusUpdatedAfter
                ? {status_updated_after: statusUpdatedAfter}: {},
        };

        setSearchParams(_.size(newSearchParams)
            ? `?${createSearchParams(newSearchParams)}`
            : '');
        setAsideChildren(null);
    };

    const customerOptions = _.chain(customers)
        .uniqBy('customer_id')
        .compact()
        .map(customerToOption)
        .value();

    const statusOptions = _.map(statuses, (status) => ({
        label: status,
        value: status,
    }));

    const stageOptions = _.map(stages, (stage) => ({
        label: stage,
        value: stage,
    }));

    const poolOptions = _.map(pools, (pool) => ({
        label: pool ?? 'N/A',
        value: pool,
    }));

    const storageLocationOptions = _.chain(storageLocations)
        .uniqBy('storage_location_id')
        .compact()
        .map(storageLocationToOption)
        .value();

    const boolOptions = [
        {
            label: 'Yes',
            value: '1',
        },
        {
            label: 'No',
            value: '0',
        },
    ];

    return <>
        <AsideHeader>
            <b>{'Filter Work'}</b>
        </AsideHeader>
        <Form onSubmit={(event) => {
            event.preventDefault();
            formSubmit();
        }}>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Customer'}</Form.Text>
                <AsyncFormSelect
                    isClearable={true}
                    backspaceRemovesValue={true}
                    loadOptions={searchCustomers}
                    value={_.find(customerOptions, {value: customerId}) ?? null}
                    onChange={(e) => setCustomerId(e?.value)}
                />
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Status'}</Form.Text>
                <FormSelect
                    isMulti={true}
                    isClearable={true}
                    backspaceRemovesValue={true}
                    value={_.filter(
                        statusOptions,
                        ({value}) => _.includes(status, value),
                    )}
                    options={statusOptions}
                    onChange={(e) => setStatus(_.map(e, 'value'))}
                />
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Min Stage'}</Form.Text>
                <FormSelect
                    isClearable={true}
                    backspaceRemovesValue={true}
                    value={_.find(stageOptions, {value: minStage}) ?? null}
                    options={stageOptions}
                    onChange={(e) => setMinStage(e?.value)}
                />
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Max Stage'}</Form.Text>
                <FormSelect
                    isClearable={true}
                    backspaceRemovesValue={true}
                    value={_.find(stageOptions, {value: maxStage}) ?? null}
                    options={stageOptions}
                    onChange={(e) => setMaxStage(e?.value)}
                />
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Target Metrics Status'}</Form.Text>
                <FormSelect
                    isMulti={true}
                    isClearable={true}
                    backspaceRemovesValue={true}
                    value={_.filter(
                        targetMetricsStatusOptions,
                        ({value}) => _.includes(targetMetricsStatus, value),
                    )}
                    options={targetMetricsStatusOptions}
                    onChange={(e) => setTargetMetricsStatus(_.map(e, 'value'))}
                />
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'In Storage'}</Form.Text>
                <AsyncFormSelect
                    isClearable={true}
                    backspaceRemovesValue={true}
                    loadOptions={searchStorageLocations}
                    value={_.find(
                        storageLocationOptions,
                        {value: inStorage},
                    ) ?? null}
                    onChange={(e) => setInStorage(e?.value)}
                />
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Out Storage'}</Form.Text>
                <AsyncFormSelect
                    isClearable={true}
                    backspaceRemovesValue={true}
                    loadOptions={searchStorageLocations}
                    value={_.find(
                        storageLocationOptions,
                        {value: outStorage},
                    ) ?? null}
                    onChange={(e) => setOutStorage(e?.value)}
                />
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Assigned'}</Form.Text>
                <FormSelect
                    isClearable={true}
                    backspaceRemovesValue={true}
                    value={_.find(boolOptions, {value: assigned}) ?? null}
                    options={boolOptions}
                    onChange={(e) => setAssigned(e?.value)}
                />
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Priority'}</Form.Text>
                <FormSelect
                    isClearable={true}
                    backspaceRemovesValue={true}
                    value={_.find(boolOptions, {value: priority}) ?? null}
                    options={boolOptions}
                    onChange={(e) => setPriority(e?.value)}
                />
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Project'}</Form.Text>
                <FormSelect
                    isClearable={true}
                    backspaceRemovesValue={true}
                    value={_.find(boolOptions, {value: project}) ?? null}
                    options={boolOptions}
                    onChange={(e) => setProject(e?.value)}
                />
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Pool'}</Form.Text>
                <FormSelect
                    isMulti={true}
                    isClearable={true}
                    backspaceRemovesValue={true}
                    value={_.filter(
                        poolOptions,
                        ({value}) => _.includes(pool, value),
                    )}
                    options={poolOptions}
                    onChange={(e) => setPool(_.map(e, 'value'))}
                />
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Status Updated After'}</Form.Text>
                <Form.Control
                    type={'datetime-local'}
                    value={DateTime
                        .fromISO(statusUpdatedAfter)
                        .toLocal()
                        .toISO({
                            includeOffset: false,
                            suppressSeconds: true,
                            suppressMilliseconds: true,
                        }) ?? ''}
                    onChange={(e) => setStatusUpdatedAfter(DateTime
                        .fromISO(e.target.value)
                        .toUTC()
                        .toISO())}
                />
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Ship Date'}</Form.Text>
                <Form.Control
                    type={'date'}
                    value={shipDate}
                    onChange={(e) => setShipDate(e?.target?.value)}
                />
            </Form.Group>
            <Button
                className={'w-100 mt-3'}
                type={'button'}
                onClick={() => resetFields()}
            >{'Reset Filter'}</Button>
            <Button
                className={'w-100 my-3'}
                variant={'success'}
                type={'submit'}
            >{'Submit'}</Button>
        </Form>
    </>;
};

export default WorkTicketFilter;
