import React, {useContext, useState, useEffect, useCallback} from 'react';
import {createSearchParams, useSearchParams} from 'react-router-dom';
import {useLocalStorage} from 'usehooks-ts';
import _ from 'lodash';
import {defaultSearchParamsObj} from '^config/defaultSearchParams';
import {ApiContext} from '^contexts/api';
import {AppContext} from '^contexts/app';
import {isResponseError} from '^utilities/isResponseError';
import AsideHeader from '^common/asideHeader';
import {AsyncFormSelect, FormSelect} from '^common/formSelect';
import {Button, Form} from 'react-bootstrap';

const resourceToOption = (resource) => ({
    label: resource?.label,
    value: resource?.resource_id,
});

const UnitFilter = () => {
    const api = useContext(ApiContext);
    const {setAsideChildren} = useContext(AppContext);
    const [searchParams, setSearchParams] = useSearchParams();
    const [userFacility] = useLocalStorage('facility_id', '');

    const [
        resourceId,
        setResourceId,
    ] = useState(searchParams.get('resource_id'));

    const [
        stage,
        setStage,
    ] = useState(_.compact(_.split(searchParams.get('stage'), ',')));

    const [
        assembled,
        setAssembled,
    ] = useState(searchParams.get('has_assembled_into_unit_id'));

    const [resources, setResources] = useState([]);
    const [stages, setStages] = useState([]);

    const searchResources = useCallback(async (label) => {
        if (!api) {
            return [];
        }

        const response = await api.get('/resources', {
            params: {
                active: 1,
                facility_id: userFacility,
                ...label ? {label} : {},
            },
        });

        if (isResponseError(response)) {
            return [];
        }

        const resources = response?.data?.results ?? [];

        setResources((prevState) => _.uniqBy(
            [...prevState, ...resources],
            'resource_id',
        ));

        return _.map(resources, resourceToOption);
    }, [api, userFacility]);

    useEffect(() => {
        const addResource = async (resourceId) => {
            if (!api) {
                return;
            }

            if (!resourceId) {
                return;
            }

            const response = await api.get(`/resources/${resourceId}`);

            if (isResponseError(response)) {
                return;
            }

            setResources((prevState) => _.uniqBy(
                [...prevState, response?.data],
                'resource_id',
            ));
        };

        const resourceIds = _.map(resources, 'resource_id');

        if (!_.includes(resourceIds, resourceId)) {
            addResource(resourceId);
        }
    }, [api, resourceId, resources]);

    useEffect(() => {
        const fetchStages = async () => {
            const response = await api.get('/types/stage');

            if (isResponseError(response)) {
                return;
            }

            setStages(response?.data);
        };

        if (api) {
            fetchStages();
        }
    }, [api]);

    const resetFields = () => {
        const defaultWorkTicketParams = defaultSearchParamsObj?.workTicket;
        setResourceId(null);
        setStage(defaultWorkTicketParams?.stage ?? null);
        setAssembled(null);
    };

    const formSubmit = () => {
        const newSearchParams = {
            ...resourceId ? {resource_id: resourceId} : {},
            ..._.size(stage) ? {stage: _.join(stage, ',')} : {},
            ...assembled ? {has_assembled_into_unit_id: assembled} : {},
        };

        setSearchParams(_.size(newSearchParams)
            ? `?${createSearchParams(newSearchParams)}`
            : '');
        setAsideChildren(null);
    };

    const resourceOptions = _.chain(resources)
        .uniqBy('resource_id')
        .compact()
        .map(resourceToOption)
        .value();

    const stageOptions = _.map(stages, (stage) => ({
        label: stage,
        value: stage,
    }));

    const boolOptions = [
        {
            label: 'Yes',
            value: '1',
        },
        {
            label: 'No',
            value: '0',
        },
    ];

    return <>
        <AsideHeader>
            <b>{'Filter Units'}</b>
        </AsideHeader>
        <Form onSubmit={(event) => {
            event.preventDefault();
            formSubmit();
        }}>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Resource'}</Form.Text>
                <AsyncFormSelect
                    isClearable={true}
                    backspaceRemovesValue={true}
                    loadOptions={searchResources}
                    value={_.find(resourceOptions, {value: resourceId}) ?? null}
                    onChange={(e) => setResourceId(e?.value)}
                />
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Stage'}</Form.Text>
                <FormSelect
                    isMulti={true}
                    isClearable={true}
                    backspaceRemovesValue={true}
                    value={_.filter(
                        stageOptions,
                        ({value}) => _.includes(stage, value),
                    )}
                    options={stageOptions}
                    onChange={(e) => setStage(_.map(e, 'value'))}
                />
            </Form.Group>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Assembled'}</Form.Text>
                <FormSelect
                    isClearable={true}
                    backspaceRemovesValue={true}
                    value={_.find(boolOptions, {value: assembled}) ?? null}
                    options={boolOptions}
                    onChange={(e) => setAssembled(e?.value)}
                />
            </Form.Group>
            <Button
                className={'w-100 mt-3'}
                type={'button'}
                onClick={() => resetFields()}
            >{'Reset Filter'}</Button>
            <Button
                className={'w-100 my-3'}
                variant={'success'}
                type={'submit'}
            >{'Submit'}</Button>
        </Form>
    </>;
};

export default UnitFilter;
