import styled from 'styled-components';
import verticalEllipsis from '^assets/images/verticalEllipsis.svg';

export const DropdownToggle = styled.img.attrs(() => ({
    role: 'button',
    src: verticalEllipsis,
    alt: 'Dropdown Toggle',
}))`
  width: 1rem;
  height: 1rem;
`;
