import React from 'react';
import _ from 'lodash';
import {buildAssembly} from '../common/util';
import UnitRow from './unitRow';

const AssemblyGroup = ({
    parent,
    allUnits,
    workTicketId,
    showAssetDetails = false,
    serialFilter = null,
}) => {
    const assembly = buildAssembly(parent, allUnits);

    const assemblyUnitSerials = _.chain(assembly)
        .map('serial_number')
        .compact()
        .value();

    if (serialFilter && !_.includes(assemblyUnitSerials, serialFilter)) {
        return null;
    }

    return <div className={'mb-2'}>
        {_.map(assembly, (unit, idx) => <UnitRow
            key={unit.unit_id}
            first={idx === 0}
            bg={idx === 0 ? 'secondary' : 'white'}
            unit={unit}
            workTicketId={workTicketId}
            count={idx === 0 ? _.size(assembly) : 0}
            showAssetDetails={showAssetDetails}
        />)}
    </div>;
};

export default AssemblyGroup;
