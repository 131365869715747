import React, {useContext, useState} from 'react';
import _ from 'lodash';
import AsideHeader from '^common/asideHeader';
import {createSearchParams, useSearchParams} from 'react-router-dom';
import {Button, Form} from 'react-bootstrap';
import {AppContext} from '^contexts/app';
import {FormSelect} from '^common/formSelect';

const ActiveFilterAside = ({
    entityName,
}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [active, setActive] = useState(searchParams.get('active'));
    const {setAsideChildren} = useContext(AppContext);

    const filterOptions = [
        {label: 'Active Only (Default)', value: '1'},
        {label: 'Inactive Only', value: '0'},
    ];

    const resetFields = () => {
        setActive('1');
    };

    const formSubmit = () => {
        setSearchParams(active
            ? `?${createSearchParams({active})}`
            : '');
        setAsideChildren(null);
    };

    return <>
        <AsideHeader>
            <b>{`Filter ${entityName}`}</b>
        </AsideHeader>
        <Form onSubmit={(event) => {
            event.preventDefault();
            formSubmit();
        }}>
            <Form.Group className={'mb-3'}>
                <Form.Text>{'Active'}</Form.Text>
                <FormSelect
                    isClearable={true}
                    backspaceRemovesValue={true}
                    value={_.find(filterOptions, {
                        value: active,
                    })}
                    options={filterOptions}
                    onChange={(e) => {
                        setActive(e?.value);
                    }}
                />
            </Form.Group>
            <Button
                className={'w-100 mt-3'}
                type={'button'}
                onClick={() => resetFields()}
            >{'Reset Filter'}</Button>
            <Button
                className={'w-100 my-3'}
                variant={'success'}
                type={'submit'}
            >{'Submit'}</Button>
        </Form>
    </>;
};

export default ActiveFilterAside;
