import React, {useCallback, useContext, useEffect, useState} from 'react';
import _ from 'lodash';
import {isResponseError} from '^utilities/isResponseError';
import {ApiContext} from '^contexts/api';
import {Col, Row, Spinner} from 'react-bootstrap';
import AsideHeader from '^common/asideHeader';
import {AsyncFormSelect} from '^common/formSelect';
import {toast} from 'react-toastify';

const WorkTicketAssignAside = ({
    workTicket,
    updateWorkTicket,
}) => {
    const api = useContext(ApiContext);
    const [me, setMe] = useState(null);
    const [facilityUsers, setFacilityUsers] = useState([]);
    const [processing, setProcessing] = useState(false);

    const [
        assignedUsers,
        setAssignedUsers,
    ] = useState(workTicket?.assigned_users);

    const workTicketId = workTicket?.work_ticket_id;
    const facilityId = workTicket?.work_ticket_group?.facility?.facility_id;
    const assignedUserIds = _.map(assignedUsers, 'user_id');
    const assignedToMe = _.includes(assignedUserIds, me?.user_id);

    // In case an assigned User no longer has Facility access
    const availableUsers = _.uniqBy(
        [...facilityUsers, ...assignedUsers],
        'user_id',
    );

    useEffect(() => {
        const getMe = async () => {
            const response = await api.get('/me');

            if (isResponseError(response)) {
                return;
            }

            setMe(response?.data);
        };

        if (api) {
            getMe();
        }
    }, [api]);

    const searchUsers = useCallback(async (name) => {
        if (!api) {
            return [];
        }

        const response = await api.get(`/facilities/${facilityId}/users`, {
            params: name ? {name} : {},
        });

        if (isResponseError(response)) {
            return [];
        }

        setFacilityUsers((prevState) => _.uniqBy(
            [...prevState, ...response?.data?.results ?? []],
            'user_id',
        ));

        return _.map(
            response?.data?.results,
            (user) => ({
                label: user.name,
                value: user.user_id,
            }),
        );
    }, [api, facilityId]);

    useEffect(() => {
        updateWorkTicket({
            ...workTicket,
            assigned_users: assignedUsers,
            is_assigned: (assignedUsers?.length ?? 0) > 0,
        });
    }, [assignedUsers, workTicket, updateWorkTicket]);

    const assignUser = useCallback(async (user) => {
        const userId = user.user_id;
        setProcessing(true);

        const response = await api.post(`/users/${userId}/work-tickets`, {
            work_ticket_id: workTicketId,
        });

        if (isResponseError(response)) {
            toast.error(response?.data?.error);
            setProcessing(false);
            return;
        }

        setAssignedUsers((prevState) => [...prevState, user]);
        setProcessing(false);
    }, [api, workTicketId]);

    const removeUser = useCallback(async (user) => {
        const userId = user.user_id;
        setProcessing(true);

        const response = await api.delete(
            `/users/${userId}/work-tickets/${workTicketId}`,
        );

        if (isResponseError(response)) {
            toast.error(response?.data?.error);
            setProcessing(false);
            return;
        }

        setAssignedUsers((prevState) => _.filter(
            prevState,
            (assignedUser) => assignedUser.user_id !== userId,
        ));

        setProcessing(false);
    }, [api, workTicketId]);

    const onChange = useCallback(async (event) => {
        const inputUserIds = _.map(event, 'value');

        const firstUserDifference = (a, b) => _.head(_.filter(
            availableUsers,
            {user_id: _.head(_.difference(a, b))},
        ));

        const assignedUser = firstUserDifference(inputUserIds, assignedUserIds);
        const removedUser = firstUserDifference(assignedUserIds, inputUserIds);

        switch (true) {
            case !!assignedUser:
                await assignUser(assignedUser);
                break;
            case !!removedUser:
                await removeUser(removedUser);
                break;
            default:
                // no-op
        }
    }, [assignUser, assignedUserIds, availableUsers, removeUser]);

    const availableUserOptions = _.map(availableUsers, (user) => ({
        label: user.name,
        value: user.user_id,
    }));

    const selectedUserOptions = _.filter(
        availableUserOptions,
        ({value}) => _.includes(assignedUserIds, value),
    );

    return <>
        <AsideHeader>
            <div>
                <div>{'Assign Technicians'}</div>
                <h6>{workTicket.work_ticket_number}</h6>
            </div>
        </AsideHeader>
        <Row className={'align-items-center'}>
            <Col>
                <AsyncFormSelect
                    isClearable={false}
                    isMulti={true}
                    isDisabled={processing}
                    value={selectedUserOptions}
                    loadOptions={searchUsers}
                    onChange={onChange}
                />
            </Col>
            <Col xs={1} className={'text-end me-3'}>
                {processing && <Spinner size={'sm'}/>}
            </Col>
        </Row>
        {me && !assignedToMe && <Row className={'text-center py-2'}>
            <div
                className={'text-decoration-underline text-primary'}
                role={'button'}
                onClick={() => assignUser(me)}
            >
                {'Assign Me'}
            </div>
        </Row>}
    </>;
};

export default WorkTicketAssignAside;
